import {ARUtils} from '@relayter/core';
import {GetPropertyPipe} from '@relayter/rubber-duck';

export class ModelUtil {
    /**
     * Create api body for post/patch requests
     *
     * @param {Record<string, any>} model
     * @param {string} itemId
     * @returns {Record<string, any>}
     */
    public static createApiBody(model: Record<string, any>, itemId: string): Record<string, any> {
        return Object.keys(model).reduce((item, key) => {
            const value = GetPropertyPipe.transform(model, key);
            item[key] = ModelUtil.getValue(value, itemId);
            return item;
        }, {});
    }

    /**
     * Get the value recursively
     */
    public static getValue(value: any[] | Record<string, any>, itemId: string) {
        if (ARUtils.isObject(value) && !(value instanceof Date)) {
            return ModelUtil.createApiBody(value, itemId);
        } else {
            if (value || typeof value === 'boolean' || typeof value === 'number') {
                if (Array.isArray(value)) {
                    return value.map(entry => ModelUtil.getValue(entry, itemId));
                } else {
                    return value;
                }
            } else if (!!itemId) {
                return value;
            }
        }
    }
}
