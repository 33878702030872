import {FormControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {PropertyValueModel} from '../../models/ui/property-value.model';
import {EPropertyControlType} from '../../components/property-control/property-control.component';
import {FieldPropertyType, RulePropertyModel} from '../../models/api/rule-property.model';
import {VariantModel} from '../../models/api/variant.model';

export function PropertyControlValidator(): ValidatorFn {
    return (control: FormControl<PropertyValueModel>): ValidationErrors => {
        const propertyValueModel: PropertyValueModel = control.value;

        if (!Array.isArray(propertyValueModel?.properties)) return;

        const propertyInputs = getPropertyControlTypes(propertyValueModel.properties, propertyValueModel.options.ignoreIndex);
        const numEntries = propertyValueModel.properties.length;
        if (numEntries === 0) {
            return {
                propertyControlError: {
                    value: 'Select a property'
                }
            };
        }

        for (const [index, property] of propertyValueModel.properties.entries()) {
            if (!property) {
                const propertyType = propertyInputs[index];
                switch (propertyType) {
                    case EPropertyControlType.ARRAY:
                        // Not the last array entry or last array entry required
                        if (index !== numEntries - 1 || (index === numEntries - 1 && propertyValueModel.options.arrayIndexRequired)) {
                            return {
                                propertyControlError: {
                                    value: 'Fill in the array operator index'
                                }
                            };
                        }
                        break;
                    case EPropertyControlType.FIELD:
                        if (propertyValueModel.options.fieldRequired) {
                            return {
                                propertyControlError: {
                                    value: 'Select a property'
                                }
                            };
                        }
                        break;
                    case EPropertyControlType.VARIANT:
                        if (propertyValueModel.options.variantRequired) {
                            return {
                                propertyControlError: {
                                    value: 'Select a variant'
                                }
                            };
                        }
                        break;
                }
            }
        }
    }
}

function getPropertyControlTypes(properties: FieldPropertyType[], ignoreIndex: boolean): EPropertyControlType[] {
    const propertyControlTypes = [];

    // If no properties yet, add first one
    if (properties.length === 0) {
        propertyControlTypes.push(EPropertyControlType.FIELD);
    } else {
        properties.forEach((property, index) => {
            if (index === 0) {
                propertyControlTypes.push(EPropertyControlType.FIELD);
            } else if (property instanceof RulePropertyModel) {
                propertyControlTypes.push(EPropertyControlType.FIELD);
            } else if (property instanceof VariantModel) {
                propertyControlTypes.push(EPropertyControlType.VARIANT);
            } else {
                // Could be an optional field/variant/array index selection
                const previousProperty = properties[index - 1];
                if (previousProperty instanceof RulePropertyModel) {
                    if (previousProperty.enableVariants) {
                        propertyControlTypes.push(EPropertyControlType.VARIANT);
                    } else if (ignoreIndex || !previousProperty.isArray) {
                        propertyControlTypes.push(EPropertyControlType.FIELD);
                    } else {
                        propertyControlTypes.push(EPropertyControlType.ARRAY);
                    }
                } else {
                    propertyControlTypes.push(EPropertyControlType.ARRAY);
                }
            }
        });
    }

    return propertyControlTypes;
}
