import {
    Component,
    computed,
    DestroyRef,
    ElementRef,
    inject,
    input,
    model,
    OnInit,
    Signal,
    signal,
    viewChild
} from '@angular/core';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {AssetModel} from '../../../models/api/asset.model';
import {ProductAssetExportSetupModel, SortItemModel} from '../../../models/api/product-asset-export-setup.model';
import {ProductForm} from '../product-form.component';
import {ProductAssetExportModel} from '../../../models/api/product.model';
import {
    EPropertySettingsContext,
    PropertySettingsService
} from '../../../components/property-settings/property-settings.service';
import {PropertySetting, PropertySettingsModel} from '../../../components/property-settings/property-settings.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {CdkDragDrop, CdkDragExit} from '@angular/cdk/drag-drop';
import {StringUtil} from '../../../classes/string-util';
import {AppConstants} from '../../../app.constants';

export class AssetExportSortItemModel {
    public sortItem: SortItemModel;
    public asset: AssetModel;
}

export class ProductAssetExportSortItemForm {
    productAssetExportSetup: FormControl<ProductAssetExportSetupModel>;
    items: FormArray<FormControl<AssetExportSortItemModel>>;
}

@Component({
    selector: 'product-asset-export-form',
    templateUrl: 'product-asset-export-form.component.html',
    styleUrls: ['product-asset-export-form.component.scss']
})
export class ProductAssetExportFormComponent implements OnInit {
    public readonly EPropertySettingsContext = EPropertySettingsContext;
    public readonly PERMISSIONS = AppConstants.PERMISSIONS;

    // Injected services
    private propertySettingsService = inject(PropertySettingsService);
    private destroyRef = inject(DestroyRef);

    // Input signals
    public form = input.required<FormGroup<ProductForm>>();
    public selectedAssets = input.required<AssetModel[]>();
    public productAssetExportSetups = input.required<ProductAssetExportSetupModel[]>();
    public productAssetExports = input.required<ProductAssetExportModel[]>();
    // Two-way input/output signal
    public selectedProductAssetExportSetup = model.required<ProductAssetExportSetupModel>();
    // View child signal
    public productAssetExportElement = viewChild('productAssetExport', {read: ElementRef});

    public selectedProductAssetExportSetupControl: Signal<FormGroup<ProductAssetExportSortItemForm>> = computed(() =>
            this.productAssetExportsControl.controls.find(control => control.value.productAssetExportSetup === this.selectedProductAssetExportSetup())
        );

    public properties = signal<PropertySetting[]>(null);
    public searchValue = signal<string>(null);
    public assets = signal<AssetModel[]>([]);
    public transferringAssetItem = signal<AssetModel>(null);
    public dragging = signal<boolean>(false);

    public get productAssetExportsControl() {
        return this.form().controls.assetExports;
    }

    public ngOnInit(): void {
        this.assets.set(Array.from(this.selectedAssets()));

        this.initForm();
        this.getPropertySettings();
    }

    private getPropertySettings(): void {
        this.propertySettingsService.getSettings(EPropertySettingsContext.ASSET).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(
                (settings: PropertySettingsModel) => {
                    this.properties.set([...settings.relayterFields, ...settings.dataFields]);
                });
    }

    private initForm(): void {
        let items: Partial<{ productAssetExportSetup: ProductAssetExportSetupModel; items: AssetExportSortItemModel[]; }>[];

        if (!this.form().controls.assetExports) {
            this.form().addControl('assetExports', new FormArray<FormGroup<ProductAssetExportSortItemForm>>([]));

            this.productAssetExportSetups().forEach(setup => {
                const controls =  setup.sortItems.map(() => {
                   return new FormControl<AssetExportSortItemModel>(null);
                });

                this.productAssetExportsControl.push(new FormGroup<ProductAssetExportSortItemForm>({
                    productAssetExportSetup: new FormControl(null),
                    items: new FormArray<FormControl<AssetExportSortItemModel>>(controls)
                }));
            });

            items = this.productAssetExportSetups().map(setup => {
                return {
                    productAssetExportSetup: setup,
                    items: setup.sortItems.map(sortItem => {
                        const exportSortItem = new AssetExportSortItemModel()
                        exportSortItem.sortItem = sortItem;
                        exportSortItem.asset = this.findExportSortItemAsset(setup, sortItem, this.productAssetExports());

                        return exportSortItem;
                    })
                };
            });
        } else {
            // Check if all assets still selected
            items = this.productAssetExportsControl.value.map(assetExport => {
                assetExport.items = assetExport.items.map(item => {
                    item.asset = this.selectedAssets().find(asset => asset._id === item.asset?._id);
                    return item;
                });
                return assetExport;
            });
        }

        this.productAssetExportsControl.patchValue(items);

        if (!this.selectedProductAssetExportSetup() && this.productAssetExportSetups().length) {
            this.selectedProductAssetExportSetup.set(this.productAssetExportSetups()[0]);
        }
    }

    public onSearch(): void {
        const regex = new RegExp(StringUtil.escapeRegExp(this.searchValue()), 'i');
        this.assets.set(this.selectedAssets().filter(asset => !this.searchValue() || !!asset.name.match(regex)));
    }

    public checkIfItemIsSelected(item: AssetModel): boolean {
        const sortItems = this.form().value.assetExports.find(assetExport =>
            assetExport.productAssetExportSetup._id === this.selectedProductAssetExportSetup()._id)?.items;
        return !!sortItems.find(sortItem => sortItem.asset?._id === item._id);
    }

    public enteredAssetItems(): void {
        this.transferringAssetItem.set(null);
    }

    public exitedAssets(event: CdkDragExit<AssetModel>): void {
        this.transferringAssetItem.set(event.item.data);
    }

    public dragStarted(): void {
        this.updateDragging(true);
    }

    public dragEnded(): void {
        this.updateDragging(false);
    }

    private updateDragging(dragging: boolean): void {
        this.dragging.set(dragging);
    }

    public onDrop(event: CdkDragDrop<AssetModel>): void {
        this.transferringAssetItem.set(null);

        if (event) {
            const x = event.dropPoint.x;
            const y = event.dropPoint.y;
            const sortItemElements = this.productAssetExportElement().nativeElement.children;

            let newIndex = -1;
            for (let index = 0; index < sortItemElements.length; index++) {
                const rect = sortItemElements[index].getBoundingClientRect();
                if (rect.x <= x && rect.x + rect.width >= x && rect.y <= y && rect.y + rect.height >= y) {
                    newIndex = index;
                    break;
                }
            }

            if (newIndex > -1) {
                // Same DropListContainer: moving items
                if (event.previousContainer === event.container) {
                    this.selectedProductAssetExportSetupControl().controls.
                    items.at(event.currentIndex).value.asset = undefined;
                }

                this.selectedProductAssetExportSetupControl().controls.items.at(newIndex).value.asset = event.item.data;
            }
        }
    }

    public onProductAssetExportSetupChanged(item: ProductAssetExportSetupModel): void {
        if (item !== this.selectedProductAssetExportSetup()) {
            this.selectedProductAssetExportSetup.set(item);
        }
    }

    public removeProductAsset(index: number): void {
        this.selectedProductAssetExportSetupControl().controls.items.at(index).value.asset = undefined;
    }

    private findExportSortItemAsset(setup: ProductAssetExportSetupModel, sortItem: SortItemModel,
                                    productAssetExports: ProductAssetExportModel[]): AssetModel {
        const productAssetExport = productAssetExports.find(productAssetExport => productAssetExport.productAssetExportSetup === setup._id);
        if (!productAssetExport) return;

        const assetId = productAssetExport.items.find(item => item.sortItem === sortItem._id)?.asset;
        if (!assetId) return;

        return this.selectedAssets().find(asset => asset._id === assetId);
    }
}
