<form [formGroup]="formGroup">
    <nuc-form-field label="Family">
        <nuc-input formControlName="family" placeholder="Family name of the font" required></nuc-input>
    </nuc-form-field>

    <rl-upload-file-component
        title="Source file"
        (onUploadUpdate)="uploadUpdateSubject.next($event)"
        [fileTypeCategories]="fileTypeCategories">
    </rl-upload-file-component>
</form>
