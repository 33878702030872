import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel, FullModalService, NUC_FULL_MODAL_DATA} from '@relayter/rubber-duck';
import {distinctUntilChanged, finalize, map, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {WorkflowAutomationModel, WorkflowAutomationPatchModel, WorkflowAutomationPostModel} from '../../models/api/workflow-automation.model';
import {WorkflowAutomationsService} from '../../api/services/workflow-automations.service';
import {Toaster} from '../../classes/toaster.class';
import {RLValidatorConstants} from '../../classes/validators/rl-validators.constant';
import {EFormStatus} from '../../app.enums';

export interface IWorkflowAutomationFormData {
    workflowAutomation?: WorkflowAutomationModel;
}

@Component({
    selector: 'workflow-automation-form-component',
    templateUrl: 'workflow-automation-form.component.html',
    styleUrls: ['workflow-automation-form.component.scss']
})

export class WorkflowAutomationFormComponent implements OnInit, OnDestroy {
    private onDestroySubject = new Subject<void>();
    private saveButton: ButtonConfig;
    public formGroup: UntypedFormGroup;

    constructor(private fullModalService: FullModalService,
                private workflowAutomationsService: WorkflowAutomationsService,
                @Inject(NUC_FULL_MODAL_DATA) private modalData: IWorkflowAutomationFormData) {
    }

    public ngOnInit(): void {
        this.setupFormGroup();
        this.initModalButtons();
        this.trackFormStatus();
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private setupFormGroup(): void {
        const workflowAutomation = this.modalData.workflowAutomation;
        this.formGroup = new UntypedFormGroup({
            name: new UntypedFormControl(workflowAutomation?.name || '', RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
            description: new UntypedFormControl(workflowAutomation?.description || '')
        });
    }

    private initModalButtons(): void {
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        this.saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', null, false, this.formGroup.status !== EFormStatus.VALID);

        const cancelAction = new FullModalActionModel(cancelButton);
        const saveAction = new FullModalActionModel(this.saveButton);

        cancelAction.observable.subscribe(() => this.fullModalService.close(false, true));
        saveAction.observable.subscribe(() => {
            this.saveButton.loading = true;
            this.modalData?.workflowAutomation ? this.editWorkflowAutomation() : this.createWorkflowAutomation();
        });

        this.fullModalService.setModalActions([cancelAction, saveAction]);
    }

    private trackFormStatus(): void {
        this.formGroup.statusChanges.pipe(
            distinctUntilChanged(),
            map((status) => status === EFormStatus.VALID),
            takeUntil(this.onDestroySubject)
        ).subscribe((isValid: boolean) => this.saveButton.disabled = !isValid);
    }

    private editWorkflowAutomation(): void {
        const id = this.modalData.workflowAutomation._id;
        const formValue = this.formGroup.getRawValue();
        const body = new WorkflowAutomationPatchModel(
            formValue.name, formValue.description);
        this.workflowAutomationsService.patch(id, body)
            .pipe(
                finalize(() => this.saveButton.loading = false),
                takeUntil(this.onDestroySubject)
            )
            .subscribe({
                next: (result) => {
                    this.fullModalService.close(result);
                    Toaster.success('Workflow automation updated successfully');
                },
                error: Toaster.handleApiError
            });
    }

    private createWorkflowAutomation(): void {
        const body = new WorkflowAutomationPostModel(
            this.formGroup.value.name, this.formGroup.value.description);
        this.workflowAutomationsService.create(body)
            .pipe(
                finalize(() => this.saveButton.loading = false),
                takeUntil(this.onDestroySubject)
            )
            .subscribe({
                next: (result) => {
                    this.fullModalService.close(result);
                    Toaster.success('Workflow automation created successfully');
                },
                error: Toaster.handleApiError
            });
    }
}
