@if (formGroup) {
    <form [formGroup]="formGroup">
        @if (selectedVariant) {
            <nuc-label icon="nucicon_variant" iconColorClass="variant-highlight">{{ formGroup.value.variant.name }}</nuc-label>
        }

        <nuc-form-field label="After Effects project file">
            <nuc-dropdown
                formControlName="afterEffectsProjectFile"
                placeholder="Select an After Effects project file"
                [searchable]="true"
                [items]="afterEffectsProjectFiles"
                [total]="afterEffectsProjectFilesTotalItems"
                (requestData)="requestAfterEffectsProjectFiles($event)"
                [required]="true">
            </nuc-dropdown>
        </nuc-form-field>

        <nuc-form-field label="Animated content ruleset">
            <nuc-dropdown
                formControlName="animatedContentRuleset"
                placeholder="Select an animated content ruleset"
                [items]="animatedContentRulesets"
                [required]="true">
            </nuc-dropdown>
        </nuc-form-field>
    </form>
}
