import {Component, DestroyRef, inject, Inject, OnInit, Optional} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {
    BUTTON_TYPE,
    ButtonConfig,
    FullModalActionModel,
    FullModalService,
    NUC_FULL_MODAL_DATA
} from '@relayter/rubber-duck';
import {ARLogger} from '@relayter/core';
import {Toaster} from '../../classes/toaster.class';
import {distinctUntilChanged, finalize, map, startWith} from 'rxjs/operators';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {IUploadUpdate} from '../../components/upload-file-component/upload-file.component';
import {EUploadStatus} from '../../components/upload-file-component/upload.model';
import {
    EMasterPageJobType,
    IAddMasterPageJobData,
    IUpdateMasterPageJobData,
    MasterPagesService
} from '../../api/services/master-pages.service';
import {JobModel} from '../../models/api/job.model';
import {MasterPageModel, MasterPagePatchModel} from '../../models/api/master-page.model';
import {RLValidatorConstants} from '../../classes/validators/rl-validators.constant';
import {FileTypeUtil} from '../../classes/file-type.util';
import {EChannel, EFormStatus} from '../../app.enums';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

interface IMasterPageModalData {
    masterPage?: MasterPageModel;
}

interface IMasterPageForm {
    name: FormControl<string>;
    channel?: FormControl<DropdownItem<EChannel>>;
}

@Component({
    selector: 'rl-master-page-form-component',
    templateUrl: 'master-page-form.component.html',
    styleUrls: ['master-page-form.component.scss']
})
export class MasterPageFormComponent implements OnInit {
    private destroyRef = inject(DestroyRef);
    public formGroup: FormGroup<IMasterPageForm>;
    public channels = Object.values(EChannel).map((v) => new DropdownItem(v, v));

    public uploadUpdateSubject = new BehaviorSubject<IUploadUpdate>(null);
    public uploadUpdate: IUploadUpdate;
    public fileTypeCategories = [FileTypeUtil.CATEGORIES.INDESIGN_TEMPLATE];

    private saveButton: ButtonConfig;
    private readonly masterPage: MasterPageModel;

    constructor(private fullModalService: FullModalService,
                private masterPagesService: MasterPagesService,
                @Optional() @Inject(NUC_FULL_MODAL_DATA) private modalData: IMasterPageModalData) {
        this.masterPage = this.modalData?.masterPage;
    }

    public ngOnInit(): void {
        this.initModalButtons();
        this.initForm();
        this.trackStatus();
    }

    private initModalButtons(): void {
        this.saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', null, null, true);
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        const cancel = new FullModalActionModel(cancelButton);
        cancel.observable.subscribe(() => this.fullModalService.close());
        const save = new FullModalActionModel(this.saveButton);
        save.observable.subscribe(() => this.saveMasterPage());
        this.fullModalService.setModalActions([cancel, save]);
    }

    private initForm(): void {
        const selectedChannel = this.channels.find((channel) => channel.getValue() === this.masterPage?.channel);

        this.formGroup = new FormGroup<IMasterPageForm>({
            name: new FormControl(this.masterPage?.name, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
            channel: new FormControl(
                {value: selectedChannel, disabled: !!this.masterPage}, RLValidatorConstants.VALIDATOR_SETS.REQUIRED)
        });
    }

    private trackStatus(): void {
        combineLatest([
            this.formGroup.statusChanges.pipe(startWith(this.formGroup.status), distinctUntilChanged(),
                map((status) => status === EFormStatus.VALID)),
            this.uploadUpdateSubject.pipe(map((uploadUpdate) => {
                this.uploadUpdate = uploadUpdate;
                // when updating a master page, file is not required
                return uploadUpdate?.status === EUploadStatus.Done || (this.modalData?.masterPage && !uploadUpdate);
            }))])
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(([formValid, uploadValid]) => this.saveButton.disabled = !formValid || !uploadValid);
    }

    private saveMasterPage(): void {
        this.saveButton.loading = true;

        const name = this.formGroup.value.name.trim();
        const channel = this.formGroup.getRawValue()?.channel.getValue();
        const s3Key = this.uploadUpdate?.s3Key;
        const itemId = this.masterPage?._id;
        const obs: Observable<JobModel | MasterPageModel> = this.masterPage ? s3Key
                ? this.masterPagesService.postJob(EMasterPageJobType.UPDATE_MASTER_PAGE, {itemId, name, s3Key} as IUpdateMasterPageJobData)
                : this.masterPagesService.patchMasterPage(itemId, new MasterPagePatchModel(name))
            : this.masterPagesService.postJob(EMasterPageJobType.ADD_MASTER_PAGE, {name, s3Key, channel} as IAddMasterPageJobData);

        obs.pipe(
            finalize(() => this.saveButton.loading = false),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe({
            next: (result) => {
                if (result instanceof JobModel) {
                    ARLogger.debug('InDesign Library Job scheduled: ' + result._id);
                    this.fullModalService.close({jobId: result._id});
                } else {
                    Toaster.success('Master page updated successfully');
                    this.fullModalService.close({});
                }
            },
            error: Toaster.handleApiError
        });
    }
}
