import {Component, DestroyRef, inject, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DropdownItem} from '../../../models/ui/dropdown-item.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {distinctUntilChanged} from 'rxjs/operators';
import {RulePropertyModel} from '../../../models/api/rule-property.model';
import {EWorkflowActionOptionNotificationReceiver} from '../../../models/api/custom-workflow-option.model';
import {EStickyNoteStatus} from '../../../app.enums';
import {UserModel} from '../../../models/api/user.model';
import {RLValidatorConstants} from '../../../classes/validators/rl-validators.constant';
import {FormControlArrayMinLengthValidator} from '../../../classes/validators/form-control-array-min-length.validator';

@Component({
    selector: 'workflow-configuration-action-notification-receiver-form',
    templateUrl: 'workflow-configuration-action-notification-receiver-form.component.html',
    styleUrls: ['workflow-configuration-action-notification-receiver-form.component.scss']
})
export class WorkflowConfigurationActionNotificationReceiverFormComponent implements OnInit {
    private destroyRef = inject(DestroyRef);
    @Input() public formGroup: FormGroup;
    @Input() public properties: RulePropertyModel[];
    @Input() public readonly receiverOptions: DropdownItem<string>[];
    @Input() public readonly noteStatuses: DropdownItem<EStickyNoteStatus>[];
    @Input() public users: UserModel[] = [];
    public readonly VALIDATOR_MESSAGES = RLValidatorConstants.MESSAGES;

    public ngOnInit(): void {
        this.listenToTypeControl();
    }

    public listenToTypeControl(): void {
        this.formGroup.controls.type.valueChanges
            .pipe(
                distinctUntilChanged(),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe((type) => {
                const selectedReceiverType = type?.getTitle();

                switch (selectedReceiverType) {
                    case EWorkflowActionOptionNotificationReceiver.NOTE_CHANGELOG: {
                        this.formGroup.addControl('status', new FormControl(null, Validators.required));
                        this.formGroup.removeControl('users');
                        break;
                    }
                    case EWorkflowActionOptionNotificationReceiver.USER_IDS: {
                        this.formGroup.addControl('users', new FormControl(null, FormControlArrayMinLengthValidator(1)));
                        this.formGroup.removeControl('status');
                        break;
                    }
                    default:
                        this.formGroup.removeControl('status');
                        this.formGroup.removeControl('users');
                        break;
                }
            });
    }
}
