<nuc-table [items]="items"
           [columns]="columns"
           [loading]="!subscription?.closed"
           [actions]="tableActions"
           [tableId]="tableId"
           emptyStateTitle="No publication items. Move publication items to this step."
           [selection]="selection"
           [selectionMode]="tableSelectionMode"
           [prefixStorageKey]="storageKey"
           (actionClicked)="handleTableRowAction($event)"
           (sortOptionChange)="onSortOptionChange($event)">
    <ng-container button-bar-right>
        <ng-container *ngIf="selection.hasValue()">
            <div class="popout-button">
                <nuc-button-secondary
                    text="{{selection.selected.length}} selected {{selection.selected.length === 1 ? 'item' : 'items'}}"
                    icon="nucicon_dots_vertical"
                    iconPosition="end"
                    [matMenuTriggerFor]="menu">
                </nuc-button-secondary>
                <mat-menu #menu="matMenu" class="menu-items-container">
                    <ng-container *ngIf="multiCompatibleActions?.length else noActionsAvailable">
                        <ng-container *ngFor="let action of multiCompatibleActions">
                            <button mat-menu-item
                                    (click)="handleMultiSelectionAction(action)"
                                    [disableRipple]="true">{{action.title}}</button>
                        </ng-container>
                    </ng-container>
                    <ng-template #noActionsAvailable>
                        <button mat-menu-item [disabled]="true" [disableRipple]="true">No actions available</button>
                    </ng-template>
                </mat-menu>
            </div>

            <div class="vertical-line"></div>
        </ng-container>

        <paginator [viewId]="tableId"
                   [loading]="!subscription?.closed"
                   [disableNextPage]="disableNextPage">
        </paginator>
    </ng-container>
</nuc-table>
