import {Component, DestroyRef, inject, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {
    BUTTON_TYPE,
    ButtonConfig,
    FullModalActionModel,
    FullModalService,
    NUC_FULL_MODAL_DATA,
    NucIcons
} from '@relayter/rubber-duck';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {Toaster} from '../../classes/toaster.class';
import {WorkflowConfigurationModel} from '../../models/api/workflow-configuration.model';
import {WorkflowConfigurationsService} from '../../api/services/workflow-configurations.service';
import {
    CUSTOM_WORKFLOW_ACTIONS_CONFIGS,
    CustomWorkflowActionModel,
    EBriefingActionName,
    ETransitionTriggerActionName,
    EWorkflowConfigurationActionType,
    IActionName,
    IActionOptionConfig
} from '../../models/api/custom-workflow-action.model';
import {EChannel, EDataCollectionName, EDataFieldCollectionName, EFormStatus, EStickyNoteStatus} from '../../app.enums';
import {IDropdownRequestDataEvent} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {PermissionsService} from '../../api/services/permissions.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ModelUtil} from '../../classes/model.util';
import {CustomWorkflowStepModel} from '../../models/api/custom-workflow-step.model';
import {
    CUSTOM_WORKFLOW_COMPONENT_TYPES_CONFIGS,
    CustomWorkflowComponentModel,
    IAllowedActionConfig
} from '../../models/api/custom-workflow-component.model';
import {combineLatest, distinctUntilChanged, map, startWith, Subscription} from 'rxjs';
import {
    CustomWorkflowOptionModel,
    EWorkflowActionOptionDisplayType,
    EWorkflowActionOptionName,
    EWorkflowActionOptionNotificationReceiver,
    EWorkflowActionOptionSignOffs
} from '../../models/api/custom-workflow-option.model';
import {EEngineType} from '../../models/api/template.model';
import {
    EPackageOptions,
    EPackageType
// eslint-disable-next-line max-len
} from '../../pages/relayter/campaigns/publication/custom-workflow/custom-workflow-files/custom-workflow-files-download/package-type/files-download-package-type.component';
import {DataFieldsApiService} from '../../api/services/data-fields.api.service';
import {StringUtil} from '../../classes/string-util';
import {TemplateTypeService} from '../../api/services/template-types.service';
import {DataCollectionService} from '../../api/services/data-collection.service';

export interface IWorkflowConfigurationActionFormData {
    workflowConfiguration: WorkflowConfigurationModel;
    action?: CustomWorkflowActionModel;
    step?: CustomWorkflowStepModel;
    component?: CustomWorkflowComponentModel;
}

@Component({
    selector: 'workflow-configuration-action-form-component',
    templateUrl: 'workflow-configuration-action-form.component.html',
    styleUrls: ['workflow-configuration-action-form.component.scss']
})
export class WorkflowConfigurationActionFormComponent implements OnInit {
    private destroyRef: DestroyRef = inject(DestroyRef);
    private templateTypeService = inject(TemplateTypeService);
    private dataCollectionService = inject(DataCollectionService);

    public permissions: DropdownItem<string>[];

    public readonly transitions: DropdownItem<string>[];
    public transitionOptions: DropdownItem<string>[];

    public readonly nucIcons: DropdownItem<string>[];
    public nucIconOptions: DropdownItem<string>[] = [];

    public names: DropdownItem<string>[] = [];
    public nameOptions: DropdownItem<string>[] = [];

    public actionTypeOptions: DropdownItem<EWorkflowConfigurationActionType>[];
    public allowedActionsConfig: IAllowedActionConfig[];

    public formGroup: FormGroup;
    private saveButton: ButtonConfig;

    public optionsFormGroup: FormGroup;
    public EWorkflowActionOptionName = EWorkflowActionOptionName

    private workflowConfiguration: WorkflowConfigurationModel;
    public action: CustomWorkflowActionModel;

    public readonly noteToStatus = Object.values(EStickyNoteStatus)
        .filter(status => ![EStickyNoteStatus.NEW, EStickyNoteStatus.CREATED].includes(status))
        .sort().map(status => new DropdownItem<EStickyNoteStatus>(status, status));

    public readonly noteFromStatus = Object.values(EStickyNoteStatus)
        .filter(status => ![EStickyNoteStatus.NEW].includes(status))
        .sort().map(status => new DropdownItem<EStickyNoteStatus>(status, status));

    public readonly engineTypes = Object.values(EEngineType).map((value) => new DropdownItem<EEngineType>(value, value));
    public readonly channels = Object.values(EChannel).map((value) => new DropdownItem<EChannel>(value, value));

    // all the campaign item dataFields field key collection
    // either with 'dataFields.' or without, we should align it
    public displayOptions: DropdownItem<string>[];
    public campaignItemFieldOptions: DropdownItem<string>[];
    public templateTypeOptions: DropdownItem<string>[];
    public templateTagsOptions: DropdownItem<any>[];
    public displayType: EWorkflowActionOptionDisplayType; // temporary solution

    // sign off (publication item approval) options
    public signOffOptions: DropdownItem<string>[] = [
        new DropdownItem<string>('Keep approvals', EWorkflowActionOptionSignOffs.KEEP),
        new DropdownItem<string>('Remove approvals', EWorkflowActionOptionSignOffs.REMOVE),
        new DropdownItem<string>('Show confirmation popup', EWorkflowActionOptionSignOffs.SHOW_CONFIRM),
    ];

    // TODO: we should filter this out base on publication type
    public readonly packageOptions = Object.values(EPackageType)
        .filter((value) => value !== EPackageType.CUSTOM_EXPORT_PACKAGE)
        .map((value) => new DropdownItem<EPackageType>(value, value));

    // for now, we only support one type
    public scaleTypes = [new DropdownItem('Box size', 'BOX_SIZE')];
    public formatOptions = ['PNG', 'JPG', 'JPEG', 'TIF', 'TIFF', 'GIF'].map((v) => new DropdownItem(v, v));
    public customExportPackageControlSubscription: Subscription;

    public sendNotificationControlSubscription: Subscription;

    public notificationOptionValue: any;

    constructor(private fullModalService: FullModalService,
                private workflowConfigurationService: WorkflowConfigurationsService,
                private dataFieldsService: DataFieldsApiService,
                private permissionService: PermissionsService,
                @Inject(NUC_FULL_MODAL_DATA) public modalData: IWorkflowConfigurationActionFormData) {
        this.workflowConfiguration = this.modalData.workflowConfiguration;
        this.action = this.modalData.action || new CustomWorkflowActionModel();

        this.transitions = this.workflowConfiguration.transitions
            .filter((transition) =>  !this.modalData.step || transition.from === this.modalData.step._id)
            .map(transition =>
                new DropdownItem(transition.name, transition._id));

        this.transitionOptions = this.transitions;

        this.nucIcons = [...NucIcons].sort().map(nucIcon =>
            new DropdownItem(
                nucIcon,
                `nucicon_${nucIcon}`,
                null,
                `nucicon_${nucIcon}`
            ));

        this.allowedActionsConfig = this.modalData.component
            ? CUSTOM_WORKFLOW_COMPONENT_TYPES_CONFIGS.find((config) => config.type === this.modalData.component.componentType).allowedActionsConfig
            : [{ actionType: EWorkflowConfigurationActionType.TRANSITION_TRIGGER, names: [ETransitionTriggerActionName.TRANSITION_TRIGGER] }];

        this.actionTypeOptions = this.allowedActionsConfig.filter((item) => !!item.actionType)
            .map((item) => new DropdownItem<EWorkflowConfigurationActionType>(item.actionType, item.actionType));
    }

    public ngOnInit(): void {
        this.initData();
        this.initModalButtons();
    }

    private initModalButtons(): void {
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        this.saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', null, false, true);

        const cancelAction = new FullModalActionModel(cancelButton);
        const saveAction = new FullModalActionModel(this.saveButton);

        cancelAction.observable.subscribe(() => this.fullModalService.close(false, true));
        saveAction.observable.subscribe(() => this.saveWorkflowConfigurationAction());
        this.fullModalService.setModalActions([cancelAction, saveAction]);
    }

    private initData(): void {
        this.nucIconOptions = [...this.nucIcons];

        combineLatest([
            this.permissionService.getAllPermissions('key', 'asc'),
            this.dataFieldsService.getAllDataFields(EDataFieldCollectionName.CAMPAIGN_ITEM),
            this.templateTypeService.getTemplateTypes(),
            this.dataCollectionService.getDataCollectionValues(EDataCollectionName.TEMPLATE, 'tags', null)
        ]).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: ([permissions, campaignItemDataFields, templateTypes, templateTags]) => {
                    this.permissions = permissions.map(permission => new DropdownItem(
                        permission._id,
                        permission._id
                    ));
                    this.displayOptions = campaignItemDataFields.map((field) => new DropdownItem(field.name, field.fieldName));
                    this.campaignItemFieldOptions = campaignItemDataFields.map((field) =>
                        new DropdownItem(field.name, `dataFields.${field.fieldName}`));
                    this.templateTypeOptions = templateTypes.items.map((templateType) => new DropdownItem(templateType.name, templateType._id));
                    this.templateTagsOptions = templateTags.items.map((templateTag) => new DropdownItem(templateTag.title, templateTag.value));

                    this.initForm();
                    this.listenToFormStatus();
                    this.listenToTypeControl();
                    this.listenToNameControl();
                },
                error: Toaster.handleApiError
            });
    }

    private initForm(): void {
        const transition = this.transitionOptions.find(transition => transition.getValue() === this.action?.transition);
        const icon = this.nucIcons.find(icon => icon.getValue() === this.action?.icon);
        // set the default type GENERAL_ACTION
        const type = this.actionTypeOptions.find(type => type.getValue() === (this.action?.type || EWorkflowConfigurationActionType.GENERAL_ACTION));

        const permissions = this.permissions.filter(permission => this.action.permissions?.includes(permission.getValue()));

        this.names = (this.allowedActionsConfig.find((config) => {
            return config.actionType === type?.getValue();
        })?.names || [])
            .map((name) => CUSTOM_WORKFLOW_ACTIONS_CONFIGS.find((config) => config.name === name))
            .map((config) => new DropdownItem<string>(config.title, config.name));

        this.nameOptions = [...this.names];

        const name = this.names.find((name) => name.getValue() === this.action.name);

        this.formGroup = new FormGroup<any>({
            name: new FormControl(name, Validators.required),
            title: new FormControl(this.action.title),
            type: new FormControl(type),
            permissions: new FormControl(permissions),
            icon: new FormControl(icon, Validators.required)
        });

        // TODO: remove the later part when we have a better solution for BRIEFING_ACTION with transition
        if (type?.getValue() === EWorkflowConfigurationActionType.TRANSITION_TRIGGER || name?.getValue() === EBriefingActionName.GENERATE) {
            this.formGroup.addControl('transition', new FormControl(transition, Validators.required));
        }

        const actionConfig = CUSTOM_WORKFLOW_ACTIONS_CONFIGS.find((config) => config.name === name?.getValue());

        if (actionConfig?.fromApplicable) {
            const statusFrom = this.noteFromStatus.filter(status => this.action.from?.includes(status.getValue()));
            this.formGroup.addControl('from', new FormControl(statusFrom));
        }

        if (actionConfig?.toApplicable) {
            const statusTo = this.noteToStatus.find(status => status?.getValue() === this.action.to);
            this.formGroup.addControl('to', new FormControl(statusTo, Validators.required));
        }

        if (actionConfig?.optionsConfig) this.addOptionsFormGroup(actionConfig.optionsConfig);
    }

    private addOptionsFormGroup(optionsConfig: IActionOptionConfig[]): void {

        this.optionsFormGroup = new FormGroup({});

        for (const optionConfig of optionsConfig) {
            const value = this.action.options?.find((option) => optionConfig.name === option.name)?.value;

            switch (optionConfig.name) {
                // all boolean values
                case EWorkflowActionOptionName.ADD_PACKAGE_DATA:
                case EWorkflowActionOptionName.ADD_NOTES:
                case EWorkflowActionOptionName.AUTO_ASSIGN:
                case EWorkflowActionOptionName.TEMPLATE_FILTER_CAMPAIGN_TAGS:
                case EWorkflowActionOptionName.ADD_FOR_RELATED_PUBLICATION_ITEMS: {
                    this.optionsFormGroup.addControl(optionConfig.name, new FormControl(value));
                    break;
                }
                case EWorkflowActionOptionName.ENGINE_TYPES: {
                    const selectedEngineTypes = value ? this.engineTypes.filter((option) => value.includes(option.getValue())) : [];
                    this.optionsFormGroup.addControl(optionConfig.name, new FormControl(selectedEngineTypes));
                    break;
                }
                case EWorkflowActionOptionName.REMOVE_SIGN_OFFS: {
                    const selectedOption = this.signOffOptions.find((option) => option.getValue() === value);
                    this.optionsFormGroup.addControl(optionConfig.name, new FormControl(selectedOption, Validators.required));
                    break;
                }
                case EWorkflowActionOptionName.DISPLAY: {
                    this.displayType = optionConfig.type as EWorkflowActionOptionDisplayType;
                    // currently we need to switch ¯\_(ツ)_/¯
                    if (optionConfig.type === EWorkflowActionOptionDisplayType.MULTI) {
                        const selectedValues = value ? this.displayOptions.filter((option) => value.includes(option.getValue())) : [];
                        this.optionsFormGroup.addControl(optionConfig.name, new FormControl(selectedValues));
                    } else {
                        const selectedValue = value ? this.displayOptions.find((option) => value === option.getValue()) : null;
                        this.optionsFormGroup.addControl(optionConfig.name, new FormControl(selectedValue));
                    }
                    break;
                }
                case EWorkflowActionOptionName.CAMPAIGN_ITEM_FIELDS: {
                    const selectedValues = value ? this.campaignItemFieldOptions.filter((option) => value.includes(option.getValue())) : [];
                    this.optionsFormGroup.addControl(optionConfig.name, new FormControl(selectedValues));
                    break;
                }
                case EWorkflowActionOptionName.PACKAGES: {
                    const selectedValues = value
                        ? this.packageOptions.filter((option) => value?.map((v) => v.type).includes(option.getValue()))
                        : [];

                    const customExportPackage = value?.find((v) => v.type === EPackageType.CUSTOM_EXPORT_PACKAGE);
                    const customExportPackageControl = new FormControl(!!customExportPackage);

                    const packageFormGroup: FormGroup = new FormGroup({
                        packageTypes: new FormControl(selectedValues),
                        customExportPackage: customExportPackageControl,
                    });

                    if (!!customExportPackage) {
                        packageFormGroup.addControl('customExportPackageData', this.setupCustomExportPackageForm(customExportPackage?.options));
                    }

                    // avoid memory leak
                    this.customExportPackageControlSubscription?.unsubscribe();
                    this.customExportPackageControlSubscription = customExportPackageControl.valueChanges
                        .pipe(takeUntilDestroyed(this.destroyRef))
                        .subscribe((value) => {
                            const formGroup = this.optionsFormGroup.controls[optionConfig.name] as FormGroup;
                            if (value) {
                                formGroup.addControl('customExportPackageData', this.setupCustomExportPackageForm(customExportPackage?.options));
                            } else {
                                formGroup.removeControl('customExportPackageData');
                            }
                        });
                    this.optionsFormGroup.addControl(optionConfig.name, packageFormGroup);
                    break;
                }
                case EWorkflowActionOptionName.NOTIFICATION: {
                    this.notificationOptionValue = value || {};
                    this.notificationOptionValue.type = optionConfig.type; // make sure type is correct

                    const sendNotificationsControl = new FormControl(!!value);
                    const notificationFormGroup = new FormGroup({
                        sendNotifications: sendNotificationsControl
                    });

                    this.optionsFormGroup.addControl(optionConfig.name, notificationFormGroup);

                    // avoid memory leak
                    this.sendNotificationControlSubscription?.unsubscribe();
                    this.sendNotificationControlSubscription = sendNotificationsControl.valueChanges
                        .pipe(
                            startWith(!!value),
                            takeUntilDestroyed(this.destroyRef)
                        )
                        .subscribe((value: boolean) => {
                            const formGroup = this.optionsFormGroup.controls[optionConfig.name] as FormGroup;
                            if (value) {
                                formGroup.addControl('notificationData', new FormGroup({}));
                            } else {
                                formGroup.removeControl('notificationData');
                            }
                        });
                    break;
                }
                case EWorkflowActionOptionName.CHANNEL: {
                    const selectedChannel = this.channels.find((channel) => channel.getValue() === value);
                    this.optionsFormGroup.addControl(optionConfig.name, new FormControl(selectedChannel));
                    break;
                }
                case EWorkflowActionOptionName.TEMPLATE_FILTER_TEMPLATE_TAGS: {
                    const selectedValues = value ? this.templateTagsOptions.filter((option) => value.includes(option.getValue())) : [];
                    this.optionsFormGroup.addControl(optionConfig.name, new FormControl(selectedValues));
                    break;
                }
                case EWorkflowActionOptionName.TEMPLATE_FILTER_TEMPLATE_TYPES: {
                    const selectedValues = value ? this.templateTypeOptions.filter((option) => value.includes(option.getValue())) : [];
                    this.optionsFormGroup.addControl(optionConfig.name, new FormControl(selectedValues));
                    break;
                }
                default:
                    Toaster.error(`Workflow action option: ${optionConfig.name} is not supported yet.`);
            }
        }

        // this is to help with the save button status, otherwise we can use two forms
        this.formGroup.addControl('options', this.optionsFormGroup);
    }

    private setupCustomExportPackageForm(customExportPackageData: any[]): FormGroup {
        return Object.keys(EPackageOptions).reduce((formGroup, optionName) => {
            const value = customExportPackageData?.find((option) => option.name === optionName)?.value;
            switch (optionName) {
                case EPackageOptions.SIZE: {
                    const scaleType = this.scaleTypes.find((v) => v.getValue() === value?.scaleType);
                    formGroup.addControl('scaleType', new FormControl(scaleType, Validators.required));
                    formGroup.addControl('width', new FormControl(value?.width, Validators.required));
                    formGroup.addControl('height', new FormControl(value?.height, Validators.required));
                    break;
                }
                case EPackageOptions.FORMAT: {
                    const foundFormat = this.formatOptions.find((format) => format.getValue() === value);
                    formGroup.addControl(optionName, new FormControl(foundFormat, Validators.required));
                    break;
                }
                default:
                    formGroup.addControl(optionName, new FormControl(value, Validators.required));
                    break;
            }
            return formGroup;
        }, new FormGroup({}));
    }

    private listenToFormStatus(): void {
        // only to the form after everything is initialized
        this.formGroup.statusChanges.pipe(
            distinctUntilChanged(),
            map((status) => status === EFormStatus.VALID),
            startWith(this.formGroup.status === EFormStatus.VALID),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((valid) => this.saveButton.disabled = !valid);
    }

    private listenToTypeControl(): void {
        this.formGroup.get('type').valueChanges
            .pipe(
                distinctUntilChanged(),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe((value) => {
                this.names = this.allowedActionsConfig.find((config) => {
                    return config.actionType === value?.getValue()
                }).names.map((name) => CUSTOM_WORKFLOW_ACTIONS_CONFIGS.find((config) => config.name === name))
                    .map((config) => new DropdownItem<string>(config.title, config.name));

                this.nameOptions = [...this.names];

                // always reset the name when action type is changed
                this.formGroup.patchValue({name: null});

                if (value?.getValue() === EWorkflowConfigurationActionType.TRANSITION_TRIGGER) {
                    this.formGroup.addControl('transition', new FormControl('', Validators.required));
                } else {
                    this.formGroup.removeControl('transition');
                }
            });
    }

    private listenToNameControl(): void {
        this.formGroup.get('name').valueChanges
            .pipe(
                distinctUntilChanged(),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe((name: DropdownItem<IActionName>) => {
                // TODO: eventually remove this block. Currently GENERATE is a briefing action with transition ¯\_(ツ)_/¯
                if (name?.getValue() === EBriefingActionName.GENERATE) {
                    this.formGroup.addControl('transition', new FormControl('', Validators.required));
                } else {
                    // if necessary remove the control
                    if (this.formGroup.value.type?.getValue() !== EWorkflowConfigurationActionType.TRANSITION_TRIGGER) {
                        this.formGroup.removeControl('transition');
                    }
                }

                const actionConfig = CUSTOM_WORKFLOW_ACTIONS_CONFIGS.find((config) => config.name === name?.getValue());

                if (actionConfig?.fromApplicable) {
                    const statusFrom = this.noteFromStatus.filter(status => this.action.from?.includes(status.getValue()));
                    this.formGroup.addControl('from', new FormControl(statusFrom));
                } else {
                    this.formGroup.removeControl('from');
                }

                if (actionConfig?.toApplicable) {
                    const statusTo = this.noteToStatus.find(status => status?.getValue() === this.action.to);
                    this.formGroup.addControl('to', new FormControl(statusTo, Validators.required));
                } else {
                    this.formGroup.removeControl('to');
                }

                // remove first
                this.formGroup.removeControl('options');
                this.optionsFormGroup = null; // reset the optionsFormGroup

                if (actionConfig?.optionsConfig) this.addOptionsFormGroup(actionConfig.optionsConfig);
            });
    }

    private saveWorkflowConfigurationAction(): void {
        const formValue = this.formGroup.value;

        const action = ModelUtil.createApiBody({
            name: formValue.name.getValue(),
            title: formValue.title,
            type: formValue.type.getValue() === EWorkflowConfigurationActionType.GENERAL_ACTION ? null : formValue.type.getValue(),
            from: formValue.from?.map((status: DropdownItem<EStickyNoteStatus>) => status.getValue()) || [],
            to: formValue.to?.getValue(),
            transition: formValue.transition?.getValue(),
            icon: formValue.icon?.getValue(),
            permissions: formValue.permissions?.map((permission: DropdownItem<string>) => permission.getValue()) || [],
            options: this.optionsFormGroup ? this.createOptionsBody() : []
        }, this.action._id);

        if (this.action._id) {
            this.workflowConfigurationService.patchWorkflowConfigurationAction(this.workflowConfiguration._id,
                this.action._id, action, this.modalData.component?._id, this.modalData.step?._id)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: workflowConfiguration => {
                        this.fullModalService.close(workflowConfiguration);
                        Toaster.success('Action updated successfully');
                    },
                    error: Toaster.handleApiError
                });
        } else {
            this.workflowConfigurationService.createWorkflowConfigurationAction(this.workflowConfiguration._id, action,
                this.modalData.component?._id, this.modalData.step?._id)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: workflowConfiguration => {
                        this.fullModalService.close(workflowConfiguration);
                        Toaster.success('Action created successfully');
                    },
                    error: Toaster.handleApiError
                });
        }
    }

    private createOptionsBody(): CustomWorkflowOptionModel[] {
        const formattedOptions = [];

        for (const [optionName, value] of Object.entries(this.optionsFormGroup.value)) {
            if (!value || (Array.isArray(value) && value.length === 0)) continue;

            switch (optionName) {
                case EWorkflowActionOptionName.ADD_PACKAGE_DATA:
                case EWorkflowActionOptionName.ADD_NOTES:
                case EWorkflowActionOptionName.AUTO_ASSIGN:
                case EWorkflowActionOptionName.TEMPLATE_FILTER_CAMPAIGN_TAGS:
                case EWorkflowActionOptionName.ADD_FOR_RELATED_PUBLICATION_ITEMS: {
                    // boolean field
                    formattedOptions.push({name: optionName, value});
                    break;
                }
                case EWorkflowActionOptionName.CHANNEL:
                    // single select
                    formattedOptions.push({name: optionName, value: (value as DropdownItem<any>).getValue()});
                    break;
                case EWorkflowActionOptionName.REMOVE_SIGN_OFFS:
                    // single select
                    formattedOptions.push({name: optionName, value: (value as DropdownItem<EWorkflowActionOptionSignOffs>).getValue()});
                    break;
                case EWorkflowActionOptionName.DISPLAY:
                    // we trust the form group
                    const formattedValue = Array.isArray(value)
                        ? (value as DropdownItem<string>[]).map((v) => v.getValue())
                        : (value as DropdownItem<string>).getValue();
                    formattedOptions.push({
                        name: optionName,
                        value: formattedValue
                    });
                    break;
                case EWorkflowActionOptionName.TEMPLATE_FILTER_TEMPLATE_TAGS:
                case EWorkflowActionOptionName.TEMPLATE_FILTER_TEMPLATE_TYPES:
                case EWorkflowActionOptionName.CAMPAIGN_ITEM_FIELDS:
                case EWorkflowActionOptionName.ENGINE_TYPES:
                    formattedOptions.push({
                        name: optionName,
                        value: (value as DropdownItem<string>[]).map((v) => v.getValue())
                    });
                    break;
                case EWorkflowActionOptionName.PACKAGES:
                    // get the package form
                    const packageTypes = (value['packageTypes'] as DropdownItem<EPackageType>[]).map((v) => {
                        return {type: v.getValue()};
                    });
                    if (value['customExportPackage']) {
                        const customExportType = {type: EPackageType.CUSTOM_EXPORT_PACKAGE};
                        customExportType['options'] = Object.keys(EPackageOptions).map((name) => {
                            switch(name) {
                                case EPackageOptions.SIZE: {
                                    return {
                                        name,
                                        value: {
                                            scaleType: value['customExportPackageData']['scaleType'].getValue(),
                                            height: value['customExportPackageData']['height'],
                                            width: value['customExportPackageData']['width']
                                        }
                                    }
                                }
                                case EPackageOptions.FORMAT: {
                                    return {name, value: value['customExportPackageData'][name]?.getValue()};
                                }
                                default:
                                    return {name, value: value['customExportPackageData'][name]};
                            }
                        });
                        packageTypes.push(customExportType);
                    }
                    formattedOptions.push({name: optionName, value: packageTypes});
                    break;
                case EWorkflowActionOptionName.NOTIFICATION:
                    // when sendNotification checkbox is not toggled we skip this action option
                    if (!value['sendNotifications']) break;

                    const notificationValue = value['notificationData'];

                    const receivers = notificationValue['receivers'].map((receiver) => {
                        const type = receiver.type.getValue();
                        const result = {type};
                        if (type === EWorkflowActionOptionNotificationReceiver.NOTE_CHANGELOG) {
                            result['property'] = 'status';
                            result['value'] = receiver.status.getTitle();
                        }
                        if (type === EWorkflowActionOptionNotificationReceiver.USER_IDS) {
                            result['value'] = receiver.users.map((user) => user.getValue());
                        }
                        return result;
                    });

                    const campaignItemDataFields = notificationValue['campaignItemDataFields']?.map((v) => v.getValue());

                    formattedOptions.push({
                        name: optionName,
                        value: {
                            type: this.notificationOptionValue.type,
                            actionName: this.formGroup.value.title,
                            campaignItemDataFields,
                            receivers: receivers
                        }
                    });

                    break;
                default:
                    Toaster.error(`Workflow action option: ${optionName} is not supported yet.`);
                    break;
            }
        }
        return formattedOptions;

    }

    public searchTransitions(event: IDropdownRequestDataEvent): void {
        if (event.reset) this.transitionOptions = [];

        if (event.search) {
            const regex = new RegExp(StringUtil.escapeRegExp(event.search), 'i');
            this.transitionOptions = this.transitions.filter((transition) =>
                transition.getTitle().match(regex)?.length > 0);
        } else {
            this.transitionOptions = this.transitions;
        }
    }

    public searchIcons(event: IDropdownRequestDataEvent): void {
        if (event.reset) this.nucIconOptions = [];

        if (event.search) {
            const regex = new RegExp(StringUtil.escapeRegExp(event.search), 'i');
            this.nucIconOptions = this.nucIcons.filter((icon) =>
                icon.getTitle().match(regex)?.length > 0);
        } else {
            this.nucIconOptions = this.nucIcons;
        }
    }

    public searchNames(event: IDropdownRequestDataEvent): void {
        if (event.search) {
            const regex = new RegExp(StringUtil.escapeRegExp(event.search), 'i');
            this.nameOptions = this.names.filter((name) =>
                name.getTitle().match(regex)?.length > 0 || name.getValue().match(regex)?.length > 0)
        } else {
            this.nameOptions = this.names;
        }
    }

    protected readonly EWorkflowActionOptionDisplayType = EWorkflowActionOptionDisplayType;
}
