import {inject, Injectable} from '@angular/core';
import {ISocketMessageBody, SocketService} from './socket/socket.service';
import {Observable} from 'rxjs';
import {ARApiUrlBuilderService, ARPagedResponseDataModel, ARRequestOptions, ARResponseModel} from '@relayter/core';
import {environment} from '../../../environments/environment';
import {ApiConstants} from '../api.constant';
import {BaseApiRequestService} from './base-api-request.service';
import {QueryParams} from '../../classes/query-params';
import {ENotificationStatus, NotificationModel} from '../../models/api/notification.model';
import {filter} from 'rxjs/operators';
import {ENotificationEventType} from './socket/constants/socket-events';
import {SortDirection} from '@angular/material/sort';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Injectable()
export class NotificationsService extends BaseApiRequestService {
    private socketService = inject(SocketService);

    public notificationUpdates$: Observable<ISocketMessageBody>;

    constructor() {
        super();

        this.initialize();
    }

    private initialize(): void {
        // Create observable for notification updates
        this.notificationUpdates$ = this.socketService.personalMessages$
            .pipe(
                filter((update) => update.type === ENotificationEventType.NOTIFICATION_UPDATE),
                takeUntilDestroyed(this.destroyRef),
            );
    }

    public setNotificationStatus(notificationId: string, status: ENotificationStatus): Observable<ARResponseModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_NOTIFICATIONS,
            notificationId]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.PATCH;
        options.url = url;
        options.body = {
            status
        };

        return new Observable((obs) => this.handleDetailResponse(options, obs, NotificationModel));
    }

    public getNotifications(limit?: number,
                            offset?: number,
                            sortProperty?: string,
                            sortOrder?: SortDirection,
                            status?: ENotificationStatus[]): Observable<ARPagedResponseDataModel<NotificationModel>> {
        const queryParams = new QueryParams()
            .setSortAndSortDirectionParams(sortProperty, sortOrder)
            .setLimitAndOffsetParams(limit, offset);

        if (status) {
            queryParams.addParam('status', status);
        }

        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_NOTIFICATIONS], queryParams.getParams());
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;

        return new Observable((obs) => this.handlePagedResponse(options, obs, NotificationModel));
    }
}
