<form *ngIf="formGroup" [formGroup]="formGroup">
    <div class="component-form">
        <nuc-form-field label="Name">
            <nuc-input formControlName="name" placeholder="Enter name" required></nuc-input>
        </nuc-form-field>

        <nuc-form-field label="Component type">
            <nuc-dropdown
                searchable="true"
                formControlName="componentType"
                placeholder="Choose a component type"
                [nullOption]="false"
                [items]="componentTypes"
                (requestData)="onRequestComponentTypes($event)"
                [total]="componentTypes.length">
            </nuc-dropdown>
            <!--Temporarily shows suggestive component for deprecated component-->
            <ng-container *ngIf="formGroup.controls.componentType.getRawValue()?.getValue()?.canBeReplacedBy">
                <nuc-error *ngIf="formGroup.controls.componentType.errors?.canBeReplaceBy">
                    {{formGroup.controls.componentType.errors.canBeReplaceBy}}
                </nuc-error>
            </ng-container>
        </nuc-form-field>

    </div>

    <div *ngIf="formGroup.controls.options" class="options-form">

        <form [formGroup]="formGroup.controls.options">
            <ng-container *ngFor="let option of formGroup.controls.options.controls | keyvalue">
                <ng-container [ngSwitch]="option.key">
                    <h2>Column config</h2>
                    <p>Set up the columns for your component</p>
                    <hr />
                    <div *ngSwitchCase="EWorkflowComponentOptionName.COLUMN_CONFIG">
                        <form *ngFor="let columnGroup of option.value.controls; let i = index"
                              class="column-row"
                              [formGroup]="columnGroup">
                            <nuc-form-field label="Display name">
                                <nuc-input formControlName="displayName"
                                           placeholder="Enter display name"
                                           required>
                                </nuc-input>
                            </nuc-form-field>

                            <nuc-form-field label="Property">
                                <nuc-dropdown formControlName="property"
                                              [searchable]="true"
                                              (requestData)="onSearchProperty($event)"
                                              [nullOption]="false"
                                              [total]="columnConfigProperties.length"
                                              [items]="columnConfigProperties"
                                              placeholder="Select a property">
                                </nuc-dropdown>
                            </nuc-form-field>

                            <div class="button">
                                <div></div>
                                <nuc-button-secondary
                                    icon="nucicon_trash_fill"
                                    nucTooltip="Add column"
                                    (click)="removeColumn(option.value, i)">
                                </nuc-button-secondary>
                            </div>
                        </form>
                    </div>
                    <nuc-button-bar>
                        <nuc-button-secondary
                            icon="nucicon_add"
                            nucTooltip="Add column"
                            (click)="addColumn(option.value)"></nuc-button-secondary>
                    </nuc-button-bar>
                </ng-container>
            </ng-container>
        </form>
    </div>
</form>
