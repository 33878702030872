import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {BUTTON_TYPE} from '@relayter/rubber-duck';
import {Subject} from 'rxjs';
import {IEventData, UploadItemFilesFormComponent} from '../upload-item-files-form.component';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
    selector: 'upload-item-files-inline-form-component',
    templateUrl: '../upload-item-files-form.component.html',
    styleUrls: ['../upload-item-files-form.component.scss']
})
export class UploadItemFilesInlineFormComponent extends UploadItemFilesFormComponent implements OnInit, OnChanges {
    public isInline = true;

    public buttonTypes = BUTTON_TYPE;

    @Input() public publicationItemId: string;
    @Input() public transitionItemId: string;
    @Output() public uploadedData: EventEmitter<IEventData> = new EventEmitter();

    public ngOnInit() {
        super.ngOnInit()

        this.publicationItem$.pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(publicationItem => this.publicationItems = [publicationItem])
    }

    public ngOnChanges(changes: SimpleChanges): void {
        // Update data when transition item is done
        if (!changes.transitionItemId.currentValue && changes.transitionItemId.previousValue) {
            this.confirmButton.disabled = false;
            this.uploadItemFiles = [];
            this.getPublicationItem();
        }
    }

    protected initButtons(): void {
        super.initButtons();

        this.actions = [this.confirmAction];
    }

    /** Announces user clicks on action buttons */
    public buttonClicked(actionSubject: Subject<void>): void {
        actionSubject.next();
    }

    protected handleUploadData(result?: IEventData) {
        this.uploadedData.emit(result);
        this.confirmButton.disabled = true;
    }
}
