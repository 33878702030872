import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {EColumnType, ESelectionMode, FullModalConfig, FullModalService, ITableColumn} from '@relayter/rubber-duck';
import {AdvancedFiltersDataService} from '../../../../../../api/services/advanced-filters.data-service';
import {ISocketMessageBody, SocketService} from '../../../../../../api/services/socket/socket.service';
import {
    CustomWorkflowSelectionModalComponent,
    ICustomWorkflowSelectionModalData
} from '../custom-workflow-briefing-actions/custom-workflow-selection-modal/custom-workflow-selection-modal.component';
import {UserIsAllowedToPipe} from '../../../../../../pipes/user-is-allowed-to.pipe';
import {UserSettingsStorageService} from '../../../../../../api/services/user-settings-storage.service';
import {AppConstants} from '../../../../../../app.constants';
import {PublicationModel} from '../../../../../../models/api/publication.model';
import {CampaignModel} from '../../../../../../models/api/campaign.model';
import {CustomWorkflowComponentModel} from '../../../../../../models/api/custom-workflow-component.model';
import {CampaignItemModel} from '../../../../../../models/api/campaign-item.model';
import {TableActionTypeModel} from '../../../../../../models/ui/table-action-type.model';
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {EJobStatus} from '../../../../../../models/api/job.model';
import {EDataFieldCollectionName} from '../../../../../../app.enums';
import {RLTableComponent} from '../../../../../../components/rl-base-component/rl-table.component';
import {SelectionModel} from '@angular/cdk/collections';
import {PaginatorService} from '../../../../../../components/paginator/paginator.service';
import {BriefingTableComponent} from '../../../../../../components/briefing-table/briefing-table.component';
import {EWorkflowConfigurationActionType} from '../../../../../../models/api/custom-workflow-action.model';

@Component({
    selector: 'rl-custom-workflow-multi-selection-component',
    templateUrl: './custom-workflow-multi-selection.component.html',
    styleUrls: ['./custom-workflow-multi-selection.component.scss'],
    providers: [AdvancedFiltersDataService, PaginatorService]
})
export class CustomWorkflowMultiSelectionComponent extends RLTableComponent implements OnInit, OnDestroy {
    public readonly tableId = 'custom-workflow-multi-selection-table';

    public selection = new SelectionModel<CampaignItemModel>(true, [], true);
    public ESelectionMode = ESelectionMode;

    @Input() public publication: PublicationModel;
    @Input() public campaign: CampaignModel;
    @Input() public component: CustomWorkflowComponentModel;

    public columns: ITableColumn[];
    public readonly EDataFieldCollectionName = EDataFieldCollectionName;

    public additionalColumns: ITableColumn[] = [{
        title: 'Generated',
        key: 'itemsGenerated',
        type: EColumnType.ICON,
        iconClass: (value) => value > 0 ? 'nucicon_check_round_fill' : '',
        format: (value) => value > 0 ? value : 'No items',
        color: AppConstants.FIRST_BRAND_COLOR,
        sortProperty: 'itemsGenerated'
    }];

    public total: number;
    public pageIndex: number;
    public pageSize: number;
    public items: CampaignItemModel[];

    private onDestroySubject = new Subject<void>();

    public disableNextPage = true;
    public actions: TableActionTypeModel[];

    @ViewChild(BriefingTableComponent)
    private briefingTable: BriefingTableComponent;

    constructor(protected fullModalService: FullModalService,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private socketService: SocketService,
                userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);
    }

    public ngOnInit(): void {
        this.socketService.publicationUpdates$.pipe(
            filter((event: ISocketMessageBody) => event.data?.status === EJobStatus.DONE),
            takeUntil(this.onDestroySubject)
        ).subscribe(() => {
            this.refreshData();
        });
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private refreshData(): void {
        this.briefingTable.getCampaignItems();
    }

    public openTemplateSelectionModal(): void {
        // Currently, cannot find by name, because this is for both pos and web
        // it's safer if in the future we can use the action name to filter out the correct action
        const allowedActions = this.component.actions.filter((action) => this.userIsAllowedToPipe.transform(action.permissions));

        const transitionAction = allowedActions.find(
            (action) => action.type === EWorkflowConfigurationActionType.TRANSITION_TRIGGER);

        const modalData: ICustomWorkflowSelectionModalData = {
            campaignItemIds: this.selection.selected.map(item => item._id),
            publication: this.publication,
            action: transitionAction,
            campaign: this.campaign,
            multiSelection: true
        };
        const config = new FullModalConfig(
            `Add ${this.publication.channel.getTitle()} items`,
            'Select the template(s) for your items.',
            modalData
        );
        config.confirmClose = true;
        this.fullModalService.open(CustomWorkflowSelectionModalComponent, config)
            .afterClosed()
            .subscribe((result) => {
                if (result) this.selection.clear(); // reset selected items
            });
    }
}
