import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {RLValidatorConstants} from '../../../classes/validators/rl-validators.constant';
import {ETabFormGroup} from '../package-setup-form.component';
import {PackageSetupModel} from '../../../models/api/package-setup.model';
import {RulePropertyModel} from '../../../models/api/rule-property.model';
import {VariantModel} from '../../../models/api/variant.model';
import {RulePropertyUtil} from '../../../classes/rule-property.util';
import {EDataFieldTypes} from '../../../app.enums';
import {DropdownItem} from '../../../models/ui/dropdown-item.model';
import {TitleCasePipe} from '@angular/common';

@Component({
    selector: 'package-setup-package-data-form-component',
    templateUrl: 'package-setup-package-data-form.component.html',
    styleUrls: ['package-setup-package-data-form.component.scss'],
    providers: []
})
export class PackageSetupPackageDataFormComponent implements OnInit {
    @Input() public form: UntypedFormGroup;
    @Input() public packageSetup: PackageSetupModel;
    @Input() public properties: RulePropertyModel[] = [];
    @Input() public variants: VariantModel[] = [];
    public materialDataTypes = [EDataFieldTypes.STRING, EDataFieldTypes.NUMBER].map((v) => {
        return new DropdownItem(this.titleCasePipe.transform(v), v);
    });

    public packageDataFormGroup: UntypedFormGroup;

    public readonly dataTypes = [
        {
            name: 'Material Data',
            description: 'Add one or more material data fields to show in the package template. \n' +
                'Material data are used to add extra information to the overall package.',
            formArrayName: 'materialDataFields'
        },
        {
            name: 'Campaign Data',
            description: 'Add one or more campaign data fields to show in the package template. \n' +
                'Campaign data can’t be edited in the overall package.',
            formArrayName: 'campaignDataFields'
        },
    ];

    constructor(private formBuilder: UntypedFormBuilder, private titleCasePipe: TitleCasePipe) {
    }

    public ngOnInit(): void {
        this.initForm();
    }

    private initForm(): void {
        if (!this.form.contains(ETabFormGroup.PACKAGE_DATA)) {
            const materialDataFormArray = new UntypedFormArray([]);
            const campaignDataFormArray = new UntypedFormArray([]);

            for (const dataField of this.packageSetup.materialDataFields) {
                const selectedDateType = this.materialDataTypes.find((type) => type.getValue() === dataField.dataType.type);
                materialDataFormArray.push(this.formBuilder.group({
                    _id: dataField._id,
                    name: [dataField.name, RLValidatorConstants.VALIDATOR_SETS.DATA_FIELD_NAME],
                    type: [selectedDateType, Validators.required]
                }));
            }
            for (const dataField of this.packageSetup.campaignDataFields) {
                const {ruleProperty, variant} =
                    RulePropertyUtil.findRulePropertyAndVariantByPath(dataField.property, this.variants, this.properties);
                const config = {
                    _id: dataField._id,
                    name: [dataField.name, RLValidatorConstants.VALIDATOR_SETS.DATA_FIELD_NAME],
                    property: [ruleProperty, Validators.required]
                };

                if (ruleProperty.enableVariants) config['variant'] = [variant, Validators.required];

                campaignDataFormArray.push(this.formBuilder.group(config));
            }
            this.packageDataFormGroup = new UntypedFormGroup({
                materialDataFields: materialDataFormArray,
                campaignDataFields: campaignDataFormArray,
            }, this.noDuplicateValuesValidator);
            this.form.addControl(ETabFormGroup.PACKAGE_DATA, this.packageDataFormGroup);
        } else {
            this.packageDataFormGroup = this.form.get(ETabFormGroup.PACKAGE_DATA) as UntypedFormGroup;
        }
    }

    private noDuplicateValuesValidator: ValidatorFn = (formGroup: UntypedFormGroup): null => {
        const materialDataFieldsFormArray = formGroup.get('materialDataFields') as UntypedFormArray;
        const campaignDataFieldsFormArray = formGroup.get('campaignDataFields') as UntypedFormArray;
        const subFormGroups = materialDataFieldsFormArray.controls.concat(campaignDataFieldsFormArray.controls);

        const duplicateFieldNames = subFormGroups
            .map((item) => item.value.name)
            .filter((element, index, array) => {
                return !!element && array.indexOf(element) !== index;
            });

        subFormGroups.forEach((subFormGroup) => {
            const nameControl = subFormGroup.get('name');
            if (nameControl.errors && !nameControl.errors['duplicateValues']) {
                // return if another validator has already found an error on the nameControl
                return;
            }

            if (duplicateFieldNames.includes(nameControl.value)) {
                nameControl.markAsDirty();
                nameControl.setErrors({duplicateValues: true});
           } else {
               nameControl.setErrors(null);
           }
        });
        return;
    };

    public onDeleteFieldClicked(formArrayName: string, index: number): void {
        const formArray = this.getFormArrayByName(formArrayName);
        if (formArray.controls[index]) {
            formArray.removeAt(index);
        }
    }

    public onAddFieldClicked(formArrayName: string): void {
        const formArray = this.getFormArrayByName(formArrayName);
        const controlConfig = {name: ['', RLValidatorConstants.VALIDATOR_SETS.DATA_FIELD_NAME]};
        if (formArrayName === 'campaignDataFields') {
            controlConfig['property'] = [null, Validators.required];
        } else {
            controlConfig['type'] = [null, Validators.required];
        }
        formArray.push(this.formBuilder.group(controlConfig));
    }

    private getFormArrayByName(formArrayName: string): UntypedFormArray {
        return this.packageDataFormGroup.get(formArrayName) as UntypedFormArray;
    }
}
