import { __extends } from "tslib";
var RateLimitError = /** @class */function (_super) {
  __extends(RateLimitError, _super);
  function RateLimitError(message, retryTimeout) {
    var _this = _super.call(this, message) || this;
    _this.retryTimeout = retryTimeout;
    _this.name = 'RateLimitError';
    return _this;
  }
  return RateLimitError;
}(Error);
export { RateLimitError };
