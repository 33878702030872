import {Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormatOption} from '../../../modules/static-content-rulesets/static-content-ruleset.constants';
import {FormControl, FormGroup} from '@angular/forms';
import {DropdownItem} from '../../../models/ui/dropdown-item.model';
import {RulePropertyModel} from '../../../models/api/rule-property.model';
import {BUTTON_TYPE} from '@relayter/rubber-duck';
import {toWordsOrdinal} from 'number-to-words';
import {ETransformAction, StringTransformPipe} from '../../../modules/static-content-rulesets/pipes/string-transform.pipe';
import {format as dateFormatter} from 'date-fns-tz';
import {nl as DEFAULT_LOCALE} from 'date-fns/locale';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AppConstants} from '../../../app.constants';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import { LowerCasePipe } from '@angular/common';

import {PropertyValueModel} from '../../../models/ui/property-value.model';
import {PropertyControlOptions} from '../../../components/property-control/property-control.options';

class TitleElement {
    public text: string;
    public bold = false;

    constructor(text: string, bold = false) {
        this.text = text;
        this.bold = bold;
    }
}

export class ValueForm {
    tag: FormControl<IDropdownItem<string>>;
    property: FormControl<PropertyValueModel>;
    format?: FormControl<FormatOption>;
    formatString?: FormControl<string>;
}

@Component({
    selector: 'values-form',
    templateUrl: './values-form.component.html',
    styleUrls: ['./values-form.component.scss'],
    providers: [LowerCasePipe]
})
export class ValuesFormComponent implements OnInit, OnDestroy {
    private lowercasePipe = inject(LowerCasePipe);

    @Output() public deleteClicked = new EventEmitter<void>();
    @Input() public formGroup: FormGroup<ValueForm>;
    @Input() public valueIndex = 0;
    @Input() public tags: DropdownItem<string>[] = [];
    @Input() public ruleProperties: RulePropertyModel[] = [];
    @Input() public formatEnabled = true;
    @Input() public tagLabel = 'Tag';
    @Input() public tagPlaceholder = 'Select a tag';

    public readonly BUTTON_TYPE = BUTTON_TYPE;

    public formatOptions = FormatOption.OPTIONS;
    public FORMAT_OPTION = FormatOption;
    public onDestroySubject = new Subject<void>();

    // Property control settings
    public static readonly PropertyControlOptions = new PropertyControlOptions(false, false, false);
    protected readonly propertyControlOptions = ValuesFormComponent.PropertyControlOptions;

    get tagFormControl() {
        return this.formGroup.controls.tag;
    }

    get propertyFormControl() {
        return this.formGroup.controls.property;
    }

    get formatFormControl() {
        return this.formGroup.controls.format;
    }

    get formatStringFormControl() {
        return this.formGroup.controls.formatString;
    }

    public operators = ['Item at'];
    public valueDescription: TitleElement[];

    public ngOnInit(): void {
        this.formGroup.valueChanges
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(() => this.updateValueDescription());

        if (this.formatEnabled) {
            this.formatFormControl.valueChanges
                .pipe(takeUntil(this.onDestroySubject))
                .subscribe((value) => {
                    if (![FormatOption.DATE_FORMAT.getValue(), FormatOption.TO_STRING.getValue()].includes(value?.getValue())) {
                        this.formatStringFormControl.patchValue(null);
                    }
                });
        }

        this.updateValueDescription();
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    public updateValueDescription(): void {
        this.valueDescription = null;
        if (!this.formGroup.valid) {
            return;
        }

        const title: TitleElement[] = [];
        const properties = this.propertyFormControl.value?.properties || [];
        for (let index = properties.length; index > 0; index--) {
            const property = properties[index - 1];
            if (property instanceof RulePropertyModel) {
                title.push(new TitleElement('the'));
                title.push(new TitleElement(property.getTitle(), true));
                if (index > 1) {
                    title.push(new TitleElement('of'));
                }
            } else if (Number.isFinite(property)) {
                title.push(new TitleElement(`the ${toWordsOrdinal(property)} item from`));
            }
        }

        title.push(new TitleElement(`will be set in ${this.lowercasePipe.transform(this.tagLabel)}`));
        title.push(new TitleElement(this.tagFormControl.value.getTitle(), true));

        if (this.formatEnabled) {
            if (this.formatFormControl.value?.getTitle()) {
                title.push({text: 'and formatted with', bold: false});
                title.push({text: this.formatFormControl.value.getTitle(), bold: true});
            }

            if (this.formatStringFormControl.value) {
                let formattedString = null;
                switch (this.formatFormControl.value) {
                    case FormatOption.DATE_FORMAT:
                        title.push({text: this.formatStringFormControl.value, bold: true});
                        const options = {
                            timeZone: AppConstants.DEFAULT_TIMEZONE,
                            locale: DEFAULT_LOCALE,
                            useAdditionalWeekYearTokens: true,
                            useAdditionalDayOfYearTokens: true
                        };
                        formattedString = dateFormatter(new Date(), this.formatStringFormControl.value, options);
                        break;
                    case FormatOption.TO_STRING:
                        formattedString = `separator '${this.formatStringFormControl.value}'`;
                        break;
                }
                if (formattedString) title.push({text: `(${formattedString})`, bold: false});
            }
        }

        if (title.length) {
            title[0].text = StringTransformPipe.format(title[0].text, ETransformAction.FIRST_LETTER);
        }

        this.valueDescription = title;
    }

    public openDateFormatLink(): void {
        window.open('https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table', 'default');
    }

    public lastOperatorRemoved(): void {
        if (this.formatEnabled) {
            this.formGroup.patchValue({
                format: FormatOption.TO_STRING,
                formatString: this.formatStringFormControl.value || ', '
            });
        }
    }
}
