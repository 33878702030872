import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {
    BUTTON_TYPE,
    ButtonConfig,
    FullModalActionModel,
    FullModalService,
    NUC_FULL_MODAL_DATA
} from '@relayter/rubber-duck';
import {distinctUntilChanged, filter, map, takeUntil, withLatestFrom} from 'rxjs/operators';
import {forkJoin, Subject} from 'rxjs';
import {EDataFieldCollectionName, EDataFieldFormatter, EDataFieldTypes, EFormStatus} from '../../app.enums';
import {Toaster} from '../../classes/toaster.class';
import {Papa} from 'ngx-papaparse';
import {AmazonService} from '../../api/services/amazon.service';
import {EUploadStatus} from '../../components/upload-file-component/upload.model';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {DataFieldsApiService} from '../../api/services/data-fields.api.service';
import {XliffReader} from '../../classes/readers/xliff-reader';
import {FileTypeUtil} from '../../classes/file-type.util';
import {VariantService} from '../../api/services/variant.service';
import {VariantModel} from '../../models/api/variant.model';
import {IDropdownRequestDataEvent} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {StringUtil} from '../../classes/string-util';

const DELIMITERS = {
    COMMA: ',',
    SEMICOLON: ';',
    CARET: '^'
};

interface IDefaultFieldConfig {
    identifier?: boolean;
    context?: EDataFieldCollectionName;
    options?: DropdownItem<string>[];
    placeholder?: string;
}

export interface IImportDataFormComponentData {
    defaultFields: Record<string, IDefaultFieldConfig>;
    identifierContext: EDataFieldCollectionName;
    campaignId?: string;
}

@Component({
    selector: 'import-data-form-component',
    templateUrl: './import-data-form.component.html',
    styleUrls: ['./import-data-form.component.scss']
})
export class ImportDataFormComponent implements OnInit, OnDestroy {
    public defaultFields = {}; // from modal data
    private fieldsWithIdentifier: string[] = [];

    private onDestroySubject = new Subject<void>();
    private mappingsRemovedSubject = new Subject<void>();
    private uploadButton: ButtonConfig;
    public form: UntypedFormGroup;

    public variants: VariantModel[] = [];
    public variantEnabled: boolean = false;
    public selectedVariant: VariantModel;

    public fileTypes = [FileTypeUtil.CATEGORIES.CSV, FileTypeUtil.CATEGORIES.XLIFF];
    public file: File;
    public data: Record<string, any>[];
    public columns: DropdownItem<string>[] = [];
    private _columns: DropdownItem<string>[];// all the column headers from csv

    private _identifierFields: DropdownItem<string>[]; // only string data fields
    public identifierFields: DropdownItem<string>[] = [];
    public fields: DropdownItem<string>[] = []; // all the possible fields
    private _allowedFields: DropdownItem<string>[]; // all the possible fields after filtering out selected identifier field
    public allowedFields: DropdownItem<string>[] = [];

    constructor(private fullModalService: FullModalService,
                private papa: Papa,
                private amazonService: AmazonService,
                private dataFieldService: DataFieldsApiService,
                private variantService: VariantService,
                @Inject(NUC_FULL_MODAL_DATA) private modalData: IImportDataFormComponentData) {
    }

    public ngOnInit(): void {
        this.initModalButtons();
        this.initFields();
    }

    public ngOnDestroy(): void {
        [this.onDestroySubject, this.mappingsRemovedSubject].forEach((subject) => {
            subject.next();
            subject.complete();
        });
    }

    private initModalButtons(): void {
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        const cancel = new FullModalActionModel(cancelButton);
        cancel.observable.pipe(takeUntil(this.onDestroySubject)).subscribe(() => this.fullModalService.close(null, true));

        this.uploadButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Upload', null, null, true);
        const upload = new FullModalActionModel(this.uploadButton);
        upload.observable.pipe(takeUntil(this.onDestroySubject)).subscribe(() => {
            this.uploadButton.loading = true;
            this.createMappedCsvFile();
        });

        this.fullModalService.setModalActions([cancel, upload]);
    }

    private initFields(): void {
        this.defaultFields = this.modalData.defaultFields;
        const defaultFields = Object.keys(this.defaultFields);
        this.fieldsWithIdentifier = Object.keys(this.defaultFields).filter((fieldName) => this.defaultFields[fieldName].identifier);
        const identifierDataTypes = [EDataFieldTypes.STRING, EDataFieldTypes.NUMBER];

        forkJoin([
            this.variantService.getVariants(this.modalData.campaignId),
            this.dataFieldService.getAllDataFields(this.modalData.identifierContext)
        ])
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: ([variants, dataFields]) => {
                    this._identifierFields = dataFields.filter((dataField) => {
                        return dataField.dataType.formatter === EDataFieldFormatter.NONE
                            && identifierDataTypes.includes(dataField.dataType.type)
                            && !dataField.enableVariants
                    }).map((field) => new DropdownItem(field.name, field.name));
                    this.identifierFields = this._identifierFields;
                    this.fields = this.fields.concat(
                        defaultFields.map((fieldName) => new DropdownItem(fieldName, fieldName)),
                        dataFields.map((field) => new DropdownItem(field.name, field.name))
                    );

                    this.variants = variants.items;
                    this.variantEnabled = dataFields.some(field => field.enableVariants);
                    if (this.variantEnabled && this.variants.length > 0) this.selectedVariant = this.variants[0];
                },
                error: Toaster.handleApiError
            });

        defaultFields.forEach((fieldName) => {
            const fieldConfig = this.defaultFields[fieldName];
            const context = fieldConfig.context;

            if (context) {
                this.dataFieldService.getAllDataFields(context)
                    .pipe(takeUntil(this.onDestroySubject))
                    .subscribe({
                        next: (dataFields) => {
                            const dataFieldDropdownItems = dataFields.map((field) => new DropdownItem(field.name, field.name));
                            fieldConfig.options = fieldConfig.options.concat(dataFieldDropdownItems);
                        },
                        error: Toaster.handleApiError
                    });
            }
        });

    }

    public onFileChanged(file: File): void {
        this.file = file;

        const extension = '.' + this.file.name.split('.').pop();

        if (extension === FileTypeUtil.EXTENSIONS.CSV) {
            this.getHeadersFromCsv(this.file);
        } else if (extension === FileTypeUtil.EXTENSIONS.XLIFF) {
            const reader = new FileReader();

            reader.onloadend = (event) => {
                const text = event.target['result'];
                if (typeof text === 'string') {
                    const xliffReader = new XliffReader(text);
                    try {
                        xliffReader.validate();
                        const data = xliffReader.parse();
                        const csv = this.papa.unparse(data);
                        this.getHeadersFromCsv(csv);
                    } catch (error) {
                        Toaster.error(error.message);
                    }
                }
            };

            reader.readAsText(this.file);
        }
    }

    private getHeadersFromCsv(data): void {
        const config = {
            header: true,
            delimitersToGuess: Object.values(DELIMITERS),
            skipEmptyLines: true,
            error: (error) => Toaster.error(error.message),
            complete: (results) => {
                const columnNames = results.meta.fields.filter((field) => field !== '');
                this._columns = columnNames.map((v) => new DropdownItem<string>(v, v));
                this.columns = this._columns;
                this.data = results.data;
                this.initForm();
            }
        };

        this.papa.parse(data, config);
    }

    public noDuplicateValuesValidator: ValidatorFn = (formArray: UntypedFormArray): null => {
        const subFormGroups = formArray.controls as UntypedFormGroup[];

        const duplicateFieldNames = subFormGroups
            .map((form) => form.value.field)
            .filter((element, index, array) => {
                return !!element && array.indexOf(element) !== index;
            });

        subFormGroups.forEach((subFormGroup) => {
            const fieldControl = subFormGroup.get('field');
            if (fieldControl.errors && !fieldControl.errors['duplicateValues']) return;

            if (duplicateFieldNames.includes(fieldControl.value)) {
                fieldControl.markAsDirty();
                fieldControl.setErrors({duplicateValues: true});
            } else {
                fieldControl.setErrors(null);
            }
        });
        return;
    };

    public initForm(): void {
        const identifierForm = new UntypedFormGroup({
            column: new UntypedFormControl(null, Validators.required),
            field: new UntypedFormControl(null, Validators.required)
        });
        identifierForm.statusChanges
            .pipe(map((status) => status === EFormStatus.VALID), takeUntil(this.onDestroySubject))
            .subscribe((valid) => valid ? this.addMappingsToForm() : this.removeMappingForm());

        this.form = new UntypedFormGroup({});
        if (this.variantEnabled && this.variants.length) {
            const variantControl = new UntypedFormControl(null, Validators.required);
            this.form.addControl('variant', variantControl);
            setTimeout(() => {
                variantControl.setValue(this.selectedVariant);
            });
        }
        this.form.addControl('identifier', identifierForm);

        this.form.statusChanges
            .pipe(map((status) => status === EFormStatus.VALID), takeUntil(this.onDestroySubject))
            .subscribe((valid) => this.uploadButton.disabled = !valid);
    }

    private createMappedCsvFile(): void {
        const mappedData = this.mapData();

        const config = {
            headers: true,
            delimiter: DELIMITERS.COMMA
        };
        const csv = this.papa.unparse(mappedData, config);
        const blob = new Blob([csv], {type: 'text/csv'});
        const mappedCsvFile = new File([blob], 'import-data.csv', {type: this.file.type, lastModified: Date.now()});
        this.uploadFile(mappedCsvFile);
    }

    private mapData(): Record<string, any>[] {
        return this.data.map((row) => {
            const newItem = this.form.value.mappings
                .reduce((acc, mapping) => {
                    const column = mapping.column;
                    const field = mapping.field?.getValue();
                    if (field) acc[field] = row[column];
                    return acc;
                }, {});

            const {column: identifierColumn, field: identifierField} = this.getValueFromIdentifierForm();
            newItem[identifierField] = row[identifierColumn];
            return newItem;
        });
    }

    private uploadFile(file: File): void {
        const upload = this.amazonService.createUpload(file);
        upload.progress$.pipe(
            filter((progress) => progress === EUploadStatus.Done || progress === EUploadStatus.Failed),
            withLatestFrom(upload.s3Key$)
        ).subscribe(([status, s3Key]) => {
            if (status === EUploadStatus.Failed) {
                this.uploadButton.loading = false;
                return Toaster.error('Failed to upload asset to Amazon');
            }
            this.fullModalService.close({s3Key, formValue: this.form.value});
        });
    }

    private getValueFromIdentifierForm(): Record<string, string> {
        const identifierForm = this.form.get('identifier') as UntypedFormGroup;
        return {
            column: identifierForm.get('column').value?.getValue(),
            field: identifierForm.get('field').value?.getValue()
        };
    }

    private addMappingsToForm(): void {
        this.removeMappingForm();

        const {column: identifierColumn, field: identifierField} = this.getValueFromIdentifierForm();

        // filter out selected field and column
        const allowedColumns = this.columns.map((col) => col.getValue()).filter((v) => identifierColumn !== v);
        this._allowedFields = this.fields.filter((field) => identifierField !== field.getValue());
        this.allowedFields = this._allowedFields;

        const controls = [];
        for (const column of allowedColumns) {
            const matchingField = this._allowedFields.find((field) => field.getValue().toLowerCase() === column.toLowerCase());
            const formGroup = new UntypedFormGroup({
                column: new UntypedFormControl(column, Validators.required),
                field: new UntypedFormControl(matchingField)
            });
            const shouldHaveIdentifier = this.fieldsWithIdentifier.includes(matchingField?.getValue());
            if (shouldHaveIdentifier) formGroup.addControl('identifier', new UntypedFormControl(null, Validators.required));
            controls.push(formGroup);
        }
        this.form.addControl('mappings', new UntypedFormArray(controls, [this.noDuplicateValuesValidator]));
        this.listenToMappingsFormArray();
    }

    private listenToMappingsFormArray(): void {
        const mappingsFormArray = this.form.get('mappings') as UntypedFormArray;

        mappingsFormArray.valueChanges
            .pipe(
                distinctUntilChanged(),
                takeUntil(this.mappingsRemovedSubject),
                takeUntil(this.onDestroySubject))
            .subscribe((mappings) => {
                mappings.forEach((mapping, index) => {
                    const selectedField = mapping.field?.getValue();
                    const shouldHaveIdentifier = this.fieldsWithIdentifier.includes(selectedField);
                    const formGroup = mappingsFormArray.controls[index] as UntypedFormGroup;
                    const identifierControl = formGroup.get('identifier');

                    // identifier exists, but it doesn't match the dropdown options
                    // example: identifier selected for Products, but now for Assets, and the field doesn't exist for Assets
                    if (identifierControl?.value?.getValue() && shouldHaveIdentifier) {
                        const foundIdentifier = this.defaultFields[selectedField].options
                            .find((item) => item.getValue() === identifierControl.value.getValue());
                        if (!foundIdentifier) formGroup.patchValue({identifier: null});
                    }

                    // add identifier control if it doesn't exist
                    if (!identifierControl && shouldHaveIdentifier) {
                        formGroup.addControl('identifier', new UntypedFormControl(null, Validators.required));
                    }

                    // remove identifier control if it's not needed any more
                    if (identifierControl && !shouldHaveIdentifier) {
                        formGroup.removeControl('identifier');
                    }
                });
            });
    }

    public searchIdentifierFields(event: IDropdownRequestDataEvent): void {
        this.identifierFields = this.filterDropdownItems(this._identifierFields, event.search);
    }

    private removeMappingForm(): void {
        this.mappingsRemovedSubject.next();
        if (this.form.get('mappings')) this.form.removeControl('mappings');
    }

    public searchAllowedFields(event: IDropdownRequestDataEvent): void {
        this.allowedFields = this.filterDropdownItems(this._allowedFields, event.search);
    }

    public searchColumnFields(event: IDropdownRequestDataEvent): void {
        this.columns = this.filterDropdownItems(this._columns, event.search);
    }

    private filterDropdownItems(sourceData: DropdownItem<string>[], search: string): DropdownItem<string>[] {

        if (search) {
            const regex = new RegExp(StringUtil.escapeRegExp(search), 'i');
            return sourceData.filter((item) => item.getTitle().match(regex)?.length > 0);
        } else {
            return sourceData;
        }
    }
}
