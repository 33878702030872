import {autoserialize, autoserializeAs} from 'cerialize';
import {AfterEffectsProjectFileModel} from './after-effects-project-file.model';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {ConditionGroupsModel} from './condition-groups.model';
import {ValueModel} from '../../modules/static-content-rulesets/models/api/ruleset-value.model';

export class AnimatedContentRulesetAssetModel {
    @autoserialize tag: string;
    @autoserialize property: string;
}

export class AnimatedContentRulesetRulePostModel {
    @autoserialize public name: string;
    @autoserialize public composition: string;
    @autoserializeAs(ConditionGroupsModel) public conditions: ConditionGroupsModel;
    @autoserializeAs(ValueModel) public values: ValueModel[];
    @autoserializeAs(AnimatedContentRulesetAssetModel) public assets: AnimatedContentRulesetAssetModel[];
}

export class AnimatedContentRulesetRuleModel {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserialize public composition: string;
    @autoserializeAs(ConditionGroupsModel) public conditions: ConditionGroupsModel;
    @autoserializeAs(ValueModel) public values: ValueModel[];
    @autoserializeAs(AnimatedContentRulesetAssetModel) public assets: AnimatedContentRulesetAssetModel[];
    @autoserialize public createdAt: string;
    @autoserialize public updatedAt: string;
}

export class AnimatedContentRulesetModel implements IDropdownItem  {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserialize public engineType: string;
    @autoserializeAs(AfterEffectsProjectFileModel) afterEffectsProjectFiles: AfterEffectsProjectFileModel[];
    @autoserializeAs(AnimatedContentRulesetRuleModel) rules: AnimatedContentRulesetRuleModel[];
    @autoserialize public createdAt: string;
    @autoserialize public updatedAt: string;

    public getTitle(): string {
        return this.name;
    }

    public getValue(): string {
        return this._id;
    }
}
