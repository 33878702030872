import {FormControl, ValidationErrors, ValidatorFn} from '@angular/forms';

/**
 * Checks if the form control array value has a minimal length
 * @param {number} minLength
 * @returns {object | null}
 * @constructor
 */
export function FormControlArrayMinLengthValidator(minLength: number = 1): ValidatorFn {
    return (formControl: FormControl): ValidationErrors => {

        if (!formControl.value || formControl.value?.length < minLength) {
            return {minLength: true};
        }
        return null;
    };
}
