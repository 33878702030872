import { fetch } from '../../lib/fetch';
import { RateLimitError } from './ratelimit-error';
export default function (config) {
  function dispatch(url, body) {
    return fetch(url, {
      keepalive: config === null || config === void 0 ? void 0 : config.keepalive,
      headers: {
        'Content-Type': 'text/plain'
      },
      method: 'post',
      body: JSON.stringify(body)
    }).then(function (res) {
      var _a;
      if (res.status >= 500) {
        throw new Error("Bad response from server: ".concat(res.status));
      }
      if (res.status === 429) {
        var retryTimeoutStringSecs = (_a = res.headers) === null || _a === void 0 ? void 0 : _a.get('x-ratelimit-reset');
        var retryTimeoutMS = retryTimeoutStringSecs ? parseInt(retryTimeoutStringSecs) * 1000 : 5000;
        throw new RateLimitError("Rate limit exceeded: ".concat(res.status), retryTimeoutMS);
      }
    });
  }
  return {
    dispatch: dispatch
  };
}
