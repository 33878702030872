<ng-container *ngIf="(permissions.GET_PACKAGES | userIsAllowedTo) else noPermissionState">
    <ng-container *ngIf="!noPackagesAvailable else otherState">
        <nuc-table [items]="items"
                   [columns]="columns"
                   [loading]="!subscription?.closed"
                   [actions]="tableActions"
                   [editMode]="!tableLocked"
                   [tableId]="tableId"
                   [prefixStorageKey]="storageKey"
                   [tableSortOptions]="tableSortOptions"
                   (tableSortOptionsChange)="resetPageIndex()"
                   (itemEdited)="onItemEdited($event)"
                   (actionClicked)="handleTableRowAction($event)">
            <ng-container button-bar-left>
                <div class="dropdown">
                    <nuc-label>Campaign package</nuc-label>
                    <nuc-dropdown [disabled]="!selectedPackage || !subscription?.closed"
                                  [total]="totalPackages"
                                  [data]="selectedPackage"
                                  (dataChange)="campaignPackageChanged($event)"
                                  [nullOption]="false"
                                  (requestData)="getPackages($event)"
                                  [items]="packages"
                                  [searchable]="true">
                    </nuc-dropdown>
                </div>
                <nuc-button-secondary *ngIf="addOrEditPackage && items?.length && selectedPackage?.packageSetup.materialDataFields.length > 0"
                                      [icon]="tableLocked ? 'nucicon_lock_fill': 'nucicon_unlock'"
                                      (click)="tableLocked = !tableLocked"
                                      [nucTooltip]="tableLocked ? 'Unlock table' : 'Lock table'">
                </nuc-button-secondary>
            </ng-container>
            <ng-container button-bar-right>
                <paginator [viewId]="viewId"
                           [disableNextPage]="disableNextPage"
                           [loading]="!subscription?.closed">
                </paginator>
            </ng-container>
            <ng-container button-bar-footer>
                <!-- indicate the table is unlocked -->
                <nuc-badge-warning *ngIf="!tableLocked"
                                   class="table-unlocked-label"
                                   @unlockedBadgeAnimation >Table data is unlocked
                </nuc-badge-warning>
            </ng-container>
        </nuc-table>
    </ng-container>
</ng-container>

<ng-template #otherState>
    <ng-container *ngIf="!subscription?.closed; else noPackageState">
        <rl-loading-indicator></rl-loading-indicator>
    </ng-container>
    <ng-template #noPackageState>
        <nuc-empty-state-component imageUrl="assets/images/empty_states/packages_small.svg"
                                   [small]="true"
                                   title="There are no packages for this campaign. Add the first package in the campaign detail view.">
        </nuc-empty-state-component>
    </ng-template>
</ng-template>

<ng-template #noPermissionState>
    <no-permission-state-component></no-permission-state-component>
</ng-template>
