import {Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {Observable} from 'rxjs';
import {ApiPagedRequest} from '../requests/api-paged.request';
import {ApiService} from './api.service';
import {RoleModel} from '../../models/api/role.model';

@Injectable({providedIn: 'root'})
export class RolesApiService extends ApiService<RoleModel> {
    constructor() {
        super([ApiConstants.API_GROUP_ROLES], RoleModel);
    }

    public getAllRoles(): Observable<RoleModel[]> {
        const request = new ApiPagedRequest(this.groups, this.model);
        return this.apiRequestService.findAll(request);
    }
}
