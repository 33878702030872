<div class="sticky-notes-container" [class.highlight]="selectedStickyNote" (click)="createSticky($event)" #stickyNotesContainer
     [class.hidden]="!overlay().optionsSelected">
    @if (overlay().highLight) {
        <div class="standout_layer" ></div>
    }
    @if (overlay().showNotes) {
        @for (stickyNote of notes(); track $index){
            @if (stickyNote.publicationItem._id === item()._id){
                <div [class.selected]="selectedStickyNote?._id === stickyNote._id"
                     class="sticky" (click)="onStickyNoteClicked($event, stickyNote)"
                     [ngStyle]="{'left.%': stickyNote.position.x, 'top.%': stickyNote.position.y}">
                        <img [src]="stickyNote.status | getNotePinImagePipe:'':selectedStickyNote?._id === stickyNote._id"
                            class="pin"/>
                    @if (stickyNote.totalComments > 0) {
                        <div class="with-comments">
                            <span>{{stickyNote.totalComments}}</span>
                        </div>
                    }
                </div>
            }
        }
    }
    @if (overlay().showBriefingChanges) {
        @for (campaignItem of item().changedCampaignItems; track $index) {
            <div [class.selected]="selectedBriefingChange?.campaignItemId === campaignItem._id
                 && selectedBriefingChange?.publicationItemId === item()._id"
                 class="change" (click)="onBriefingChangeClicked(item()._id, campaignItem._id)"
                 [ngStyle]="{'left.%': campaignItem.position.x, 'top.%': campaignItem.position.y}">
                <img [src]="selectedBriefingChange?.campaignItemId === campaignItem._id
                            && selectedBriefingChange?.publicationItemId === item()._id
                            ? 'assets/images/icon_pin_briefing_changes_selected.svg'
                            : 'assets/images/icon_pin_briefing_changes.svg'"
                     class="pin"/>
            </div>
        }
    }
    @if (newStickyNote && newStickyNote.publicationItem._id === item()._id) {
        <div class="sticky new" [ngStyle]="{'left.%': newStickyNote.position.x, 'top.%': newStickyNote.position.y}"
             (click)="onNewStickyNoteClicked($event)">
            <img [src]="newStickyNote.status | getNotePinImagePipe:'':true"
                 class="pin selected" @newStickyNote/>
        </div>
    }
</div>
