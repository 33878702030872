import {ApiConstants} from '../api.constant';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ARApiUrlBuilderService, ARPagedResponseDataModel, ARRequestOptions} from '@relayter/core';
import {environment} from '../../../environments/environment';
import {BaseApiRequestService} from './base-api-request.service';
import {RoleModel} from '../../models/api/role.model';
import {QueryParams} from '../../classes/query-params';
import {SortDirection} from '@angular/material/sort';
import {UserModel} from '../../models/api/user.model';

@Injectable({
    providedIn: 'root'
})
export class RolesService extends BaseApiRequestService {
    /**
     * Get a paged list of roles
     * @param {number} limit
     * @param {string} offset
     * @param {string} sortProperty
     * @param {SortDirection} sortOrder
     * @param {string} search
     * @returns Observable<ARPagedResponseDataModel<RLRole>>
     */
    public getRoles(limit?: number,
                    offset?: number,
                    sortProperty?: string,
                    sortOrder?: SortDirection,
                    search?: string): Observable<ARPagedResponseDataModel<RoleModel>> {

        const queryParams = new QueryParams()
            .setLimitAndOffsetParams(limit, offset)
            .setSortAndSortDirectionParams(sortProperty, sortOrder);

        if (search) queryParams.addParam('search', search);

        const url = ARApiUrlBuilderService
            .urlFromComponents([environment.API_SERVER, ApiConstants.API_BASE_PATH,
                ApiConstants.API_GROUP_ROLES], queryParams.getParams());
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handlePagedResponse(options, obs, RoleModel);
        });
    }

    /**
     * Delete a role
     * @returns Observable<ARPagedResponseDataModel<RLRole>>
     */
    public deleteRole(id: string): Observable<boolean> {
        const url = ARApiUrlBuilderService
            .urlFromComponents([environment.API_SERVER, ApiConstants.API_BASE_PATH,
                ApiConstants.API_GROUP_ROLES, id]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.DELETE;
        options.url = url;
        return new Observable((obs) => {
            this.handleNoErrorResponse(options, obs);
        });
    }

    /**
     * Get a paged list of users for a role
     * @param {string} id
     * @param {number} limit
     * @param {number} offset
     * @param {string} sortProperty
     * @param {SortDirection} sortOrder
     * @param {string} search
     * @returns Observable<ARPagedResponseDataModel<UserModel>>
     */
    public getUsersForRole(id: string,
                           limit?: number,
                           offset?: number,
                           sortProperty?: string,
                           sortOrder?: SortDirection,
                           search?: string): Observable<ARPagedResponseDataModel<UserModel>> {

        const queryParams = new QueryParams()
            .setLimitAndOffsetParams(limit, offset)
            .setSortAndSortDirectionParams(sortProperty, sortOrder)
            .setSearchParams(search);

        const url = ARApiUrlBuilderService
            .urlFromComponents([environment.API_SERVER, ApiConstants.API_BASE_PATH,
                    ApiConstants.API_GROUP_ROLES, id, ApiConstants.API_METHOD_USERS], queryParams.getParams());
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handlePagedResponse(options, obs, UserModel);
        });
    }

    /**
     * Get a role with a role id
     * @param id
     * @returns {Observable<RoleModel>}
     */
    public getRoleWithId(id?: string): Observable<RoleModel> {
        const url = ARApiUrlBuilderService
            .urlFromComponents([environment.API_SERVER, ApiConstants.API_BASE_PATH,
                ApiConstants.API_GROUP_ROLES, id]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, RoleModel);
        });
    }

    /**
     * Post a new role
     * @param role
     * @returns {Observable<RoleModel>}
     */
    public postNewRole(role: RoleModel): Observable<RoleModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_ROLES]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = {name: role.name};
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, RoleModel);
        });
    }

    /**
     * Patch the details of a role
     * @param {string} roleId
     * @param {RoleModel} role
     * @returns {Observable<RoleModel>}
     */
    public patchRole(roleId, role: RoleModel): Observable<RoleModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_ROLES, roleId]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.PATCH;
        options.url = url;
        options.body = {name: role.name, permissions: role.permissions};
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, RoleModel);
        });
    }
}
