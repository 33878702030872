import {Component, OnInit, ViewChild} from '@angular/core';
import {PublicationsService} from '../../../../../../api/services/publications.service';
import {UserIsAllowedToPipe} from '../../../../../../pipes/user-is-allowed-to.pipe';
import {
    EColumnType,
    FullModalService,
    IActionClickEvent,
    ITableColumn,
    NucDialogService,
    NucPopOutContentService
} from '@relayter/rubber-duck';
import {CustomWorkflowService} from '../custom-workflow.service';
import {UserSettingsStorageService} from '../../../../../../api/services/user-settings-storage.service';
import {CampaignItemModel} from '../../../../../../models/api/campaign-item.model';
import {PrintPublicationItemModel} from '../../../../../../models/api/custom-workflow/print-publication-item.model';
import {
    BriefingTableComponent,
    IBriefingTableOptions
} from '../../../../../../components/briefing-table/briefing-table.component';
import {CustomWorkflowBaseComponent} from '../custom-workflow-base.component';
import {BriefingActionsService} from '../../../../../../api/services/briefing-actions.service';
import {takeUntil} from 'rxjs/operators';
import {AppConstants} from '../../../../../../app.constants';
import {Toaster} from '../../../../../../classes/toaster.class';
import {CampaignItemResultModel} from '../../../../../../models/ui/campaign-item-result.model';
import {MonitoredTransitionsService} from '../../../../../../api/services/monitored-transitions.service';
import {NewCursorArray} from '../../../../../../api/new-api-cursor';
import {CampaignItemsApiService} from '../../../../../../api/services/campaign-items.api.service';

export enum EBriefingComponentOptions {
    INLINE_EDITING = 'INLINE_EDITING'
}

@Component({
    selector: 'rl-custom-workflow-briefing-component',
    templateUrl: './custom-workflow-briefing.component.html',
    styleUrls: ['./custom-workflow-briefing.component.scss']
})
export class CustomWorkflowBriefingComponent extends CustomWorkflowBaseComponent implements OnInit {
    public tableId: string;
    public readonly storageKey: string;
    private cursorIndex: number;
    private cursorArray: NewCursorArray;
    public assignableItems: boolean;

    @ViewChild(BriefingTableComponent) private briefingTable: BriefingTableComponent;

    public additionalColumns: ITableColumn[] = [];

    public briefingTableOptions: IBriefingTableOptions = {};

    constructor(protected userIsAllowedToPipe: UserIsAllowedToPipe,
                protected customWorkflowService: CustomWorkflowService,
                protected publicationService: PublicationsService,
                protected dialogService: NucDialogService,
                protected fullModalService: FullModalService,
                protected userSettingsStorageService: UserSettingsStorageService,
                protected nucPopOutContentService: NucPopOutContentService,
                protected monitoredTransitionsService: MonitoredTransitionsService,
                private briefingActionsService: BriefingActionsService,
                private campaignItemApiService: CampaignItemsApiService) {
        super(userIsAllowedToPipe, customWorkflowService, publicationService, dialogService, fullModalService,
            monitoredTransitionsService, nucPopOutContentService);
        this.storageKey = userSettingsStorageService.getPrefixStorageKey();
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.briefingTableOptions.inlineEditing = !!this.allowedActions.find((action) => action.name === EBriefingComponentOptions.INLINE_EDITING);
        this.assignableItems = this.publication.channelIsPrintPublication();

        this.briefingActionsService.requestCampaignItem$
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(() => {
                this.getNextItem();
            });

        this.briefingActionsService.transitionPosted$
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((transitionItemId) => {
                this.monitorTransition(transitionItemId);
            });
    }

    public handleTableRowAction(event: IActionClickEvent): void {
        this.resetCursor(event.item as CampaignItemModel);

        const action = this.allowedActions.find((allowedAction) => allowedAction.title === event.action.title);
        const data = {hasNext: this.briefingTable.hasNextItemForCampaignItem(event.item._id)};
        const campaignItem = event.item as CampaignItemModel;
        this.handleAction(action, event.item as CampaignItemModel, event.origin, data);

        this.getNextItem(campaignItem);
    }

    protected refreshData(): void {
        this.briefingTable.getCampaignItems();
    }

    protected setupData(): void {
        // Ensure each use of this generic briefing table has its own config for properties
        this.tableId = `custom-workflow-briefing-${this.component._id}`;
        if (this.publication.channelIsPrintPublication()) {
            this.additionalColumns.push({
                title: 'Assigned to',
                key: 'assignedPublicationItems',
                format: (value) => {
                    const assignedPages: string[] = value.map((item) => {
                        item = Object.assign(new PrintPublicationItemModel(), item);
                        return item.formattedPageNumbers;
                    });
                    return assignedPages.length === 0 ? 'Not assigned' : assignedPages.join(', ');
                }
            });
        } else {
            this.additionalColumns.push({
                title: 'Generated',
                key: 'itemsGenerated',
                type: EColumnType.ICON,
                sortDuplicates: true,
                iconClass: (value) => value > 0 ? 'nucicon_check_round_fill' : '',
                format: (value) => value > 0 ? value : 'No items',
                color: AppConstants.FIRST_BRAND_COLOR,
                sortProperty: 'itemsGenerated'
            });
        }
    }

    private resetCursor(campaignItem: CampaignItemModel): void {
        this.cursorIndex = AppConstants.PAGE_INDEX_DEFAULT;

        this.cursorArray = new NewCursorArray(AppConstants.PAGE_INDEX_DEFAULT, this.briefingTable.tableSortOptions);

        this.cursorArray.setCursor(AppConstants.PAGE_INDEX_DEFAULT, campaignItem);

        this.cursorIndex++;
    }

    public getNextItem(campaignItem?: CampaignItemModel): void {
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_CAMPAIGN_ITEMS)) {
            const cursor = this.cursorArray.getCursor(this.cursorIndex);
            // Limit set on 2 to get also the next item
            this.campaignItemApiService.getCampaignItems(this.campaign._id, this.publication._id,
                2, 0, this.briefingTable.briefingDataProvider.phraseValue, this.briefingTable.briefingDataProvider.sortOptions,
                this.briefingTable.briefingDataProvider.filterValues, cursor)
                .pipe(takeUntil(this.onDestroySubject))
                .subscribe({
                    next: (result) => {
                        const itemsLength = campaignItem ? 1 : 0;

                        if (campaignItem) {
                            result.items.unshift(campaignItem);
                        }

                        if (result.items.length > itemsLength) {
                            // Item at 0, for the cursor to get the correct next items
                            this.cursorArray.setCursor(this.cursorIndex, result.items[0]);
                            this.cursorIndex++;
                        }

                        this.briefingActionsService.setCampaignItem(new CampaignItemResultModel(result.items,
                            result.items.length > 1)); // Has next only when we have at least two items, because the cursor is looking ahead
                    },
                    error: (error) => {
                        Toaster.handleApiError(error);
                        this.briefingActionsService.setCampaignItem(new CampaignItemResultModel([], false));
                    }
                });
        }
    }
}
