<ng-container *ngIf="permissions.GET_WORKFLOW_AUTOMATIONS | userIsAllowedTo; else noPermissionState">
    <nuc-table [loading]="!subscription?.closed"
               [items]="automations"
               [columns]="columns"
               emptyStateTitle="No workflow automations"
               [selectionMode]="selectionMode"
               [selection]="selection"
               [tableId]="tableId"
               [prefixStorageKey]="storageKey"
               (sortOptionChange)="onSortOptionChanged($event)">
        <ng-container button-bar-right>
            <ng-container *ngIf="selection.hasValue()">
                <div class="popout-button">
                    <nuc-button-secondary
                        text="{{selection.selected.length}} selected {{selection.selected.length === 1 ? 'item' : 'items'}}"
                        icon="nucicon_dots_vertical"
                        iconPosition="end"
                        [matMenuTriggerFor]="menu">
                    </nuc-button-secondary>
                    <mat-menu #menu="matMenu" class="menu-items-container">
                        <ng-container *ngIf="automations && transitionAction; else noActionsAvailable">
                            <button mat-menu-item
                                    (click)="openDialog()"
                                    [disableRipple]="true">
                                <i class="nucicon_gear"></i>
                                {{transitionAction.title}}
                            </button>
                        </ng-container>
                        <ng-template #noActionsAvailable>
                            <button mat-menu-item [disabled]="true" [disableRipple]="true">No actions available</button>
                        </ng-template>
                    </mat-menu>
                </div>

                <div class="vertical-line"></div>
            </ng-container>

            <paginator [viewId]="tableId"
                       [disableNextPage]="disableNextPage"
                       [loading]="!subscription?.closed">
            </paginator>
        </ng-container>
    </nuc-table>
</ng-container>

<ng-template #noPermissionState>
    <no-permission-state-component></no-permission-state-component>
</ng-template>
