<div class="container">
    <div class="canvas-container" #container>
        @if(publicationItemSignal(); as items) {
            @if(items.length === 1 && items[0].previewType === 'Video') {
                <video-sticky-notes-view [src]="items[0].previewImage" [item]="items[0]"
                                         [overlay]="notesOverlayDisplayOptions"/>
            } @else {
                <image-sticky-notes-view [items]="items" (minZoomLevel)="setMinZoomLevel($event)"
                                         [horizontal]="horizontal" [(zoomLevel)]="zoomLevel"
                                         [overlay]="notesOverlayDisplayOptions" [placeHolderImage]="placeHolderImage"/>
            }
        }
    </div>

    @if (publicationItemSignal(); as items) {
        @if (items.length === 1) {
            <div class="controls">
                @if (items[0].previewType !== 'Video') {
                    <nuc-button-secondary (click)="zoomIn()" icon="nucicon_add"
                                          [nucTooltip]="'Zoom in'" [nucTooltipPosition]="'right'"></nuc-button-secondary>
                    <nuc-slider step="1" [min]="(minZoomLevel()  * 100)" max="300" vertical="true" [value]="(zoomLevel * 100)"
                                [nucTooltip]="getZoomValue()" [nucTooltipPosition]="'right'" (valueChange)="valueChanged($event)">
                    </nuc-slider>
                    <nuc-button-secondary (click)="zoomOut()" icon="nucicon_remove"
                                          [nucTooltip]="'Zoom out'" [nucTooltipPosition]="'right'"></nuc-button-secondary>
                }
                <nuc-button-secondary (click)="fitToContainer()" icon="nucicon_images_line"
                                      [nucTooltip]="'Fit to canvas'" [nucTooltipPosition]="'right'"></nuc-button-secondary>
                <overlay-button [icon]="notesOverlayDisplayOptions.optionsSelected ? 'nucicon_comments_show' : 'nucicon_comments_hide'"
                                nucTooltip="Notes display options"
                                [content]="displayOptions">
                </overlay-button>
                <ng-template #displayOptions>
                    <div class="display-options">
                        <span class="body-strong">Notes view</span>
                        <nuc-switch text="Notes" [(data)]="notesOverlayDisplayOptions.showNotes"/>
                        <nuc-switch text="Briefing changes" [(data)]="notesOverlayDisplayOptions.showBriefingChanges"/>
                        <nuc-switch text="Highlight" [(data)]="notesOverlayDisplayOptions.highLight"/>
                    </div>
                </ng-template>
            </div>
        }
    }

    @if (filesVersions) {
        <form class="file-versions" [formGroup]="formGroup">
            <nuc-dropdown formControlName="filesVersion"
                          [nullOption]="false"
                          [total]="filesVersions.length"
                          [items]="filesVersions">
            </nuc-dropdown>
        </form>
    }
</div>
