import {Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {Observable} from 'rxjs';
import {ApiPagedRequest} from '../requests/api-paged.request';
import {ApiService} from './api.service';
import {UserModel} from '../../models/api/user.model';
import {ARPagedResponseDataModel} from '@relayter/core';
import {TableSortOptions} from '../table-sort-options';
import {NewCursor} from '../new-api-cursor';

@Injectable({
    providedIn: 'root'
})
export class UsersApiService extends ApiService<UserModel> {

    constructor() {
        super([ApiConstants.API_GROUP_USERS], UserModel);
    }

    public getUsers(tableSortOptions?: TableSortOptions, cursor?: NewCursor, limit?: number, offset?: number, groupId?: string,
                    search?: string, excludeIds?: string[]): Observable<ARPagedResponseDataModel<UserModel>> {
        const request = new ApiPagedRequest(this.groups, this.model, limit, offset, tableSortOptions, cursor, search);
        request.additionalQueryParams.addParam('groupId', groupId);
        request.additionalQueryParams.addParam('excludeIds', excludeIds);

        return this.apiRequestService.find(request);
    }

    public getAllUsers(): Observable<UserModel[]> {
        const request = new ApiPagedRequest(this.groups, this.model);
        return this.apiRequestService.findAll(request);
    }
}
