<nuc-table [items]="campaignItems"
           [columns]="columns"
           [selectionMode]="ESelectionMode.SINGLE"
           [selection]="selection"
           [tableId]="tableId"
           [loading]="!campaignItemsSubscription?.closed"
           [prefixStorageKey]="storageKey"
           (sortOptionChange)="onSortOptionChanged($event)"
           emptyStateTitle="You have to create the first briefing item and assign it to this page.">

    <paginator button-bar-right
               [viewId]="tableId"
               [disableNextPage]="totalItemCount >= campaignItems?.length"
               [loading]="!campaignItemsSubscription?.closed">
    </paginator>
</nuc-table>
