<form *ngIf="packageExportSettingsGroup" [formGroup]="packageExportSettingsGroup">
    <nuc-form-field label="Layout">
        <nuc-dropdown formControlName="layout"
                      [total]="layouts.length"
                      [items]="layouts"
                      placeholder="Select a layout">
        </nuc-dropdown>
        <nuc-error *ngIf="packageExportSettingsGroup.get('layout').invalid &&
                  packageExportSettingsGroup.get('layout').dirty">
            {{VALIDATOR_MESSAGES.LAYOUT_REQUIRED}}
        </nuc-error>
    </nuc-form-field>

    <nuc-form-field label="Sort by fields">
        <nuc-suggestive-input
            formControlName="sortBy"
            placeholder="Sort the items by package material or campaign data fields"
            (searchTermChange)="onSortBySearched($event)"
            [enableNewValue]="false"
            [options]="sortByOptions"></nuc-suggestive-input>
    </nuc-form-field>

    <nuc-form-field label="Multiply items by field">
        <nuc-dropdown
            formControlName="multiplyItemsByField"
            placeholder="Select a field that is used to multiply the items by"
            [items]="fieldOptions">
        </nuc-dropdown>
    </nuc-form-field>

    <nuc-form-field label="Add inserts for fields">
        <nuc-dropdown-multiselect
            formControlName="addInsertsFor"
            placeholder="Select fields for which inserts should be added to the PDF"
            [items]="fieldOptions">
        </nuc-dropdown-multiselect>
    </nuc-form-field>

    <nuc-form-field label="Add title page">
        <nuc-checkbox text="Add a title page with this package info (title, description)"
            formControlName="addTitlePage">
        </nuc-checkbox>
    </nuc-form-field>
</form>
