<ng-container *ngIf="publicationItems?.length > 1">
    <nuc-tab-bar [tabs]="tabBarItems"
                 [(activeTab)]="selectedTab">
    </nuc-tab-bar>
    <hr class="divider" />
</ng-container>

<ng-container *ngIf="lastFileVersion else noFilesRevisionsState">
    <div class="version-container">
        <p class="body-strong black">Briefing changes since last file version: </p>
        <p>{{lastFileVersion?.getTitle()}}</p>
    </div>
    <hr class="divider" />

    <div class="content-container" [ngClass]="{'with-tab-bar': publicationItems?.length > 1}"
         *ngIf="!loading && selectedPublicationItem; else loadingIndicator">
        <div class="items-container" *ngIf="selectedPublicationItem.changedCampaignItems?.length else emptyState" scrollIntoView>
            <div *ngFor="let campaignItem of selectedPublicationItem.changedCampaignItems; index as index">
                <div class="item-header">
                    <div class="thumbnail">
                        <img [src]="campaignItem.thumb || 'assets/images/icon_image_main.svg'">
                    </div>
                    <div class="header-content">
                        <ng-container *ngIf="displayTitle">
                            <p class="body-strong">{{displayTitle}}</p>
                            <p class="text-ellipsis">{{campaignItem.dataFields | getProperty: this.actionOptions?.display?.value : dataFieldFormatter}}</p>
                        </ng-container>
                    </div>
                    <img class="pin"
                         [src]="selectedBriefingChange?.campaignItemId === campaignItem._id
                                && selectedBriefingChange?.publicationItemId === selectedPublicationItem._id
                                ? 'assets/images/icon_pin_briefing_changes_selected.svg'
                                : 'assets/images/icon_pin_briefing_changes.svg'"
                        (click)="onBriefingChangeClicked(selectedPublicationItem._id, campaignItem._id)" />
                </div>

                <div class="changes">
                    <p class="body-strong black">Briefing changes</p>
                    <div class="change-entry" *ngFor="let change of campaignItem.changes">
                        <div class="change-header">
                            <nuc-badge-dove [icon]="change.variantKey ? 'nucicon_variant' : null">{{change.name}}</nuc-badge-dove>
                            <nuc-icon-button *ngIf="change.newValue"
                                             icon="nucicon_duplicate"
                                             nucTooltipPosition="left"
                                             (click)="copyToClipboard(change.newValue)"
                                             nucTooltip="Copy"></nuc-icon-button>
                        </div>
                        <p class="change-description">{{(change.newValue ? change.newValue : '') | anyValueDisplay}}</p>
                    </div>
                </div>

                <hr />
            </div>
        </div>
    </div>
</ng-container>

<ng-template #loadingIndicator>
    <rl-loading-indicator *ngIf="loading"></rl-loading-indicator>
</ng-template>

<ng-template #emptyState>
    <nuc-empty-state-component imageUrl="assets/images/general_empty_state.svg"
                               title="No briefing changes"
                               [small]="true">
    </nuc-empty-state-component>
</ng-template>

<ng-template #noFilesRevisionsState>
    <nuc-empty-state-component imageUrl="assets/images/general_empty_state.svg"
                               title="There are no versions to check"
                               [small]="true">
    </nuc-empty-state-component>
</ng-template>
