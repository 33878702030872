import {Component, computed, DestroyRef, ElementRef, inject, input, InputSignal, OnInit, output, OutputEmitterRef, viewChild} from '@angular/core';
import {NgStyle} from '@angular/common';
import {PipesModule} from '../../../../../../../../pipes/pipes.module';
import {StickyNotesDataService} from '../../preview-sticky-notes-sidebar/sticky-notes-data.service';
import {CustomWorkflowService} from '../../../custom-workflow.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {StickyNoteModel} from '../../../../../../../../models/api/sticky-note.model';
import {EStickyNoteStatus} from '../../../../../../../../app.enums';
import {VariantModel} from '../../../../../../../../models/api/variant.model';
import {PointModel} from '../../../../../../../../models/api/point.model';
import {StickyNoteUtil} from '../../../sticky-note.util';
import {PublicationItemModel} from '../../../../../../../../models/api/publication-item.model';
import {animate, style, transition, trigger} from '@angular/animations';
import {CustomWorkflowPreviewDataService} from '../../custom-workflow-preview-data.service';
import {CustomWorkflowActionModel, EStickyNoteActionName} from '../../../../../../../../models/api/custom-workflow-action.model';
import {EPreviewOverlay} from '../preview-sticky-notes-view.component';
import {
    CustomWorkflowBriefingChangesDataService,
    IBriefingChange
} from '../../preview-briefing-changes/custom-workflow-briefing-changes-data.service';

export class StickyOverlayDisplayOptions {
    public showNotes: boolean = true;
    public showBriefingChanges: boolean = false;
    public highLight: boolean = false;

    get optionsSelected(): boolean {
        return this.showNotes || this.showBriefingChanges || this.highLight;
    }
}

@Component({
  selector: 'sticky-note-overlay',
  standalone: true,
    imports: [
        PipesModule,
        NgStyle
    ],
  templateUrl: './sticky-notes-overlay.component.html',
  styleUrl: './sticky-notes-overlay.component.scss',
animations: [
    trigger('newStickyNote', [
        transition(':enter', [
            style({transform: 'scale(0,0)'}),
            animate('0.25s cubic-bezier(.41,.05,.48,1.46)', style({transform: 'scale(1,1)'})),
        ])
    ]),
]
})
export class StickyNotesOverlayComponent implements OnInit {
    public item = input<PublicationItemModel>();
    public isDragging: InputSignal<boolean> = input<boolean>(false);
    public stickyNotesContainer = viewChild<ElementRef>('stickyNotesContainer');
    public createStickyNote: OutputEmitterRef<StickyNoteModel> = output<StickyNoteModel>();
    public notes = input<StickyNoteModel[]>();
    public actions: CustomWorkflowActionModel[] = [];
    public overlay: InputSignal<StickyOverlayDisplayOptions> = input<StickyOverlayDisplayOptions>();

    protected selectedStickyNote: StickyNoteModel;
    protected newStickyNote: StickyNoteModel;

    private stickyNotesDataService: StickyNotesDataService = inject(StickyNotesDataService);
    private customWorkflowService: CustomWorkflowService = inject(CustomWorkflowService);
    private previewDataService: CustomWorkflowPreviewDataService = inject(CustomWorkflowPreviewDataService);
    private customWorkflowBriefingChangesDataService: CustomWorkflowBriefingChangesDataService = inject(CustomWorkflowBriefingChangesDataService);
    private destroyRef: DestroyRef = inject(DestroyRef);

    private activeVariant: VariantModel;

    public previewOverlayEnum = EPreviewOverlay;
    public selectedBriefingChange: IBriefingChange;
    public dragging = computed(() => {
        return this.isDragging();
    })

    ngOnInit(): void {
        this.previewDataService.actions$.pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((actions) => {
            this.actions = actions;
        });

        // we should listen to what is selected, that's right
        this.stickyNotesDataService.selectedStickyNote$.pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((stickyNote: StickyNoteModel) => {
            if (stickyNote?.status !== EStickyNoteStatus.NEW) this.newStickyNote = null;
            this.selectedStickyNote = stickyNote;
        });

        this.customWorkflowService.activeVariant$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((variant: VariantModel) => {
                this.activeVariant = variant;
            });
        this.customWorkflowBriefingChangesDataService.selectedBriefingChange$.pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((briefingChange: IBriefingChange) => {
            this.selectedBriefingChange = briefingChange;
        });
    }

    public createSticky(event: MouseEvent) {
        if (!this.isDragging()) {
            this.newStickyNote = null;
            if (this.canCreateStickyNote()) {
                const percentageCoordinate = new PointModel(
                    (event.offsetX / this.stickyNotesContainer().nativeElement.clientWidth) * 100,
                    (event.offsetY / this.stickyNotesContainer().nativeElement.clientHeight) * 100);
                const campaignItem = StickyNoteUtil.findCampaignItemForPosition(percentageCoordinate, this.item());
                this.newStickyNote = new StickyNoteModel(percentageCoordinate, '', campaignItem, EStickyNoteStatus.NEW,
                    this.item(), this.activeVariant);
            }
            this.createStickyNote.emit(this.newStickyNote);
        }

    }

    /**
     * Prevent click on new note to trigger click on canvas.
     * @param event {MouseEvent}
     * @return {void}
     */
    public onNewStickyNoteClicked(event: MouseEvent): void {
        event.stopPropagation();
    }

    /**
     * Check if currently logged-in user has permissions to create sticky notes
     * @returns {boolean}
     */
    public canCreateStickyNote(): boolean {
        return this.actions.some((action) => action.name === EStickyNoteActionName.CREATE_STICKY_NOTE);
    }

    public onStickyNoteClicked(event: MouseEvent, stickyNote: StickyNoteModel): void {
        event.stopPropagation();
        this.stickyNotesDataService.setSelectedStickyNote(stickyNote);
    }

    public onBriefingChangeClicked(publicationItemId: string, campaignItemId: string): void {
        this.customWorkflowBriefingChangesDataService.setSelectedBriefingChange({publicationItemId, campaignItemId} as IBriefingChange);
    }
}
