import {Component, Inject, OnDestroy, OnInit, Optional} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {
    BUTTON_TYPE,
    ButtonConfig,
    FullModalActionModel,
    FullModalService,
    NUC_FULL_MODAL_DATA
} from '@relayter/rubber-duck';
import {TabBarItemModel} from '../../models/ui/tab-bar-item.model';
import {Toaster} from '../../classes/toaster.class';
import {
    PackageExportSettings,
    PackageSetupCampaignDataFieldModel,
    PackageSetupDataFieldModel,
    PackageSetupModel,
    PackageSetupPatchModel
} from '../../models/api/package-setup.model';
import {PackageSetupService} from '../../api/services/package-setups.service';
import {finalize, takeUntil} from 'rxjs/operators';
import {RulePropertyModel} from '../../models/api/rule-property.model';
import {EPropertyContext, PropertyService} from '../../api/services/property.service';
import {EDataFieldTypes} from '../../app.enums';
import {VariantModel} from '../../models/api/variant.model';
import {VariantService} from '../../api/services/variant.service';
import {Subject} from 'rxjs';

export interface IPackageSetupFormData {
    packageSetup?: PackageSetupModel;
}

export enum ETabFormGroup {
    PACKAGE_SETUP = 'packageSetup',
    PACKAGE_DATA = 'packageData',
    PACKAGE_EXPORT_SETTINGS = 'packageExportSettings'
}

@Component({
    selector: 'package-setup-form-component',
    templateUrl: 'package-setup-form.component.html',
    styleUrls: ['package-setup-form.component.scss']
})

export class PackageSetupFormComponent implements OnInit, OnDestroy {
    public TAB_INFORMATION = 0;
    public TAB_PACKAGE_DATA = 1;
    public TAB_EXPORT_SETTINGS = 2;

    public informationTab = new TabBarItemModel('Information', this.TAB_INFORMATION);
    public packageDataTab = new TabBarItemModel('Package data', this.TAB_PACKAGE_DATA);
    public packageExportSettingsTab = new TabBarItemModel('PDF Export settings', this.TAB_EXPORT_SETTINGS);
    public tabbarItems: TabBarItemModel[] = [
        this.informationTab,
        this.packageDataTab,
        this.packageExportSettingsTab];
    private _selectedTab = this.tabbarItems[this.TAB_INFORMATION];
    public get selectedTab(): TabBarItemModel {
        return this._selectedTab;
    }

    public set selectedTab(tab: TabBarItemModel) {
        if (tab !== this._selectedTab) {
            const index = this.tabbarItems.find((t) => t.title === tab.title).index;
            this._selectedTab = tab;
            this._selectedTab.index = index;
        }
    }
    private confirmAction: FullModalActionModel;
    private actions: FullModalActionModel[];
    public properties: RulePropertyModel[];
    public variants: VariantModel[];

    public form: UntypedFormGroup = new UntypedFormGroup({});
    public packageSetup: PackageSetupModel;
    private saveButton: ButtonConfig;
    private onDestroySubject = new Subject<void>();

    constructor(private fullModalService: FullModalService,
                private packageSetupService: PackageSetupService,
                private propertyService: PropertyService,
                private variantService: VariantService,
                @Optional() @Inject(NUC_FULL_MODAL_DATA) public modalData: IPackageSetupFormData) {
    }

    public ngOnInit(): void {
        this.initModalButtons();
        this.initFormData();
        this.getProperties();
        this.getVariants();
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private initModalButtons(): void {
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        this.saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save');

        const cancelAction = new FullModalActionModel(cancelButton);
        this.confirmAction = new FullModalActionModel(this.saveButton);

        cancelAction.observable.subscribe(() => this.fullModalService.close(false, true));
        this.confirmAction.observable.subscribe(() => this.onSaveButtonClicked());

        this.actions = [cancelAction, this.confirmAction];
        this.fullModalService.setModalActions(this.actions);
    }

    private initFormData(): void {
        this.packageSetup = this.modalData?.packageSetup || new PackageSetupModel();
        // Won't edit the rules in this form
        delete this.packageSetup.rules;
    }

    private getProperties(): void {
        this.propertyService.getProperties(EPropertyContext.PACKAGE_SETUP_DATA)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (result) => this.properties = result.items,
                error: Toaster.handleApiError
            });
    }

    private getVariants(): void {
        this.variantService.getVariants()
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((result) => this.variants = result.items, Toaster.handleApiError);
    }

    private onSaveButtonClicked(): void {
        if (this.form?.valid) {
            this.savePackageSetup();
        } else {
            Toaster.warn('Form contains errors. Please update incorrect and/or missing values');
            Object.keys(this.form.controls).forEach((key) => this.form.get(key).markAsDirty());
        }
    }

    private savePackageSetup(): void {
        // tab 1
        this.packageSetup.name = this.form.get(ETabFormGroup.PACKAGE_SETUP).value.name;
        this.packageSetup.description = this.form.get(ETabFormGroup.PACKAGE_SETUP).value.description;

        // tab 2
        if (!this.form.get(ETabFormGroup.PACKAGE_DATA)) {
            delete this.packageSetup.materialDataFields; // don't send in patch call
            delete this.packageSetup.campaignDataFields; // don't send in patch call
        } else {
            const packageData = this.form.get(ETabFormGroup.PACKAGE_DATA).value;
            this.packageSetup.materialDataFields = packageData.materialDataFields.map((dataField) => {
                return {
                    name: dataField.name,
                    dataType: {
                        type: dataField.type.getValue()
                    },
                    _id: dataField._id
                } as PackageSetupDataFieldModel;
            });

            this.packageSetup.campaignDataFields = packageData.campaignDataFields.map((dataField) => {
                return {
                    name: dataField.name,
                    property: dataField.property.getValue() + `${dataField.property.enableVariants ? '.' + dataField.variant?.getValue() : ''}`,
                    dataType: {
                        type: dataField.property.dataType.type === EDataFieldTypes.DATE ? EDataFieldTypes.DATE : EDataFieldTypes.STRING
                    },
                    _id: dataField._id,
                } as PackageSetupCampaignDataFieldModel;
            });
        }

        // tab 3
        if (!this.form.get(ETabFormGroup.PACKAGE_EXPORT_SETTINGS)) {
            delete this.packageSetup.exportSettings; // don't send in patch call
        } else {
            const exportSettings = this.form.get(ETabFormGroup.PACKAGE_EXPORT_SETTINGS).value;
            exportSettings.sortBy = exportSettings.sortBy?.map(dropDownItem => dropDownItem.getValue()) || [];
            exportSettings.addInsertsFor = exportSettings.addInsertsFor?.map(dropDownItem => dropDownItem.getValue());
            exportSettings.multiplyItemsByField = exportSettings.multiplyItemsByField?.getValue() || (this.packageSetup._id ? null : undefined);
            exportSettings.addTitlePage = exportSettings.addTitlePage === true;
            exportSettings.layout = exportSettings.layout?.getValue();

            if (Object.keys(exportSettings).some(key => Array.isArray(exportSettings[key]) ? exportSettings[key].length : !!exportSettings[key])) {
                this.packageSetup.exportSettings = exportSettings as PackageExportSettings;
            } else if (this.packageSetup._id) {
                this.packageSetup.exportSettings = {};
            } else {
                delete this.packageSetup.exportSettings;
            }
        }

        this.saveButton.loading = true;
        if (this.packageSetup._id) {
            // Update the package setup
            const patchBody = new PackageSetupPatchModel(this.packageSetup);
            this.packageSetupService.patchPackageSetup(this.packageSetup._id, patchBody)
                .pipe(finalize(() => this.saveButton.loading = false))
                .subscribe((result) => {
                        Toaster.success('Package setup updated successfully');
                        this.fullModalService.close(result);
                    },
                    (err) => Toaster.handleApiError(err));
        } else {
            // Create a package setup
            this.packageSetupService.postPackageSetup(this.packageSetup)
                .pipe(finalize(() => this.saveButton.loading = false))
                .subscribe((result) => {
                        Toaster.success('Package setup added successfully');
                        this.fullModalService.close(result);
                    },
                    (err) => Toaster.handleApiError(err));
        }

    }
}
