<div class="form-container" *ngIf="permissions.GET_PACKAGES | userIsAllowedTo; else noPermission">
    <nuc-table [emptyStateTitle]="searchValue ? 'No matching results.' : 'There is no package to assign to the publication item.'"
               [loading]="!packagesSubscription?.closed"
               [items]="packages"
               [columns]="columns"
               [selectionMode]="ESelectionMode.SINGLE"
               [selection]="selection"
               [tableId]="tableId"
               [prefixStorageKey]="storageKey"
               (sortOptionChange)="onSortOptionChange($event)">

        <nuc-input button-bar-left
                   [(data)]="searchValue"
                   prefix="nucicon_search"
                   (debounceValueChanged)="onSearchBarValueUpdated()"
                   placeholder="Search packages by name or description">
        </nuc-input>

        <paginator button-bar-right
            [disableNextPage]="!hasNext"
            [viewId]="viewId"
            [loading]="!packagesSubscription?.closed">
        </paginator>
    </nuc-table>
</div>

<ng-template #noPermission>
    <nuc-empty-state-component imageUrl="assets/images/empty_states/no_permission.svg"
                               title="No permission"
                               subtitle="You do not have the permission to view this page.">
    </nuc-empty-state-component>
</ng-template>
