import {Component, DestroyRef, inject, viewChild, ViewChild} from '@angular/core';
import {PrintPublicationItemModel} from '../../../../../../models/api/custom-workflow/print-publication-item.model';
import {UserIsAllowedToPipe} from '../../../../../../pipes/user-is-allowed-to.pipe';
import {TabBarItemModel} from '../../../../../../models/ui/tab-bar-item.model';
import {PublicationsService} from '../../../../../../api/services/publications.service';
import {ARApiError, ARLogger, ARPagedResponseDataModel} from '@relayter/core';
import {CampaignItemModel} from '../../../../../../models/api/campaign-item.model';
import {Toaster} from '../../../../../../classes/toaster.class';
import {CollectionOptionsModel} from '../../../../../../models/api/collection-options.model';
import {AssetModel} from '../../../../../../models/api/asset.model';
import {AssetService} from '../../../../../../api/services/asset.service';
import {Observable, Subject, Subscription} from 'rxjs';
import {CampaignItemsService} from '../../../../../../api/services/campaign-items.service';
import {CustomWorkflowActionModel} from '../../../../../../models/api/custom-workflow-action.model';
import {AppConstants} from '../../../../../../app.constants';
import {IEditorOptions, SpreadEditorComponent} from './spread-editor/spread-editor.component';
import {PropertySettingsModel} from '../../../../../../components/property-settings/property-settings.model';
import {EPropertySettingsContext, PropertySettingsService} from '../../../../../../components/property-settings/property-settings.service';
import {concatMap, distinctUntilChanged, filter, finalize, takeUntil, tap} from 'rxjs/operators';
import {EAssetDisplayProperties} from '../../../../../../pipes/asset-display.pipe';
import {CustomWorkflowService} from '../custom-workflow.service';
import {PublicationItemSelectionService, UpdatedWorkflowLayoutItemData} from '../custom-workflow-item-selection/publication-item-selection.service';
import {CustomWorkflowLayoutService} from './custom-workflow-layout.service';
import {CdkDragDrop, CdkDragExit, CdkDragMove} from '@angular/cdk/drag-drop';
import {
    BUTTON_TYPE,
    DialogCustomContentConfig,
    EColumnDataType,
    FullModalService,
    NucDialogConfigModel, NucDialogCustomContentService,
    NucDialogService
} from '@relayter/rubber-duck';
import {CursorArray} from '../../../../../../api/api-cursor';
import {CustomWorkflowBaseComponent} from '../custom-workflow-base.component';
import {WorkflowLayoutItem} from '../../../../../../models/api/custom-workflow/workflow-layout-item.model';
import {DropdownItem} from '../../../../../../models/ui/dropdown-item.model';
import {
    ContentChangeModel,
    ContentRevisionModel,
    EContentChangeAction,
    PublicationItemModel
} from '../../../../../../models/api/publication-item.model';
import {PublicationItemContentModel} from '../../../../../../models/api/publication-item-content.model';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {TemplateModel} from '../../../../../../models/api/template.model';
import {TitleCasePipe} from '@angular/common';
import {CustomWorkflowItemSelectionComponent, EItemSelectionType} from '../custom-workflow-item-selection/custom-workflow-item-selection.component';
import {ApiConstants} from '../../../../../../api/api.constant';
import {DataFilterModel} from '../../../../../../models/ui/data-filter.model';
import {EDataFieldCollectionName, EDataFieldTypes} from '../../../../../../app.enums';
import {AdvancedFiltersDataService} from '../../../../../../api/services/advanced-filters.data-service';
import {FileTypeUtil} from '../../../../../../classes/file-type.util';
import {
    CampaignItemInlineFormComponent
} from '../../../../../../forms/campaign-item-form/campaign-item-inline-form/campaign-item-inline-form.component';
import {EFullscreenEvents, EFullscreenModalEvents} from '../../../../../../directives/fullscreen-form-directive/fullscreen-form.directive';
import {MonitoredTransitionsService} from '../../../../../../api/services/monitored-transitions.service';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {NewCursor} from '../../../../../../api/new-api-cursor';
import {CampaignItemsApiService} from '../../../../../../api/services/campaign-items.api.service';
import {EWorkflowActionOptionName} from '../../../../../../models/api/custom-workflow-option.model';
import {TableSortOptions} from '../../../../../../api/table-sort-options';
import {Serialize} from 'cerialize';
import {LayoutNoteModel} from '../../../../../../models/api/layout-note.model';
import {ILayoutNoteFormData, LayoutNoteFormComponent} from './spread-editor/note/layout-note-form/layout-note-form.component';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {PublicationItemsApiService} from '../../../../../../api/services/publication-items-api.service';

// component specific actions, that cannot be generalized
enum EComponentActions {
    EDIT_LAYOUT = 'EDIT_LAYOUT',
    EDIT_LAYOUT_BRIEFING_ITEM = 'EDIT_LAYOUT_BRIEFING_ITEM',
    SHOW_LAYOUT_REVISIONS = 'SHOW_LAYOUT_REVISIONS'
}

export enum ELayoutOverlay {
    NONE = 'NONE',
    NOTES = 'NOTES'
}

interface IEditLayoutActionOptions {
    autoAssign: { name: string; value: boolean };
}

@Component({
    selector: 'rl-custom-workflow-layout-component',
    templateUrl: './custom-workflow-layout.component.html',
    styleUrls: ['./custom-workflow-layout.component.scss'],
    providers: [CustomWorkflowLayoutService, AdvancedFiltersDataService]
})
export class CustomWorkflowLayoutComponent extends CustomWorkflowBaseComponent {
    public spreadEditorComponent = viewChild(SpreadEditorComponent);
    private destroyRef = inject(DestroyRef);

    public editLayoutAction: CustomWorkflowActionModel;
    public editLayoutBriefingItemAction: CustomWorkflowActionModel;
    public editLayoutOptions: IEditLayoutActionOptions;
    public showLayoutRevisionsAction: CustomWorkflowActionModel;

    public editorOptions: IEditorOptions;

    public briefingItemsTab: TabBarItemModel = new TabBarItemModel('Briefing Items', 0);
    public assetsTab: TabBarItemModel = new TabBarItemModel('Assets', 1);
    public notesTab: TabBarItemModel = new TabBarItemModel('Notes', 2);

    public tabs: TabBarItemModel[] = [this.briefingItemsTab, this.assetsTab, this.notesTab];
    public changesTabs: TabBarItemModel[] = [
        new TabBarItemModel(this.titleCasePipe.transform(EContentChangeAction.ADDED), 0),
        new TabBarItemModel(this.titleCasePipe.transform(EContentChangeAction.REMOVED), 1),
        new TabBarItemModel(this.titleCasePipe.transform(EContentChangeAction.EDITED), 2)
    ];
    public activeTab: TabBarItemModel;
    public activeChangesTab: TabBarItemModel;
    public revisionsItems: IDropdownItem<PublicationItemContentModel[]>[] = [];

    public campaignItems: CampaignItemModel[];
    public assets: AssetModel[];
    public changes: Record<EContentChangeAction, ContentChangeModel[]>;

    public campaignItemCollectionOptions: CollectionOptionsModel;

    public campaignItemSearch: string;
    public campaignItemsSubscription: Subscription;
    public assetsSubscription: Subscription;
    public patchPublicationItemSubscription: Subscription;

    private cursorArray: CursorArray;
    public disableNextPage = true;
    public assetsLoading = false;
    public pageIndex: number;
    static ASSET_SORT = 'updatedAt';
    static ASSET_PAGE_SIZE = 20;

    public assetSearch: string;

    public publicationItemSubscription: Subscription;
    public publicationItem: PrintPublicationItemModel;

    public BADGE_TEXT = 'Placed';

    public propertySettings: PropertySettingsModel;
    public context = EPropertySettingsContext.BRIEFING;
    public filterContext = EDataFieldCollectionName.ASSET;
    public allowedAssets: string[];
    public selection: string[]; // for both campaignItemIds and assetIds
    public selectedCampaignItemIds: string[];
    public content: PublicationItemContentModel[] = [];
    public template: TemplateModel;

    public EAssetDisplayProperties = EAssetDisplayProperties;

    public mouseEvent: MouseEvent;

    public workflowLayoutItem: WorkflowLayoutItem;

    public transferringItem: CampaignItemModel | AssetModel | LayoutNoteModel | undefined = undefined;

    public showBriefingForm: boolean = false;
    public editCampaignItemEvents: Subject<EFullscreenEvents> = new Subject<EFullscreenEvents>();
    public editCampaignItem: CampaignItemModel;
    @ViewChild(CampaignItemInlineFormComponent) public editBriefingForm: CampaignItemInlineFormComponent;
    @ViewChild(CustomWorkflowItemSelectionComponent, {static: true}) public itemSelection: CustomWorkflowItemSelectionComponent;

    public editCampaignItemPubItemId: string;

    public formGroup: UntypedFormGroup;

    public layoutOverlay: ELayoutOverlay = ELayoutOverlay.NOTES;
    public readonly layoutOverlayEnum = ELayoutOverlay;
    public layoutOverlayOptions: DropdownItem<ELayoutOverlay>[] = [
        new DropdownItem('Show layout notes', ELayoutOverlay.NOTES),
        new DropdownItem('Hide layout notes', ELayoutOverlay.NONE)];
    public dataFilters: DataFilterModel[] = [];
    private filterValues: Record<string, any> = {};
    public fullscreenModalEventSubject = new Subject<EFullscreenModalEvents>();
    public campaignDetailsSubscription: Subscription;

    constructor(userIsAllowedToPipe: UserIsAllowedToPipe,
                customWorkflowService: CustomWorkflowService,
                publicationService: PublicationsService,
                dialogService: NucDialogService,
                fullModalService: FullModalService,
                monitoredTransitionsService: MonitoredTransitionsService,
                private campaignItemService: CampaignItemsService,
                private assetService: AssetService,
                private propertySettingsService: PropertySettingsService,
                private customWorkflowLayoutService: CustomWorkflowLayoutService,
                public publicationItemSelectionService: PublicationItemSelectionService,
                public titleCasePipe: TitleCasePipe,
                private advancedFiltersDataService: AdvancedFiltersDataService,
                private campaignItemApiService: CampaignItemsApiService,
                private dialogCustomContentService: NucDialogCustomContentService,
                private publicationItemsApiService: PublicationItemsApiService) {
        super(userIsAllowedToPipe, customWorkflowService, publicationService, dialogService, fullModalService, monitoredTransitionsService);
    }

    public initComponent(): void {
        super.initComponent();

        this.cursorArray = new CursorArray();

        this.publicationItemSelectionService.setItemSelectionType(EItemSelectionType.ITEM_SELECTION);

        this.initDataFilters();

        this.fullscreenModalEventSubject
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((result) => {
            if (result !== EFullscreenModalEvents.FULLSCREEN_TOGGLE) {
                this.closeEditBriefing(null);
            }
        });

        // Set up the revision Dropdown
        const revisionFormControl = new UntypedFormControl();
        revisionFormControl.valueChanges.pipe(
            tap((item) => {
                if (!item) this.updateEditorOptions();
            }),
            distinctUntilChanged(),
            filter((item) => !!item),
            takeUntil(this.onDestroySubject)
        ).subscribe((selectedItem: DropdownItem<PublicationItemContentModel[]>) => {
            // compare selected layout with layout before selected
            const selectedIndex = this.revisionsItems.findIndex(item => item.getTitle() === selectedItem.getTitle());

            if (selectedIndex === this.revisionsItems.length - 1) {
                this.changes = {
                    [EContentChangeAction.ADDED]: [],
                    [EContentChangeAction.REMOVED]: [],
                    [EContentChangeAction.EDITED]: []
                };
            } else {
                const changes = PublicationItemModel.diffRevision(this.revisionsItems[selectedIndex + 1].getValue(), selectedItem.getValue());
                this.changes = {
                    [EContentChangeAction.ADDED]: changes.filter(change => change.action === EContentChangeAction.ADDED),
                    [EContentChangeAction.REMOVED]: changes.filter(change => change.action === EContentChangeAction.REMOVED),
                    [EContentChangeAction.EDITED]: changes.filter(change => change.action === EContentChangeAction.EDITED)
                };
            }

            this.content = selectedItem.getValue();
            this.updateEditorOptions();
        });

        this.formGroup = new UntypedFormGroup({
            revision: revisionFormControl
        });

        this.propertySettingsService.getSettings(EPropertySettingsContext.BRIEFING).pipe(
            takeUntil(this.onDestroySubject)
        ).subscribe((propertySettings) => this.propertySettings = propertySettings);

        this.publicationItemSelectionService.selectedWorkflowLayoutItem$.pipe(
            distinctUntilChanged(),
            takeUntil(this.onDestroySubject)
        ).subscribe((workflowLayoutItem) => {
            this.workflowLayoutItem = workflowLayoutItem;
            this.refreshData();
        });

        this.customWorkflowLayoutService.layoutUpdated$.pipe(takeUntil(this.onDestroySubject)).subscribe(
            (content) => this.onLayoutUpdate(content));

        this.customWorkflowLayoutService.externalDataChanged$.pipe(takeUntil(this.onDestroySubject)).subscribe(
            () => this.onExternalDataChanged()
        );

        this.customWorkflowService.activeComponent$.pipe(takeUntil(this.onDestroySubject)).subscribe(() => {
            // reset the tabs here when component changes
            this.activeTab = this.briefingItemsTab;
            this.resetPublicationItem();

            if (this.showLayoutRevisionsAction) { // reset layout component with revisions
                this.activeChangesTab = this.changesTabs[0];
                this.formGroup.get('revision').setValue(null);
            }
        });

        this.advancedFiltersDataService.getFilterValues()
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(filterValues => {
               if (this.filterValues !== filterValues) {
                   this.filterValues = filterValues;

                   this.resetAssets();
               }
            });

        this.customWorkflowService.editCampaignItemId$.pipe(takeUntil(this.onDestroySubject))
            .subscribe((result: { campaignItemId: string; pubItemId: string }) => {
                if (result?.campaignItemId) {
                    if (result.campaignItemId === this.editCampaignItem?._id) {
                        this.editBriefingForm?.beforeCloseForm();
                    } else {
                        if (this.editBriefingForm?.isFormTouched()) {
                            const dialogConfig = new NucDialogConfigModel('Edit briefing item.', 'There are unsaved changes that will be lost.');
                            const dialog = this.dialogService.openDialog(dialogConfig);
                            dialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => dialog.close());
                            dialogConfig.addAction('Ok', BUTTON_TYPE.PRIMARY).subscribe(() => {
                                dialog.close();
                                this.editCampaignItem = null;
                                this.showBriefingForm = true;
                                this.getCampaignItemDetailsForEditing(result.campaignItemId);
                                this.editCampaignItemPubItemId = result.pubItemId;
                            });
                        } else {
                            this.editCampaignItem = null;
                            this.showBriefingForm = true;
                            this.getCampaignItemDetailsForEditing(result.campaignItemId);
                            this.editCampaignItemPubItemId = result.pubItemId;
                        }
                    }
                } else {
                    this.showBriefingForm = false;
                    this.editCampaignItem = null;
                    this.editCampaignItemPubItemId = null;
                }
            });

        this.customWorkflowService.activeVariant$
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(variant => {
                this.activeVariant = variant;
            });
    }

    protected setupData(): void {}

    private initDataFilters(): void {
        this.dataFilters.push(new DataFilterModel('RIN', 'rin', EDataFieldTypes.STRING));
        this.allowedAssets = FileTypeUtil.getExtensionsFromFileCategories([FileTypeUtil.CATEGORIES.INDESIGN_GENERATION]);

        const dropdownItems = this.allowedAssets.map(item => new DropdownItem(item, item));
        this.dataFilters.push(new DataFilterModel('File type', 'type', EDataFieldTypes.ENUM, dropdownItems,
            {multiSelect: true, items: this.allowedAssets}));
    }

    protected refreshData(transitionItemId: string = null): void {
        if (transitionItemId) {
            this.publicationItemSelectionService
                .setUpdatedWorkflowLayoutItem(new UpdatedWorkflowLayoutItemData(this.workflowLayoutItem, transitionItemId));
        } else {
            this.resetPublicationItem();
            this.campaignItemSearch = null;
            this.resetCampaignItems();
        }
    }

    protected updateActions(): void {
        super.updateActions();
        this.editLayoutAction = this.findWorkflowActionByName(EComponentActions.EDIT_LAYOUT);
        this.editLayoutBriefingItemAction = this.findWorkflowActionByName(EComponentActions.EDIT_LAYOUT_BRIEFING_ITEM);
        this.showLayoutRevisionsAction = this.findWorkflowActionByName(EComponentActions.SHOW_LAYOUT_REVISIONS);
        this.updateEditorOptions();
    }

    private updateEditorOptions(): void {
        this.editLayoutOptions = undefined;
        if (this.editLayoutAction && this.editLayoutAction.options) {
            this.editLayoutOptions = {
                autoAssign: this.editLayoutAction.options.find((option) => option.name === EWorkflowActionOptionName.AUTO_ASSIGN)
            } as IEditLayoutActionOptions;
        }

        this.editorOptions = {
            editEnabled: !!this.editLayoutAction,
            editBriefingItem: !!this.editLayoutBriefingItemAction,
            showLayoutNotes: this.layoutOverlay === ELayoutOverlay.NOTES
        };
    }

    private resetPublicationItem(): void {
        this.publicationItem = null;
        this.publicationItemSubscription?.unsubscribe();
        this.patchPublicationItemSubscription?.unsubscribe();

        if (this.workflowLayoutItem) {
            // Layout could only have 1 publication item
            if (this.workflowLayoutItem.publicationItems.length !== 1) return;
            this.publicationItemSubscription =
                this.publicationService.getPublicationItem(PrintPublicationItemModel, this.publication._id,
                    this.workflowLayoutItem.publicationItems[0]._id)
                    .pipe(takeUntil(this.onDestroySubject))
                    .subscribe({
                        next: (result) => {
                            this.updatePublicationItem(result);
                        },
                        error: (error) => Toaster.handleApiError(error)
                    });
        }
    }

    private updatePublicationItem(item: PrintPublicationItemModel): void {
        this.publicationItem = item;
        this.template = this.publicationItem.template;
        this.content = this.publicationItem.content;

        if (this.showLayoutRevisionsAction && this.publicationItem.contentRevisions) {

            const currentVersion =
                new DropdownItem('Current version', this.publicationItem.content) as IDropdownItem<PublicationItemContentModel[]>;

            this.revisionsItems = this.publicationItem.contentRevisions
                ? [currentVersion].concat(this.publicationItem.contentRevisions.reverse())
                : [currentVersion];

            const selectedRevisionItem = this.formGroup.value.revision;
            const foundItem = this.revisionsItems.find((revisionsItem: ContentRevisionModel) => {
                // when the revision and date match, we think it's an existing content revision
                // we cannot compare content easily as briefing item details can change...
                return revisionsItem?.revision === selectedRevisionItem?.revision && revisionsItem?.date === selectedRevisionItem?.date;
            });
            const itemToSelect = foundItem || this.revisionsItems[0];
            this.formGroup.get('revision').setValue(itemToSelect);
        }

        this.updateSelection(item.content);
    }

    /**
     * Resets the campaignItemCollectionOptions & Gets new campaign items
     */
    private resetCampaignItems(): void {
        if (this.campaignItemsSubscription) {
            this.campaignItemsSubscription.unsubscribe();
        }
        this.campaignItemCollectionOptions = new CollectionOptionsModel();
        this.campaignItemCollectionOptions.pageSize = 50;
        this.campaignItems = [];
        if (this.workflowLayoutItem) {
            this.getCampaignItems();
        }
    }

    private resetAssets(): void {
        this.assets = [];
        this.pageIndex = 1;
        this.resetCursorArray();
        if (this.workflowLayoutItem) {
            this.getAssets();
        }
    }

    /**
     * Retrieves the campaign items assigned to the Publication Item from the API
     */
    public getCampaignItems(): void {
        this.campaignItemCollectionOptions.isLoading = true;

        if (this.campaignItemsSubscription) {
            this.campaignItemsSubscription.unsubscribe();
        }

        let campaignItems$: Observable<ARPagedResponseDataModel<CampaignItemModel>>;
        if (!this.editLayoutOptions || !this.editLayoutOptions.autoAssign || !this.editLayoutOptions.autoAssign.value) {
            // TODO: Restrict layout to have 1 item
            // Normal assign, layout flow
            campaignItems$ = this.publicationService.getCampaignItemsForPublicationItem(
                this.publication._id,
                this.workflowLayoutItem?.publicationItems[0]._id,
                this.campaignItemSearch,
                this.campaignItemCollectionOptions.pageSize,
                this.campaignItemCollectionOptions.pageSize * (this.campaignItemCollectionOptions.page - 1));
        } else {
            const lastItem = this.campaignItems[this.campaignItems.length - 1];
            const cursor = new NewCursor(new TableSortOptions(), lastItem?._id)
            // Simple flow skip assign, directly layout campaign items
            campaignItems$ = this.campaignItemApiService.getCampaignItems(
                this.campaign._id, this.publication._id, this.campaignItemCollectionOptions.pageSize, 0,
                this.campaignItemSearch, null, null, cursor);
        }

        this.campaignItemsSubscription = campaignItems$.pipe(
            takeUntil(this.onDestroySubject),
            finalize(() => this.campaignItemCollectionOptions.isLoading = false))
            .subscribe({
                next: (response: ARPagedResponseDataModel<CampaignItemModel>) => {
                    this.campaignItemCollectionOptions.page++;
                    this.campaignItemCollectionOptions.total = response.total;
                    this.campaignItems = this.campaignItems.concat(response.items);
                },
                error: Toaster.handleApiError
            });
    }

    public onButtonNextClicked(): void {
        this.pageIndex += 1;
        this.getAssets();
    }

    public getAssets(): void {
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_ASSETS)) {
            // prevent showing results from older slower calls
            if (this.assetsSubscription) {
                this.assetsSubscription.unsubscribe();
            }

            this.assetsLoading = true;

            const cursor = this.cursorArray.getCursor(this.pageIndex);
            // Only show allowed asset types if user not selected a filter on type
            if (!this.filterValues['type']) {
                this.filterValues['type'] = this.allowedAssets;
            }

            this.assetsSubscription = this.assetService
                .getAssets(true,
                    !this.assetSearch ? CustomWorkflowLayoutComponent.ASSET_SORT : null,
                    this.assetSearch,
                    CustomWorkflowLayoutComponent.ASSET_PAGE_SIZE,
                    0,
                    this.filterValues,
                    cursor)
                .pipe(
                    finalize(() => this.assetsLoading = false),
                    takeUntil(this.onDestroySubject))
                .subscribe({
                    next: (res: ARPagedResponseDataModel<AssetModel>) => {
                        this.assets = this.assets.concat(res.items);
                        this.disableNextPage = !res.hasNext;

                        if (res.items.length > 0) {
                            this.setCursor();
                        }
                    },
                    error: (err: ARApiError) => Toaster.handleApiError(err)
                });
        }
    }

    private resetCursorArray(pageIndex = this.pageIndex): void {
        if (this.assetSearch) {
            this.cursorArray.reset(pageIndex, true, EColumnDataType.NUMBER);
        } else {
            this.cursorArray.reset(pageIndex, true);
        }
    }

    private setCursor(): void {
        if (this.assetSearch) {
            this.cursorArray.setCursor(this.pageIndex, ApiConstants.SEARCH_INDEX_SCORE, this.assets[this.assets.length - 1]);
        } else {
            this.cursorArray.setCursor(this.pageIndex, CustomWorkflowLayoutComponent.ASSET_SORT, this.assets[this.assets.length - 1]);
        }
    }

    /**
     * Responds to layout updates of the spread editor
     * Saves the layout by patching the content property of the publication item
     */
    public onLayoutUpdate(content: PublicationItemContentModel[]): void {
        ARLogger.debug('Layout Component layoutUpdated: saving');
        let campaignItems = content
            .filter((contentItem) => contentItem.contentType === AppConstants.PUBLICATION_ITEM_CONTENT_TYPES.CAMPAIGN_ITEM)
            .map((contentItem) => contentItem.campaignItem._id);
        campaignItems = [...new Set(campaignItems)]; // get unique campaign items

        this.updateSelection(content);

        const patchBody = !this.editLayoutOptions || !this.editLayoutOptions.autoAssign || !this.editLayoutOptions.autoAssign.value ?
            {content} : {campaignItems, content};

        this.patchPublicationItemSubscription = this.publicationService.patchPublicationItem<PrintPublicationItemModel>(PrintPublicationItemModel,
            Serialize(patchBody), this.publication._id,
            this.publicationItem._id)
            .pipe(
                concatMap(() => this.publicationService.getPublicationItem<PrintPublicationItemModel>(PrintPublicationItemModel,
                    this.publication._id, this.publicationItem._id))
            )
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (publicationItem: PrintPublicationItemModel) => {
                    this.publicationItem = publicationItem;
                    this.content = publicationItem.content;
                },
                error: (error: ARApiError) => Toaster.handleApiError(error)
            });
    }

    /**
     * Responds to any data changes made by the user (that need to be reflected in the publication item),
     * so we need to refresh the campaign items, refresh the publication items and refresh the layout of the spread editor
     */
    public onExternalDataChanged(): void {
        this.resetCampaignItems();

        this.publicationItemSubscription?.unsubscribe();
        this.publicationItemSubscription = this.publicationService.getPublicationItem<PrintPublicationItemModel>(
            PrintPublicationItemModel,
            this.publication._id,
            this.publicationItem._id
        )
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (item: PrintPublicationItemModel) => {
                    // Update local publication item to keep in sync with updated item
                    // Otherwise, the pixi items could be redrawn on the wrong positions
                    this.updatePublicationItem(item);
                    // this.customWorkflowLayoutService.refreshLayout();
                },
                error: (error: ARApiError) => Toaster.handleApiError(error)
            });
    }

    public updateSelection(content: PublicationItemContentModel[]): void {
        const campaignItemIds: string[] = content.filter((contentItem) =>
            contentItem.contentType === AppConstants.PUBLICATION_ITEM_CONTENT_TYPES.CAMPAIGN_ITEM).map(
                (contentItem) => contentItem.campaignItem._id);

        const assetIds = content.filter((contentItem) => contentItem.contentType === AppConstants.PUBLICATION_ITEM_CONTENT_TYPES.ASSET)
            .map((contentItem) => typeof contentItem.asset === 'string' ? contentItem.asset : contentItem.asset._id);

        const noteIds = content.filter((contentItem) => contentItem.contentType === AppConstants.PUBLICATION_ITEM_CONTENT_TYPES.LAYOUT_NOTE)
            .map((contentItem) => contentItem.layoutNote._id);

        this.selectedCampaignItemIds = [...new Set(campaignItemIds)];
        this.selection = [...new Set(campaignItemIds.concat(assetIds.concat(noteIds)))];
    }

    public onCampaignItemSearchChanged(): void {
        this.resetCampaignItems();
    }

    /**
     * keep track of the mouse cursor position
     * @param {CdkDragMove} dragMoved
     */
    public onMove(dragMoved: CdkDragMove): void {
        if (dragMoved.event instanceof MouseEvent) {
            this.mouseEvent = dragMoved.event;
        }
    }

    public itemReleased(event: CdkDragDrop<CampaignItemModel | AssetModel | LayoutNoteModel>): void {
        this.customWorkflowLayoutService.addToLayout({dragData: event.item.data, mouseEvent: this.mouseEvent});
        this.transferringItem = undefined;
        this.mouseEvent = undefined;
    }

    public enteredItems(): void  {
        this.transferringItem = undefined;
    }

    public exitedCampaignItems(event: CdkDragExit<CampaignItemModel>): void  {
        this.transferringItem = event.item.data;
    }

    public exitedAssets(event: CdkDragExit<AssetModel>): void  {
        this.transferringItem = event.item.data;
    }

    public exitedNote(event: CdkDragExit<LayoutNoteModel>): void  {
        this.transferringItem = event.item.data;
    }

    public onAssetSearchChanged(): void {
        this.resetAssets();
    }

    /**
     * Set the layout overlay on selection of dropdown item
     * @param {DropdownItem<ELayoutOverlay>} layoutOverlayItem
     */
    public setLayoutOverlay(layoutOverlayItem: DropdownItem<ELayoutOverlay>): void  {
        if (this.layoutOverlay !== layoutOverlayItem.getValue()) {
            this.layoutOverlay = layoutOverlayItem.getValue();
            this.updateEditorOptions();
        }
    }

    public closeEditBriefing(campaignItem: CampaignItemModel): void {
        if (campaignItem) {
            this.onExternalDataChanged();
        }
        this.customWorkflowService.setEditCampaignItemId(null);
        this.editCampaignItemEvents.next(EFullscreenEvents.CLOSE);
    }

    public editCampaignItemClicked(campaignItem: CampaignItemModel): void {
        this.customWorkflowService.setEditCampaignItemId({campaignItemId: campaignItem._id, pubItemId: this.publicationItem._id});
    }

    protected requestClose(): void  {
        if (this.editBriefingForm?.isFormTouched()) {
            const dialogConfig = new NucDialogConfigModel('Edit briefing item.', 'There are unsaved changes that will be lost.');
            const dialog = this.dialogService.openDialog(dialogConfig);
            dialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => {
                dialog.close();
                this.closeSubscription.next(false);
            });
            dialogConfig.addAction('Ok', BUTTON_TYPE.PRIMARY).subscribe(() => {
                dialog.close();
                this.closeEditBriefing(null);
                this.closeSubscription.next(true);
            });
        } else {
            this.closeSubscription.next(true);
        }
    }

    private getCampaignItemDetailsForEditing(itemId: string): void {
        this.editCampaignItem = null;
        this.campaignDetailsSubscription = this.campaignItemService.getDetails(this.campaign._id, itemId).pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (campaignItemDetails: CampaignItemModel) => {
                    this.editCampaignItem = campaignItemDetails;
                    this.customWorkflowService.setEditCampaignItemDetails(this.editCampaignItem);
                },
                error: Toaster.handleApiError
            });
    }

    public openLayoutNoteForm(layoutNote?: LayoutNoteModel) {
        const editDialogConfig = new DialogCustomContentConfig(
            'Edit note', 'Specify the color and enter a description of your note.', {layoutNote} as ILayoutNoteFormData);
        const addDialogConfig = new DialogCustomContentConfig(
            'Add note', 'Specify the color and enter a description of your note.', {});

        this.dialogCustomContentService.open(LayoutNoteFormComponent, layoutNote ? editDialogConfig : addDialogConfig)
            .afterClosed()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((result) => {
                if (result) {
                    if (layoutNote) {
                        this.publicationItemsApiService.updateLayoutNote(this.publication._id, this.publicationItem._id, result)
                            .subscribe({
                                next: () => this.getPublicationItem(),
                                error: (error: ARApiError) => Toaster.handleApiError(error)
                            });
                    } else {
                        this.publicationItemsApiService.postLayoutNote(this.publication._id, this.publicationItem._id, result)
                            .subscribe({
                                next: () => this.getPublicationItem(),
                                error: Toaster.handleApiError
                            });
                    }
                }
            });
    }

    public preDeleteLayoutNote(layoutNote: LayoutNoteModel) {
        const dialogConfig = new NucDialogConfigModel(
            `Delete layout note '${layoutNote.message}'`,
            'The layout note will be deleted. Are you sure?'
        );
        const dialog = this.dialogService.openDialog(dialogConfig);
        dialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => dialog.close());
        dialogConfig.addAction('Ok', BUTTON_TYPE.PRIMARY).subscribe(() => {
            dialog.close();
            this.deleteLayoutNote(layoutNote);
        });
    }

    private deleteLayoutNote(layoutNote: LayoutNoteModel): void {
        this.publicationItemsApiService.deleteLayoutNote(this.publication._id, this.publicationItem._id, layoutNote._id)
            .subscribe({
                next: () => this.getPublicationItem(),
                error: Toaster.handleApiError
            });
    }

    private getPublicationItem(): void {
        this.publicationService.getPublicationItem<PrintPublicationItemModel>(PrintPublicationItemModel,
            this.publication._id, this.publicationItem._id)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (publicationItem: PrintPublicationItemModel) => {
                    this.publicationItem = publicationItem;
                    this.content = publicationItem.content;
                },
                error: Toaster.handleApiError
            });
    }
}
