<nuc-tab-bar [tabs]="tabBarItems" [(activeTab)]="activeTab"></nuc-tab-bar>

<form [formGroup]="formGroup" *ngIf="formGroup">
    <ng-container [ngSwitch]="activeTab">
        <div *ngSwitchCase="informationTab" class="information" [formGroup]="formGroup.controls.information">
            <nuc-form-field label="Name">
                <nuc-input formControlName="name" placeholder="Enter name" required></nuc-input>
            </nuc-form-field>

            <nuc-form-field label="Description">
                <nuc-textarea formControlName="description" placeholder="Enter description"></nuc-textarea>
            </nuc-form-field>
        </div>

        <div *ngSwitchCase="sortingItemsTab" formArrayName="sortItems" class="sort-items">
            <h2>Sorting items</h2>
            <p>Add one or more sorting items for the export setup</p>
            <hr />

            <div class="sort-items" cdkDropList (cdkDropListDropped)="drop($event)">
                <div class="sort-item" *ngFor="let item of formGroup.controls.sortItems.controls, let index = index" cdkDrag>
                    <div class="header">
                        <span>Sort item #{{index + 1}}</span>
                        <nuc-button-secondary icon="nucicon_selector" cdkDragHandle></nuc-button-secondary>
                        <nuc-button-secondary icon="nucicon_trash_fill" (click)="removeSortItem(index)"></nuc-button-secondary>
                    </div>

                    <div class="sort-item-form" [formGroup]="item">
                        <nuc-form-field label="Label">
                            <nuc-input formControlName="label" placeholder="Enter label"></nuc-input>
                        </nuc-form-field>
                    </div>

                </div>
            </div>

            <nuc-button-bar>
                <nuc-button-secondary text="Add Sorting item" icon="nucicon_add" iconPosition="end" (click)="addSortItem()"></nuc-button-secondary>
            </nuc-button-bar>
        </div>

        <div *ngSwitchCase="exportFormatTab" [formGroup]="formGroup.controls.exportFormat" class="export-format">
            <p>Only image file types are affected by the format settings, other file types will be exported in their original format</p>
            <hr />

            <nuc-form-field label="Format">
                <nuc-dropdown formControlName="format"
                              [nullOption]="false"
                              [total]="formatOptions.length"
                              [items]="formatOptions"
                              placeholder="Select a format">
                </nuc-dropdown>
            </nuc-form-field>

            <nuc-form-field label="Scale type">
                <nuc-dropdown formControlName="scaleType"
                              [nullOption]="false"
                              [total]="scaleTypes.length"
                              [items]="scaleTypes"
                              placeholder="Select a scale type">
                </nuc-dropdown>
            </nuc-form-field>

            <nuc-form-field label="Width">
                <nuc-input type="number" min="1" max="20000" formControlName="width" placeholder="Fill in the width"></nuc-input>
            </nuc-form-field>

            <nuc-form-field label="Height">
                <nuc-input type="number" min="1" max="20000" formControlName="height" placeholder="Fill in the height"></nuc-input>
            </nuc-form-field>
        </div>
    </ng-container>
</form>
