<form class="fragment-form" [formGroup]="formGroup" *ngIf="!loadingForm else loadingIndicator">

    <nuc-form-field label="Choose the display properties from the campaign item">
        <nuc-dropdown-multiselect formControlName="campaignItemDataFields"
                                  [items]="campaignItemFieldOptions"
                                  placeholder="Select display properties">
        </nuc-dropdown-multiselect>
    </nuc-form-field>

    <div class="receivers-container">
        <h2>Receivers</h2>
        <div class="receiver" *ngFor="let receiverGroup of formGroup.controls.receivers['controls']; let index = index">
            <workflow-configuration-action-notification-receiver-form
                [receiverOptions]="receiverTypes"
                [noteStatuses]="noteToStatus"
                [users]="users"
                [formGroup]="receiverGroup">
            </workflow-configuration-action-notification-receiver-form>
            <nuc-button-bar>
                <div></div>
                <nuc-button-secondary icon="nucicon_trash_fill" (click)="deleteReceiver(index)"></nuc-button-secondary>
            </nuc-button-bar>
        </div>

        <div class="receivers-header">
            <nuc-button-secondary text="Add receiver" icon="nucicon_add" iconPosition="end" (click)="addReceiver()"></nuc-button-secondary>
        </div>
    </div>
</form>

<ng-template #loadingIndicator>
    <rl-loading-indicator size="large"></rl-loading-indicator>
</ng-template>
