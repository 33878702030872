<form class="add-page-form" [formGroup]="formGroup" *ngIf="formGroup; else loadingIndicator">
    <nuc-form-field label="Print Template">
        <nuc-dropdown
            formControlName="template"
            placeholder="Select print template"
            [items]="templateOptions"
            [total]="totalItems"
            [searchable]="true"
            (requestData)="searchTemplates($event)"
            required>
        </nuc-dropdown>
    </nuc-form-field>
    <nuc-form-field label="Amount (max 100)">
        <nuc-input
            type="number"
            min="1"
            max="100"
            formControlName="amount"
            required>
        </nuc-input>
    </nuc-form-field>
</form>

<ng-template #loadingIndicator>
    <rl-loading-indicator></rl-loading-indicator>
</ng-template>
