import {Component} from '@angular/core';
import {IndesignLibraryFormComponent} from '../indesign-library-form/indesign-library-form.component';
import {DropdownItem} from '../../../models/ui/dropdown-item.model';
import {EEngineType} from '../../../models/api/template.model';
import {ELibraryJobTypes} from '../../../api/services/design-library.service';
import {FileTypeUtil} from '../../../classes/file-type.util';

@Component({
    selector: 'rl-svg-library-form-component',
    templateUrl: 'svg-library-form.component.html',
    styleUrls: ['svg-library-form.component.scss']
})
export class SVGLibraryFormComponent extends IndesignLibraryFormComponent {
    public engineTypes = [
        new DropdownItem(EEngineType.SVG, EEngineType.SVG)
    ];

    public updateLibraryJobName = ELibraryJobTypes.UPDATE_SVG_LIBRARY;
    public createLibraryJobName = ELibraryJobTypes.CREATE_SVG_LIBRARY;

    public fileTypeCategories = [FileTypeUtil.CATEGORIES.SVG_LIBRARY_FILE];
}
