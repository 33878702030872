import {autoserialize, autoserializeAs} from 'cerialize';

export class AfterEffectsProjectFileUsageInAnimatedContentTemplateModel {
    @autoserialize public animatedContentTemplateId: string;
    @autoserialize public name: string;
}

export class AfterEffectsProjectFileUsageModel {
    @autoserializeAs(AfterEffectsProjectFileUsageInAnimatedContentTemplateModel) public animatedContentTemplates:
        AfterEffectsProjectFileUsageInAnimatedContentTemplateModel[];
}
