import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {StickyNoteModel} from '../../../../../../../models/api/sticky-note.model';
import {CustomWorkflowComponentModel} from '../../../../../../../models/api/custom-workflow-component.model';
import {CustomWorkflowActionModel} from '../../../../../../../models/api/custom-workflow-action.model';
import {DataFieldModel} from '../../../../../../../models/api/data-field.model';
import {StickyNotesDataService} from './sticky-notes-data.service';
import {takeUntil} from 'rxjs/operators';
import {combineLatest, Subject} from 'rxjs';
import {PublicationModel} from '../../../../../../../models/api/publication.model';
import {WorkflowConfigurationModel} from '../../../../../../../models/api/workflow-configuration.model';
import {CustomWorkflowStepModel} from '../../../../../../../models/api/custom-workflow-step.model';
import {CustomWorkflowService} from '../../custom-workflow.service';
import {CustomWorkflowFilterModel} from '../../../../../../../models/api/custom-workflow-filter.model';
import {CustomWorkflowFilterOptionModel} from '../../../../../../../models/api/custom-workflow-filter-option.model';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
    selector: 'preview-sticky-notes-sidebar',
    templateUrl: './preview-sticky-notes-sidebar.component.html',
    styleUrls: ['./preview-sticky-notes-sidebar.component.scss'],
    animations: [
        trigger('slideAnimation', [
            transition(':enter', [
                style({ transform: 'translateX(100%)' }),
                animate('0.2s 25ms ease-in', style({ transform: 'translateX(0)' }))
            ]),
            transition(':leave', [
                animate('0.2s cubic-bezier(0.22, 0.88, 0.49, 1.27)', style({ transform: 'translateX(100%)' }))
            ])
        ])
    ]
})
export class PreviewStickyNotesSidebarComponent implements OnInit, OnDestroy {
    public selectedStickyNote: StickyNoteModel;
    @Input() public publication: PublicationModel;
    @Input() public workflow: WorkflowConfigurationModel;
    @Input() public loading = false;
    @Input() public component: CustomWorkflowComponentModel;
    @Input() public actions: CustomWorkflowActionModel[];
    @Input() public campaignId: string;
    @Input() public campaignItemDataFields: DataFieldModel[];
    public step: CustomWorkflowStepModel;
    public activeFilters: Map<CustomWorkflowFilterModel, CustomWorkflowFilterOptionModel[]>;

    public hasNext: boolean = false;
    private onDestroySubject = new Subject<void>();

    constructor(private stickyNotesDataService: StickyNotesDataService,
                private customWorkflowService: CustomWorkflowService) {
    }

    ngOnInit(): void {
        combineLatest([
            this.customWorkflowService.activeStep$,
            this.customWorkflowService.activeFilters$,
            this.customWorkflowService.workflow$
        ]).pipe(
            takeUntil(this.onDestroySubject),
        ).subscribe(([
                         step,
                         filters,
                         workflow]) => {
            this.step = step;
            this.activeFilters = filters;
            this.workflow = workflow;
        });

        this.stickyNotesDataService.selectedStickyNote$.pipe(takeUntil((this.onDestroySubject)))
            .subscribe((selectedStickyNote: StickyNoteModel) => {
                this.selectedStickyNote = selectedStickyNote;
            });
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

}
