import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {EDataFieldTypes} from '../../app.enums';
import {PropertyValueModel} from '../../models/ui/property-value.model';
import {ERulesetPropertyType} from '../../models/api/rule-property.model';

export class PropertyOperator extends DropdownItem<string> {
    public static LENGTH = new PropertyOperator('Length', 'LENGTH', true);
    public static EVERY = new PropertyOperator('Every', 'EVERY', true);
    public static SOME = new PropertyOperator('Any', 'SOME', true);
    public static EXISTS = new PropertyOperator('Exists', 'EXISTS', false);
    public static NOT_EXISTS = new PropertyOperator('Not exists', 'NOT_EXISTS', false);

    public static OPERATORS = [
        PropertyOperator.LENGTH,
        PropertyOperator.SOME,
        PropertyOperator.EVERY,
        PropertyOperator.EXISTS,
        PropertyOperator.NOT_EXISTS
    ];

    public static getOperatorsForProperty(property?: PropertyValueModel): PropertyOperator[] {
        // When property is not an array, we set no operators
        // When property has no arrayIndexSelected we show all operators
        // When property is an entity and has arrayIndexSelected we show selected operators
        // When property is a field and has arrayIndexSelected we show no operators
        if (!property?.isArray) return [];

        if (property.type === ERulesetPropertyType.ENTITY) {
            return [PropertyOperator.LENGTH, PropertyOperator.EXISTS, PropertyOperator.NOT_EXISTS];
        } else {
            return PropertyOperator.OPERATORS;
        }
    }

    public static getByValue(value: string): PropertyOperator {
        return PropertyOperator.OPERATORS.find((operator) => operator.getValue() === value);
    }

    constructor(title: string, value: string, public readonly typeRequired: boolean) {
        super(title, value);
    }
}

export class ConditionsConstants {
    public static readonly DATA_TYPE_LENGTH = 'length';
}

export class ConditionType extends DropdownItem<string> {
    public static readonly EXISTS = new ConditionType('Exists', 'EXISTS', false);
    public static readonly NOT_EXISTS = new ConditionType('Not exists', 'NOT_EXISTS', false);
    public static readonly EQUALS = new ConditionType('Equals', 'EQUALS', true);
    public static readonly NOT_EQUALS = new ConditionType('Not equals', 'NOT_EQUALS', true);
    public static readonly GREATER_THAN = new ConditionType('Greater than', 'GREATER_THAN', true);
    public static readonly GREATER_OR_EQUAL = new ConditionType('Greater or equal', 'GREATER_OR_EQUAL', true);
    public static readonly LOWER_THAN = new ConditionType('Lower than', 'LOWER_THAN', true);
    public static readonly LOWER_OR_EQUAL = new ConditionType('Lower or equal', 'LOWER_OR_EQUAL', true);
    public static readonly LENGTH = new ConditionType('Length of', 'LENGTH', true);
    public static readonly LENGTH_GREATER_THAN = new ConditionType('Length greater than', 'LENGTH_GREATER_THAN', true);
    public static readonly LEADING_LENGTH = new ConditionType('Leading length of', 'LEADING_LENGTH', true);
    public static readonly LEADING_LENGTH_GREATER_THAN = new ConditionType('Leading length greater than', 'LEADING_LENGTH_GREATER_THAN', true);
    public static readonly STARTS_WITH = new ConditionType('Starts with', 'STARTS_WITH', true);
    public static readonly NOT_STARTS_WITH = new ConditionType('Does not start with', 'NOT_STARTS_WITH', true);

    public static TYPES = [
        ConditionType.EXISTS, ConditionType.NOT_EXISTS,
        ConditionType.EQUALS, ConditionType.NOT_EQUALS,
        ConditionType.GREATER_THAN, ConditionType.GREATER_OR_EQUAL,
        ConditionType.LOWER_THAN, ConditionType.LOWER_OR_EQUAL,
        ConditionType.LENGTH, ConditionType.LENGTH_GREATER_THAN,
        ConditionType.LEADING_LENGTH, ConditionType.LEADING_LENGTH_GREATER_THAN,
        ConditionType.STARTS_WITH, ConditionType.NOT_STARTS_WITH
    ];

    public static getTypesForProperty(property: string): ConditionType[] {
        switch (property) {
            case EDataFieldTypes.STRING:
                return [
                    ConditionType.EXISTS,
                    ConditionType.NOT_EXISTS,
                    ConditionType.EQUALS,
                    ConditionType.NOT_EQUALS,
                    ConditionType.STARTS_WITH,
                    ConditionType.NOT_STARTS_WITH
                ];
            case EDataFieldTypes.ENUM:
            case EDataFieldTypes.LIST:
                return [
                    ConditionType.EQUALS,
                    ConditionType.NOT_EQUALS,
                    ConditionType.STARTS_WITH,
                    ConditionType.NOT_STARTS_WITH
                ];
            case EDataFieldTypes.BOOLEAN:
                return [
                    ConditionType.EQUALS
                ];
            case ConditionsConstants.DATA_TYPE_LENGTH:
                return [
                    ConditionType.EQUALS,
                    ConditionType.NOT_EQUALS,
                    ConditionType.GREATER_THAN,
                    ConditionType.GREATER_OR_EQUAL,
                    ConditionType.LOWER_THAN,
                    ConditionType.LOWER_OR_EQUAL
                ];
            case EDataFieldTypes.DATE:
                return [
                    ConditionType.EXISTS,
                    ConditionType.NOT_EXISTS,
                    ConditionType.EQUALS,
                    ConditionType.NOT_EQUALS,
                    ConditionType.GREATER_THAN,
                    ConditionType.GREATER_OR_EQUAL,
                    ConditionType.LOWER_THAN,
                    ConditionType.LOWER_OR_EQUAL
                ];
            case EDataFieldTypes.NUMBER:
                return ConditionType.TYPES;
            case EDataFieldTypes.OBJECT_ID:
                return [
                    ConditionType.EXISTS,
                    ConditionType.NOT_EXISTS,
                    ConditionType.EQUALS,
                    ConditionType.NOT_EQUALS
                ];
            case EDataFieldTypes.OBJECT:
                return [
                    ConditionType.EXISTS,
                    ConditionType.NOT_EXISTS,
                ];
            default:
                return [];
        }
    }

    public static getByValue(value: string): ConditionType {
        return ConditionType.TYPES.find((type) => type.getValue() === value);
    }

    constructor(title: string, value: string, public readonly valueRequired: boolean) {
        super(title, value);
    }
}

export class BooleanOption extends DropdownItem<boolean> {
    public static TRUE = new BooleanOption('Yes', true);
    public static FALSE = new BooleanOption('No', false);

    public static OPTIONS = [
        BooleanOption.TRUE,
        BooleanOption.FALSE
    ];

    constructor(title: string, value: boolean) {
        super(title, value);
    }
}
