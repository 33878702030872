import {autoserialize, autoserializeAs} from 'cerialize';
import {ITableItem} from '@relayter/rubber-duck';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';

export class SortItemModel {
    @autoserialize public _id: string;
    @autoserialize public label: string
}

export class ProductAssetExportSetupModel implements ITableItem, IDropdownItem<string> {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserialize public description: string;
    @autoserializeAs(SortItemModel) public sortItems: SortItemModel[];
    @autoserialize public format: string;
    @autoserialize public scaleType: string;
    @autoserialize public width: number;
    @autoserialize public height: number;
    @autoserialize public createdAt: string;
    @autoserialize public updatedAt: string;

    getTitle(): string {
        return this.name;
    }
    getValue(): string {
        return this._id;
    }
}
