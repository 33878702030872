<div class="outer-list">
    <ng-container *ngFor="let conditionGroup of conditionGroups.groups.controls; let index = index; first as isFirst">
        <div class="dropdown-wrapper" *ngIf="!isFirst">
            <nuc-dropdown-badge
                [icon]="conditionGroups.controls.operator.value.getValue() === RULESET_OPERATORS.OR ? 'nucicon_code-or' : 'nucicon_code-and'"
                iconPosition="start"
                class="outer-operator"
                [value]="conditionGroups.operator.value"
                [formControl]="conditionGroups.operator"
                [badgeStyle]="conditionGroups.controls.operator.value.getValue() === RULESET_OPERATORS.OR ? BUTTON_TYPES.SECONDARY_UI : BUTTON_TYPES.SELECTED"
                [nullOption]="false"
                [items]="groupOptions" placeholder="Select an item">
            </nuc-dropdown-badge>
        </div>
        <div class="condition-group" [class.single]="conditionGroup.controls.rules?.controls.length === 1">
            <form [formGroup]="conditionGroup">
                <div cdkDropList class="condition-group-container"
                     id="{{conditionGroup.id}}"
                     (cdkDropListDropped)="dropItem($event)"
                     [cdkDropListData]="conditionGroup.controls.rules?.controls"
                     [cdkDropListConnectedTo]="getConnectedList()">
                    <div class="dropdown-wrapper first" *ngIf="conditionGroup.controls.rules?.controls.length > 1">
                        <h2>Group #{{index +1}}</h2>
                    </div>
                    <ng-container
                        *ngFor="let condition of conditionGroup.controls.rules?.controls; let index = index; first as isFirst">
                        <div class="dropdown-wrapper" *ngIf="!isFirst">
                            <nuc-dropdown-badge
                                *ngIf="conditionGroup.controls.rules?.controls.length > 1"
                                [icon]="conditionGroup.controls.operator.value.getValue() === RULESET_OPERATORS.OR ? 'nucicon_code-or' : 'nucicon_code-and'"
                                iconPosition="start"
                                [formControl]="conditionGroup.operator"
                                [badgeStyle]="conditionGroup.controls.operator.value.getValue() === RULESET_OPERATORS.OR ? BUTTON_TYPES.SECONDARY_UI : BUTTON_TYPES.SELECTED"
                                [nullOption]="false"
                                [value]="conditionGroup.operator.value"
                                [items]="groupOptions" placeholder="Select an item">
                            </nuc-dropdown-badge>
                        </div>
                        <conditions-form
                            class="form"
                            [formGroup]="condition"
                            [valueIndex]="index"
                            cdkDrag
                            (deleteClicked)="deleteConditionClicked(index, conditionGroup.id)"
                            [ruleProperties]="ruleProperties">
                            <nuc-button-secondary icon="nucicon_selector" cdkDragHandle (mousedown)="addNewContainer()">
                            </nuc-button-secondary>
                        </conditions-form>
                    </ng-container>
                </div>
            </form>
        </div>
    </ng-container>
</div>
