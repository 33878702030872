import {Component, DestroyRef, inject, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DropdownComponent, NUCComponentsModule, NUCInputsModule, NUCLabelModule} from '@relayter/rubber-duck';
import {VariantModel} from '../../../../../models/api/variant.model';
import {AfterEffectsProjectFileModel} from '../../../../../models/api/after-effects-project-file.model';
import {AnimatedContentRulesetModel} from '../../../../../models/api/animated-content-ruleset.model';
import {IDropdownRequestDataEvent} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Toaster} from '../../../../../classes/toaster.class';
import {AfterEffectsProjectFilesApiService} from '../../../../../api/services/after-effects-project-files.api.service';
import {AnimatedContentRulesetApiService} from '../../../../../api/services/animated-content-ruleset.api.service';

export interface AnimatedContentTemplateVariantFormGroup {
    variant: FormControl<VariantModel>;
    afterEffectsProjectFile: FormControl<AfterEffectsProjectFileModel>;
    animatedContentRuleset: FormControl<AnimatedContentRulesetModel>;
}

@Component({
  selector: 'animated-content-template-variant-preset-component',
  templateUrl: './animated-content-template-variant-preset.component.html',
  styleUrl: './animated-content-template-variant-preset.component.scss',
  imports: [
      FormsModule,
      NUCComponentsModule,
      NUCInputsModule,
      NUCLabelModule,
      ReactiveFormsModule
  ],
  standalone: true
})
export class AnimatedContentTemplateVariantPresetComponent implements OnInit {
    private afterEffectsProjectFilesApiService = inject(AfterEffectsProjectFilesApiService);
    private animatedContentRulesetApiService = inject(AnimatedContentRulesetApiService);
    private destroyRef: DestroyRef = inject(DestroyRef);

    @Input() public formGroup: FormGroup<AnimatedContentTemplateVariantFormGroup>;

    public selectedVariant: VariantModel;
    public selectedAfterEffectsProjectFile: AfterEffectsProjectFileModel;
    public selectedAnimatedContentRuleset: AnimatedContentRulesetModel;

    public afterEffectsProjectFiles: AfterEffectsProjectFileModel[] = [];
    public afterEffectsProjectFilesTotalItems = 0;
    public afterEffectsProjectFilesOffset = 0;

    public animatedContentRulesets: AnimatedContentRulesetModel[] = [];
    public animatedContentRulesetsOffset = 0;

    public ngOnInit(): void {
        this.initData();

        this.formGroup.controls.afterEffectsProjectFile.valueChanges
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((afterEffectsProjectFile) =>
                this.afterEffectsProjectFileChanged(afterEffectsProjectFile)
        );

        this.formGroup.controls.animatedContentRuleset.valueChanges
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((animatedContentRuleset) =>
                this.selectedAnimatedContentRuleset = animatedContentRuleset
            );
    }

    private initData(): void {
        this.selectedVariant = this.formGroup.controls.variant?.value;
        this.selectedAfterEffectsProjectFile = this.formGroup.controls.afterEffectsProjectFile.value;
        this.selectedAnimatedContentRuleset = this.formGroup.controls.animatedContentRuleset.value;

        this.getAfterEffectsProjectFilesWithSearch();
        if (this.selectedAfterEffectsProjectFile) {
            if (this.formGroup.controls.animatedContentRuleset.disabled) this.formGroup.controls.animatedContentRuleset.enable();
            this.getAnimatedContentRulesets();
        } else {
            this.formGroup.controls.animatedContentRuleset.disable();
        }
    }

    public requestAfterEffectsProjectFiles(event?: IDropdownRequestDataEvent): void {
        if (event.reset) {
            this.afterEffectsProjectFiles = [];
            this.afterEffectsProjectFilesTotalItems = 0;
            this.afterEffectsProjectFilesOffset = 0;
        }

        this.getAfterEffectsProjectFilesWithSearch(event.search);
    }

    public getAfterEffectsProjectFilesWithSearch(search?: string): void {
        this.afterEffectsProjectFilesApiService
            .find(DropdownComponent.DEFAULT_LIMIT, this.afterEffectsProjectFilesOffset, null, null, search)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result) => {
                    this.afterEffectsProjectFilesTotalItems = result.total;
                    this.afterEffectsProjectFilesOffset += result.items.length;

                    this.afterEffectsProjectFiles = this.afterEffectsProjectFiles
                        .filter(item => !result.items.some(newItem => newItem._id === item._id))
                        .concat(result.items);
                },
                error: Toaster.handleApiError
            });
    }

    public getAnimatedContentRulesets(): void {
        this.animatedContentRulesetApiService
            .getAnimatedContentRulesetsByAfterEffectsProjectFileId(
                this.selectedAfterEffectsProjectFile._id,
                DropdownComponent.DEFAULT_LIMIT,
                this.animatedContentRulesetsOffset
            )
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (animatedContentRulesets) => {
                    this.animatedContentRulesets = animatedContentRulesets;
                },
                error: Toaster.handleApiError
            })
    }

    public afterEffectsProjectFileChanged(afterEffectsProjectFile: AfterEffectsProjectFileModel) {
        this.selectedAfterEffectsProjectFile = afterEffectsProjectFile;
        this.animatedContentRulesets = [];

        const foundAfterEffectsProjectFiles =
            this.selectedAnimatedContentRuleset?.afterEffectsProjectFiles
                .find((afterEffectsProjectFile) =>
                    afterEffectsProjectFile._id === this.selectedAfterEffectsProjectFile?._id
                );
        if (!afterEffectsProjectFile || !foundAfterEffectsProjectFiles) {
            this.formGroup.patchValue({animatedContentRuleset: null});
        }

        if (this.selectedAfterEffectsProjectFile) {
            if (this.formGroup.controls.animatedContentRuleset.disabled) {
                this.formGroup.controls.animatedContentRuleset.enable({emitEvent: false});
            }
            this.getAnimatedContentRulesets();
        } else {
            this.formGroup.controls.animatedContentRuleset.disable({emitEvent: false});
        }
    }
}
