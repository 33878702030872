import {autoserialize, autoserializeAs} from 'cerialize';
import {ITableItem} from '../interfaces/table-item.interface';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';

export class PermissionModel implements ITableItem, IDropdownItem<string> {
    @autoserializeAs('key') public _id: string;
    @autoserialize public name: string;
    @autoserialize public description: string;
    @autoserialize public active: boolean;

    public getTitle(): string {
        return this.name;
    }

    public getValue(): string {
        return this._id;
    }
}
