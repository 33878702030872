<form [formGroup]="form">
    <div class="asset-container">
        <nuc-media-card-view [title]="asset.name"
                       size="EXTRA_LARGE"
                       [subTitle]="asset.rin"
                       [media]="asset | assetDisplay:EAssetDisplayProperties.THUMBNAIL_MEDIA">
        </nuc-media-card-view>
    </div>

    <hr>

    <upload-asset-component [previousUploadAsset]="formGroup?.get('uploadedAsset')?.value"
                            [fileTypeCategories]="fileTypeCategories"
                            (onUploadUpdate)="onUploadUpdate($event)">
    </upload-asset-component>
</form>
