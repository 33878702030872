<form [formGroup]="formGroup" *ngIf="formGroup">
    <nuc-form-field label="Master page name">
        <nuc-input formControlName="name" placeholder="Name of master page" required></nuc-input>
    </nuc-form-field>

    <nuc-form-field label="Channel">
        <nuc-dropdown formControlName="channel"
                      [items]="channels"
                      [total]="channels.length"
                      placeholder="Select a channel"
                      required>
        </nuc-dropdown>
    </nuc-form-field>

    <rl-upload-file-component
        title="Master page file"
        (onUploadUpdate)="uploadUpdateSubject.next($event)"
        [fileTypeCategories]="fileTypeCategories">
    </rl-upload-file-component>
</form>
