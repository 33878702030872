import {Component, Input, OnInit} from '@angular/core';
import {SortDirection} from '@angular/material/sort';
import {FormControl, FormGroup} from '@angular/forms';
import {AssetModel} from '../../../models/api/asset.model';
import {AppConstants} from '../../../app.constants';
import {TableActionTypeModel} from '../../../models/ui/table-action-type.model';
import {EAssetDisplayProperties} from '../../../pipes/asset-display.pipe';
import {ProductForm} from '../product-form.component';

@Component({
    selector: 'product-assets-form',
    templateUrl: 'product-assets-form.component.html',
    styleUrls: ['product-assets-form.component.scss']
})
export class ProductAssetsFormComponent implements OnInit {
    public readonly PAGE_INDEX_DEFAULT: number = AppConstants.PAGE_INDEX_DEFAULT;

    @Input() public form: FormGroup<ProductForm>;
    @Input() assets: AssetModel[];

    public actions: TableActionTypeModel[] = [AppConstants.TABLE_ACTION_TYPES.ADD];
    public total: number;
    public pageIndex = AppConstants.PAGE_INDEX_DEFAULT;
    public pageSize = AppConstants.PAGE_SIZE_DEFAULT;
    public sortProperty = 'name';
    public sortOrder: SortDirection = 'asc';
    public items: AssetModel[];

    public EAssetDisplayProperties = EAssetDisplayProperties;

    public isLinkAssetsCollapsed = true;

    public ngOnInit(): void {
        this.initForm();
    }

    private initForm(): void {
        this.form.contains('assets') ?
            this.assets = this.form.value.assets :
            this.form.addControl('assets', new FormControl<AssetModel[]>(this.assets));
    }

    public linkAsset(assetToLink: AssetModel): void {
        if (!this.assets.find((asset) => asset._id === assetToLink._id)) {
            this.assets.push(assetToLink);
        }
    }

    public setAssetPrimary(primaryAsset: AssetModel): void {
        const index = this.assets.indexOf(primaryAsset);
        if (index !== -1) {
            this.assets.unshift(this.assets.splice(index, 1)[0]);
        }
    }

    public unlinkAsset(assetToUnlink: AssetModel): void {
        const index = this.assets.indexOf(assetToUnlink);
        if (index !== -1) {
            this.assets.splice(index, 1);
        }
    }
}
