import {Component, DestroyRef, inject, Inject, OnInit} from '@angular/core';
import {switchMap} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel, FullModalService, NUC_FULL_MODAL_DATA} from '@relayter/rubber-duck';
import {RLValidatorConstants} from '../../classes/validators/rl-validators.constant';
import {Toaster} from '../../classes/toaster.class';
import {WorkflowConfigurationModel} from '../../models/api/workflow-configuration.model';
import {LayoutModel} from '../../models/api/layout.model';
import {LayoutsService} from '../../api/services/layouts.service';
import {WorkflowConfigurationsService} from '../../api/services/workflow-configurations.service';
import {ModelUtil} from '../../classes/model.util';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
    CustomWorkflowIdentifierSettingFragmentModel,
    CustomWorkflowIdentifierSettingModel,
    EIdentifierSettingFragmentType
} from '../../models/api/custom-workflow-identifier-setting.model';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {RulePropertyModel} from '../../models/api/rule-property.model';
import {EPropertyContext, PropertyService} from '../../api/services/property.service';
import {FormArrayMinLengthValidator} from '../../classes/validators/form-array-min-length.validator';
import {EFormStatus} from '../../app.enums';

export interface IWorkflowConfigurationFormData {
    workflowConfiguration?: WorkflowConfigurationModel;
}

class WorkflowConfigurationFormGroup {
    name: FormControl<string>;
    layout: FormControl<LayoutModel>;
    identifierSetting: FormGroup<IdentifierSettingFormGroup>;
}
class IdentifierSettingFormGroup {
    join: FormControl<string>;
    sequence: FormControl<boolean>;
    fragments: FormArray<FormGroup<FragmentsFormGroup>>;
}

class FragmentsFormGroup {
    type: FormControl<DropdownItem<string>>;
    fixedValue?: FormControl<string>;
    property?: FormControl<RulePropertyModel>;
}

@Component({
    selector: 'workflow-configuration-form-component',
    templateUrl: 'workflow-configuration-form.component.html',
    styleUrls: ['workflow-configuration-form.component.scss']
})
export class WorkflowConfigurationFormComponent implements OnInit {
    private destroyRef = inject(DestroyRef);

    public formGroup: FormGroup<WorkflowConfigurationFormGroup>;
    public identifierSettingFormGroup: FormGroup<IdentifierSettingFormGroup>;

    private saveButton: ButtonConfig;

    public workflowConfiguration: WorkflowConfigurationModel;
    public identifierSetting: CustomWorkflowIdentifierSettingModel;
    public layouts: LayoutModel[];
    public fragmentTypeOptions: DropdownItem<string>[]
        = Object.keys(EIdentifierSettingFragmentType).map((type) => new DropdownItem(type, type));
    public allowedProperties: RulePropertyModel[];

    constructor(private fullModalService: FullModalService,
                private workflowConfigurationService: WorkflowConfigurationsService,
                private layoutService: LayoutsService,
                private propertyService: PropertyService,
                @Inject(NUC_FULL_MODAL_DATA) public modalData: IWorkflowConfigurationFormData) {
    }

    public ngOnInit(): void {
        this.initData();
        this.initModalButtons();
    }

    private initModalButtons(): void {
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        this.saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', null, false, false);

        const cancelAction = new FullModalActionModel(cancelButton);
        const saveAction = new FullModalActionModel(this.saveButton);

        cancelAction.observable.subscribe(() => this.fullModalService.close(false, true));
        saveAction.observable.subscribe(() => this.saveWorkflowConfiguration());
        this.fullModalService.setModalActions([cancelAction, saveAction]);
    }

    private initData(): void {
        this.workflowConfiguration = this.modalData.workflowConfiguration || new WorkflowConfigurationModel();

        this.layoutService.find()
        .pipe(
            switchMap((layoutsResult) => {
                this.layouts = layoutsResult.items;

                this.identifierSetting = this.workflowConfiguration.identifierSetting || new CustomWorkflowIdentifierSettingModel('-', true);

                return this.propertyService.getProperties(EPropertyContext.WORKFLOW_IDENTIFIER_SETTING);
            }),
            takeUntilDestroyed(this.destroyRef)
        )
        .subscribe({
            next: (properties) => {
                this.allowedProperties = properties.items;

                this.initForm();
            },
            error: Toaster.handleApiError
        });
    }

    private initForm(): void {
        this.identifierSettingFormGroup = new FormGroup<IdentifierSettingFormGroup>({
            join: new FormControl(this.identifierSetting.join, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
            sequence: new FormControl(this.identifierSetting.sequence),
            fragments: new FormArray<FormGroup<FragmentsFormGroup>>((this.identifierSetting.fragments).map(fragment => {
                const foundType = this.fragmentTypeOptions.find((type) => fragment.type === type.getValue());

                const formGroup = new FormGroup<FragmentsFormGroup>({
                    type: new FormControl(foundType, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
                });

                if (foundType.getValue() === EIdentifierSettingFragmentType.FIXED) {
                    formGroup.addControl('fixedValue', new FormControl(fragment.fixedValue));
                }

                if (foundType.getValue() === EIdentifierSettingFragmentType.DERIVED) {
                    const selectedProperty = this.allowedProperties.find((prop) => fragment.property === prop.key);
                    formGroup.addControl(
                        'property',
                        new FormControl<RulePropertyModel>(selectedProperty, RLValidatorConstants.VALIDATOR_SETS.REQUIRED)
                    );
                }
                return formGroup;
            }), FormArrayMinLengthValidator(1))
        });

        this.formGroup = new FormGroup<WorkflowConfigurationFormGroup>({
            name: new FormControl(this.workflowConfiguration.name, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
            layout: new FormControl(this.workflowConfiguration.layout),
            identifierSetting: this.identifierSettingFormGroup
        });

        this.formGroup.statusChanges.pipe(
            distinctUntilChanged(),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((status: string) => this.saveButton.disabled = status !== EFormStatus.VALID);
    }

    private saveWorkflowConfiguration(): void {
        const identifierSetting = {
            join: this.identifierSettingFormGroup.value.join,
            sequence: this.identifierSettingFormGroup.value.sequence,
            fragments: this.identifierSettingFormGroup.value.fragments.map((fragmentGroup: Record<string, any>) => {
                const type = fragmentGroup.type.getValue();
                const fragment: CustomWorkflowIdentifierSettingFragmentModel = {type};
                if (type === EIdentifierSettingFragmentType.FIXED) {
                    fragment.fixedValue = fragmentGroup.fixedValue;
                }
                if (type === EIdentifierSettingFragmentType.DERIVED) {
                    fragment.property = fragmentGroup.property.getValue();
                }
                return fragment;
            })
        };

        const workflow = ModelUtil.createApiBody({
            name: this.formGroup.value.name,
            layout: this.formGroup.value.layout?.getValue(),
            identifierSetting
        }, this.workflowConfiguration._id);

        if (this.workflowConfiguration._id) {
            this.workflowConfigurationService.patch(this.workflowConfiguration._id, workflow)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: workflowConfiguration => {
                        this.fullModalService.close(workflowConfiguration);
                        Toaster.success('Workflow configuration updated successfully');
                    },
                    error: Toaster.handleApiError
                });
        } else {
            this.workflowConfigurationService.create(workflow)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: workflowConfiguration => {
                        this.fullModalService.close(workflowConfiguration);
                        Toaster.success('Workflow configuration created successfully');
                    },
                    error: Toaster.handleApiError
                });
        }
    }

    public addFragment(): void {
        const formArray = this.identifierSettingFormGroup.controls.fragments;
        formArray.push(new FormGroup<FragmentsFormGroup>({
            type: new FormControl(null, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
        }));
    }

    public deleteFragment(index: number): void {
        const formArray = this.identifierSettingFormGroup.controls.fragments;
        if (formArray.controls[index]) formArray.removeAt(index);
    }
}
