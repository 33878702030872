import {autoserialize, autoserializeAs} from 'cerialize';
import {ITableItem} from '@relayter/rubber-duck';
import {FileModel} from './file.model';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {v4} from 'uuid';
import {AppConstants} from '../../app.constants';
import {ITagValueSelection} from '../../forms/rule-form/base-ruleset-item-form.component';

export enum EAfterEffectsLayerType {
    AV_LAYER = 'AVLayer',
    CAMERA_LAYER = 'CameraLayer',
    LIGHT_LAYER = 'LightLayer',
    SHAPE_LAYER = 'ShapeLayer',
    THREEDMODEL_LAYER = 'ThreeDModelLayer',
    FILE_SOURCE = 'FileSource',
    FOOTAGE_SOURCE = 'FootageSource',
    PLACEHOLDER_SOURCE = 'PlaceholderSource',
    TEXT_LAYER = 'TextLayer',
    AUDIO_LAYER = 'AudioLayer',
    SOLID_SOURCE = 'SolidSource'
}

export const EAfterEffectsLayerTypes: Record<EAfterEffectsLayerType, {name: string}> = {
    [EAfterEffectsLayerType.AV_LAYER]: {name: 'Composition'},
    [EAfterEffectsLayerType.CAMERA_LAYER]: {name: 'Camera'},
    [EAfterEffectsLayerType.LIGHT_LAYER]: {name: 'Light'},
    [EAfterEffectsLayerType.SHAPE_LAYER]: {name: 'Shape'},
    [EAfterEffectsLayerType.THREEDMODEL_LAYER]: {name: 'Maxon Cinema 4D'},
    [EAfterEffectsLayerType.FILE_SOURCE]: {name: 'File'},
    [EAfterEffectsLayerType.FOOTAGE_SOURCE]: {name: 'Footage'},
    [EAfterEffectsLayerType.PLACEHOLDER_SOURCE]: {name: 'Placeholder'},
    [EAfterEffectsLayerType.TEXT_LAYER]: {name: 'Text'},
    [EAfterEffectsLayerType.AUDIO_LAYER]: {name: 'Audio'},
    [EAfterEffectsLayerType.SOLID_SOURCE]: {name: 'Solid'}
}

export class AfterEffectsProjectFileFilesModel {
    @autoserializeAs(FileModel) public source: FileModel;
}

export class AfterEffectsProjectFileCompositionFilesSchema {
    @autoserializeAs(FileModel) public preview: FileModel;
}

export class AfterEffectsProjectFileCompositionLayerModel implements ITableItem {
    @autoserialize public _id: string = v4();
    @autoserialize public name: string;
    @autoserialize public type: EAfterEffectsLayerType;

    get thumbnailType() {
        switch (this.type) {
            case EAfterEffectsLayerType.AV_LAYER:
                return AppConstants.ICONS.AFTER_EFFECTS_AV;
            case EAfterEffectsLayerType.CAMERA_LAYER:
                return AppConstants.ICONS.AFTER_EFFECTS_CAMERA;
            case EAfterEffectsLayerType.LIGHT_LAYER:
                return AppConstants.ICONS.AFTER_EFFECTS_LIGHT;
            case EAfterEffectsLayerType.SHAPE_LAYER:
                return AppConstants.ICONS.AFTER_EFFECTS_SHAPE;
            case EAfterEffectsLayerType.THREEDMODEL_LAYER:
                return AppConstants.ICONS.AFTER_EFFECTS_3D;
            case EAfterEffectsLayerType.FILE_SOURCE:
                return AppConstants.ICONS.AFTER_EFFECTS_FILE;
            case EAfterEffectsLayerType.FOOTAGE_SOURCE:
                return AppConstants.ICONS.AFTER_EFFECTS_FOOTAGE;
            case EAfterEffectsLayerType.PLACEHOLDER_SOURCE:
                return AppConstants.ICONS.AFTER_EFFECTS_PLACEHOLDER;
            case EAfterEffectsLayerType.TEXT_LAYER:
                return AppConstants.ICONS.AFTER_EFFECTS_TEXT;
            case EAfterEffectsLayerType.AUDIO_LAYER:
                return AppConstants.ICONS.AFTER_EFFECTS_AUDIO;
            case EAfterEffectsLayerType.SOLID_SOURCE:
                return AppConstants.ICONS.AFTER_EFFECTS_SOLID;
            default:
                return AppConstants.ICONS.IMAGE_MAIN;
        }
    }
}

export class AfterEffectsProjectFileCompositionModel implements IDropdownItem, ITagValueSelection {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserialize public duration: number;
    @autoserialize public frameDuration: number;
    @autoserializeAs(AfterEffectsProjectFileCompositionFilesSchema) public files: AfterEffectsProjectFileCompositionFilesSchema;
    @autoserializeAs(AfterEffectsProjectFileCompositionLayerModel) public layers: AfterEffectsProjectFileCompositionLayerModel[];

    public getTitle(): string {
        return this.name;
    }

    public getValue(): string {
        return this._id;
    }

    public get tags(): string[] {
        return this.layers
            .filter((layer) => layer.type === EAfterEffectsLayerType.TEXT_LAYER)
            .map((layer) => layer.name);
    }

    public get assetTags(): string[] {
        return this.layers
            .filter((layer) => layer.type !== EAfterEffectsLayerType.TEXT_LAYER)
            .map((layer) => layer.name);
    }
}

export class AfterEffectsProjectFileModel implements ITableItem, IDropdownItem {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserializeAs(AfterEffectsProjectFileFilesModel) public files: AfterEffectsProjectFileFilesModel;
    @autoserializeAs(AfterEffectsProjectFileCompositionModel) public compositions: AfterEffectsProjectFileCompositionModel[];
    @autoserialize public createdAt: string;
    @autoserialize public updatedAt: string;

    public getTitle(): string {
        return this.name;
    }

    public getValue(): string {
        return this._id;
    }
}
