import {autoserialize, autoserializeAs} from 'cerialize';
import {EJobStatus} from './job.model';
import {UserModel} from './user.model';

export enum ENotificationActionType {
    DOWNLOAD = 'DOWNLOAD',
    NAVIGATE = 'NAVIGATE',
}

export enum ENotificationType {
    DOWNLOAD = 'DOWNLOAD',
    HTML = 'HTML'
}

export enum ENotificationStatus {
    READ = 'READ',
    UNREAD = 'UNREAD',
    ARCHIVED = 'ARCHIVED'
}

export class NotificationActionModel {
    @autoserialize title: string;
    @autoserialize type: ENotificationActionType;
    @autoserialize url: string;
}

export class NotificationDataModel {
    @autoserialize public job: string;
    @autoserialize public jobStatus: EJobStatus;
    @autoserialize public details: string[];
}

export class NotificationModel {
    @autoserialize public _id: string;
    @autoserialize public title: string;
    @autoserialize public message: string;
    @autoserialize public type: ENotificationType;
    @autoserialize public status: ENotificationStatus;
    @autoserializeAs(UserModel) public sender: UserModel;
    @autoserializeAs(NotificationDataModel) public data: NotificationDataModel;
    @autoserializeAs(NotificationActionModel) public actions: NotificationActionModel[];
    @autoserializeAs(Date) public createdAt: Date;
    @autoserializeAs(Date) public updatedAt: Date;
    @autoserializeAs(Date) public expires: Date;
}
