<form [formGroup]="form">
    <nuc-form-field label="Name">
        <nuc-input formControlName="name"
                   placeholder="Enter field name"
                   type="string"
                   required>
        </nuc-input>
        <nuc-error *ngIf="form.get('name').invalid &&
                  form.get('name').dirty &&
                  form.get('name').hasError('pattern')">
            {{VALIDATOR_MESSAGES.DATA_FIELD_NAME}}
        </nuc-error>
        <nuc-error *ngIf="form.get('name').invalid &&
                  form.get('name').dirty &&
                  form.get('name').hasError('duplicateValues')">
            {{VALIDATOR_MESSAGES.UNIQUE}}
        </nuc-error>
    </nuc-form-field>

    <nuc-form-field *ngIf="form.controls.type" label="Value type">
        <nuc-dropdown formControlName="type"
                      [items]="dataTypes"
                      [nullOption]="false"
                      [total]="dataTypes.length"
                      placeholder="Choose the value type">
        </nuc-dropdown>
    </nuc-form-field>

    <nuc-form-field *ngIf="form.controls.property" label="Campaign data">
        <nuc-dropdown formControlName="property"
                      [items]="propertyOptions"
                      [searchable]="true"
                      (requestData)="searchProperties($event)"
                      [total]="properties.length"
                      placeholder="Choose the property">
        </nuc-dropdown>
    </nuc-form-field>

    <nuc-form-field *ngIf="form.controls.variant" label="Variant">
        <nuc-dropdown formControlName="variant"
                      [nullOption]="false"
                      [items]="variants"
                      [total]="variants.length"
                      placeholder="Choose the variant">
        </nuc-dropdown>
    </nuc-form-field>
</form>
