<form class="fragment-form" [formGroup]="formGroup">
    <nuc-form-field label="Type">
        <nuc-dropdown formControlName="type"
                      placeholder="Select a type"
                      [nullOption]="false"
                      [total]="receiverOptions.length"
                      [items]="receiverOptions"
                      [required]="true">
        </nuc-dropdown>
        <nuc-error *ngIf="formGroup.get('type').invalid
                    && formGroup.get('type').dirty
                    && formGroup.get('type').hasError('duplicateValues')">
            {{VALIDATOR_MESSAGES.UNIQUE}}
        </nuc-error>
    </nuc-form-field>

    <nuc-form-field *ngIf="formGroup.controls.status" label="Status">
        <nuc-dropdown formControlName="status"
                      placeholder="Select a status"
                      [nullOption]="false"
                      [total]="noteStatuses.length"
                      [items]="noteStatuses">
        </nuc-dropdown>
        <nuc-error *ngIf="formGroup.get('status').invalid
                    && formGroup.get('status').dirty
                    && formGroup.get('status').hasError('duplicateValues')">
            {{VALIDATOR_MESSAGES.UNIQUE}}
        </nuc-error>
    </nuc-form-field>

    <nuc-form-field *ngIf="formGroup.controls.users" label="Choose users">
        <nuc-dropdown-multiselect formControlName="users"
                                  [items]="users"
                                  placeholder="Choose users">
        </nuc-dropdown-multiselect>
    </nuc-form-field>
</form>
