import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ARApiError} from '@relayter/core';
import {RoleModel} from '../../models/api/role.model';
import {RLValidatorConstants} from '../../classes/validators/rl-validators.constant';
import {RolesService} from '../../api/services/roles.service';
import {Toaster} from '../../classes/toaster.class';
import {distinctUntilChanged, map, finalize} from 'rxjs/operators';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel, FullModalService, NUC_FULL_MODAL_DATA} from '@relayter/rubber-duck';
import {EFormStatus} from '../../app.enums';

export interface IRoleFormModalData {
    role: RoleModel;
}

@Component({
    selector: 'rl-role-name-form-component',
    templateUrl: 'role-name-form.component.html',
    styleUrls: ['role-name-form.component.scss']
})

export class RoleNameFormComponent implements OnInit {
    public form: UntypedFormGroup;
    public validationMessages = {
        name: RLValidatorConstants.MESSAGE_SETS.REQUIRED
    };

    private saveButton: ButtonConfig;
    private readonly role: RoleModel;

    constructor(private fb: UntypedFormBuilder,
                private rolesService: RolesService,
                @Inject(NUC_FULL_MODAL_DATA) modalData: IRoleFormModalData,
                private fullModalService: FullModalService) {
        if (modalData) {
            this.role = modalData.role;
        }
    }

    public ngOnInit(): void {
        const saveButtonText = this.role ? 'Edit' : 'Save';
        this.saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, saveButtonText, null, null, true);
        const saveAction = new FullModalActionModel(this.saveButton);
        const cancelAction = new FullModalActionModel(new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel'));

        cancelAction.observable.subscribe(() => this.fullModalService.close(null, true));
        saveAction.observable.subscribe(() => this.role ? this.putRole() : this.postRole());
        this.fullModalService.setModalActions([cancelAction, saveAction]);

        this.form = this.fb.group({
            name: [this.role ? this.role.name : '', RLValidatorConstants.VALIDATOR_SETS.REQUIRED]
        });

        this.form.statusChanges.pipe(
            map((status) => status === EFormStatus.VALID),
            distinctUntilChanged()
        ).subscribe((valid) => this.saveButton.disabled = !valid);
    }

    private postRole(): void {
        this.saveButton.loading = true;
        const role = new RoleModel();
        role.name = this.form.value.name;

        this.rolesService.postNewRole(role)
            .pipe(finalize(() => this.saveButton.loading = false))
            .subscribe(
            (res: RoleModel) => {
                Toaster.success(`Successfully created ${res.name} role`);
                this.fullModalService.close(true);
            },
            (err: ARApiError) => {
                Toaster.handleApiError(err);
                this.fullModalService.close(false);
            });
    }

    private putRole(): void {
        this.saveButton.loading = true;
        this.role.name = this.form.value.name;

        this.rolesService.patchRole(this.role._id, {name: this.role.name} as RoleModel)
            .pipe(finalize(() => this.saveButton.loading = false))
            .subscribe(
            (res: RoleModel) => {
                Toaster.success(`Successfully edited ${res.name} role`);
                this.fullModalService.close(true);
            },
            (err: ARApiError) => {
                Toaster.handleApiError(err);
                this.fullModalService.close(false);
            });
    }
}
