<form *ngIf="form" [formGroup]="form">
    <nuc-form-field label="InDesign library name">
        <nuc-input formControlName="name" placeholder="InDesign library name" required />
    </nuc-form-field>

    <nuc-form-field label="Fonts">
        <nuc-dropdown-multiselect formControlName="fonts"
                                  placeholder="Select fonts used in this library"
                                  [items]="fonts"
                                  [total]="fontsTotalItems"
                                  (requestData)="requestFonts($event)"
                                  [required]="false">
        </nuc-dropdown-multiselect>
    </nuc-form-field>

    <nuc-form-field label="Library engine type">
        <nuc-dropdown
            formControlName="engineType"
            placeholder="Select a library engine type"
            [nullOption]="false"
            [items]="engineTypes"
            [required]="true">
        </nuc-dropdown>
    </nuc-form-field>

    <rl-upload-file-component
        title="Library file"
        (onUploadUpdate)="uploadUpdateSubject.next($event)"
        [fileTypeCategories]="fileTypeCategories">
    </rl-upload-file-component>
</form>
