<div class="group" *ngFor="let dataType of dataTypes" [formGroup]="packageDataFormGroup">
    <p class="body-strong">{{dataType.name}}</p>
    <p>{{dataType.description}}</p>

    <div class="form-array" [formArrayName]="dataType.formArrayName">
        <div class="sub-form" *ngFor="let subFormGroup of packageDataFormGroup.get(dataType.formArrayName)['controls']; index as index">
            <package-setup-package-data-item-form-component
                [form]="subFormGroup"
                [properties]="properties"
                [dataTypes]="materialDataTypes"
                [variants]="variants">
            </package-setup-package-data-item-form-component>

            <div class="button">
                <div></div>  <!-- placeholder to align vertical position -->
                <nuc-button-secondary icon="nucicon_trash_fill"
                                      (click)="onDeleteFieldClicked(dataType.formArrayName, index)">
                </nuc-button-secondary>
            </div>

        </div>
    </div>

    <div class="add-button">
        <nuc-button-secondary text="Add field" icon="nucicon_add" iconPosition="end" (click)="onAddFieldClicked(dataType.formArrayName)">
        </nuc-button-secondary>
    </div>
</div>

