<form *ngIf="formGroup; else error" [formGroup]="formGroup">
    <nuc-form-field label="Field" class="column property-control">
        <property-control formControlName="property"
                          [ignoreIndex]="propertyControlOptions.ignoreIndex"
                          [variants]="variants"
                          [variantRequired]="propertyControlOptions.variantRequired"
                          [fieldRequired]="propertyControlOptions.fieldRequired"
                          [controlType]="EControlType.CONDITION"
                          [ruleProperties]="ruleProperties"/>
    </nuc-form-field>

    <nuc-form-field *ngIf="operators.length" label="Operator" class="column">
        <nuc-dropdown [items]="operators" [total]="operators.length" formControlName="operator" placeholder="Choose the operator" />
    </nuc-form-field>

    <nuc-form-field *ngIf="conditionTypes?.length" label="Type" class="column">
        <nuc-dropdown [items]="conditionTypes" [total]="conditionTypes.length" formControlName="type" placeholder="Choose type" />
    </nuc-form-field>

    <nuc-form-field *ngIf="inputType" [ngSwitch]="inputType" label="Value" class="column">
        <nuc-input *ngSwitchCase="'number'" formControlName="value" placeholder="Define the number value"
                   type="number"></nuc-input>
        <nuc-input *ngSwitchCase="'string'" formControlName="value" placeholder="Define the string value"
                   type="string"></nuc-input>
        <nuc-dropdown *ngSwitchCase="'dropdown'"
                      [items]="dropdownItems"
                      [total]="dropdownItems.length"
                      formControlName="value"
                      placeholder="Select option">
        </nuc-dropdown>
        <nuc-datepicker *ngSwitchCase="'date'" formControlName="value"></nuc-datepicker>
    </nuc-form-field>
</form>

<nuc-button-secondary icon="nucicon_trash_fill" (click)="deleteClicked.emit()"></nuc-button-secondary>

<ng-template #error>
    <p>Please pass a FormGroup as Input</p>
</ng-template>
