<div class="container">
    <nuc-table
        [tableId]="tableId"
        [items]="transitionItemsData"
        [columns]="columns"
        [prefixStorageKey]="storageKey"
        (sortOptionChange)="onSortOptionChanged($event)"
        [loading]="!transitionItemsDataSubscription?.closed">

        <paginator button-bar-right
                   [viewId]="tableId"
                   [loading]="!transitionItemsDataSubscription?.closed"
                   [disableNextPage]="disableNextPage">
        </paginator>
    </nuc-table>
</div>
