<div class="sticky-notes-container">
    @for (stackedNote of stackedNotes(); track stackedNote) {
        <div class="sticky" (click)="onStickyNoteClicked($event, stackedNote.stickyNotes[0])"
             [ngClass]="{multi: stackedNote.count > 1, selected: stackedNote.start === this.currentTime()}"
             [ngStyle]="{'left.%': stackedNote.startPosition}">
            <i class="nucicon_timeline---note pin" [ngClass]="{multi: stackedNote.count > 1}"></i>
            @if (stackedNote.count > 1) {
                <span class="count body-strong">{{stackedNote.count}}</span>
            }
        </div>
    }
</div>
