<div class="video-sticky-component">
        <div class="video-container" #videoContainer
             cdkDrag (cdkDragStarted)="startDragging()" (cdkDragReleased)="stopDragging()">
            <div class="sizer" #sizer>
                <video #videoPlayer preload="metadata" (volumechange)="volumeChanged($event)" [disableRemotePlayback]="true"
                       (click)="playPauseVideo()" (timeupdate)="timeUpdate()" (loadedmetadata)="metaDataLoaded()" width="100%"
                       (progress)="bufferHandler($event)">
                    <source [src]="src" type="video/mp4"> <!--  for now only mp4 type-->
                </video>
                <div class="note-canvas" #noteCanvas>
                    <sticky-note-overlay [overlay]="overlay()"
                        [item]="item" [notes]="visibleNotes()" (createStickyNote)="newStickyNote($event)"></sticky-note-overlay>
                </div>
        </div>
    </div>
    <div class="controls-container">
        <div class="buttons-container">
            <div class="left">
                <nuc-button-secondary [round]="true" [icon]="playPauseIcon()" (click)="playPauseVideo()"></nuc-button-secondary>
                <div class="video-time">
                    <span class="current-time">{{currentTime() | durationMedia}}</span>
                    &#160; &bull; &#160;
                    <span class="duration">{{duration | durationMedia}}</span>
                </div>
            </div>
            <div class="right">
                <nuc-button-secondary [round]="true" [icon]="volumeIcon()" (click)="toggleAudio()" nucVolumeControl
                                      (volumeChanged)="setVolume($event)" [volumeLevel]="volumeLevel"/>
                <nuc-button-secondary [round]="true" icon="nucicon_fullscreen" (click)="fullScreen()"/>
            </div>
        </div>
        <div class="progress-bar">
            <sticky-note-timeline [item]="item" [notes]="stickyNotes" [duration]="duration" [currentTime]="currentTime()"/>
            <nuc-seekbar [duration]="duration" [currentTime]="currentTime()" (seekVideo)="seekVideo($event)" [bufferedTime]="bufferedTime()"/>
        </div>
    </div>
</div>
