import {Component, Inject, OnDestroy, OnInit, Optional} from '@angular/core';
import {FormArray, FormGroup} from '@angular/forms';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel, FullModalService, NUC_FULL_MODAL_DATA} from '@relayter/rubber-duck';
import {forkJoin, Subject, Subscription} from 'rxjs';
import {TabBarItemModel} from '../../models/ui/tab-bar-item.model';
import {Toaster} from '../../classes/toaster.class';
import {PackageRuleModel, PackageSetupModel} from '../../models/api/package-setup.model';
import {PackageSetupService} from '../../api/services/package-setups.service';
import {RulePropertyModel} from '../../models/api/rule-property.model';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {finalize, takeUntil} from 'rxjs/operators';
import {EPropertyContext, PropertyService} from '../../api/services/property.service';
import {EDataFieldTypes} from '../../app.enums';
import {RuleConditionModel} from '../../models/api/rule-condition.model';
import {VariantModel} from '../../models/api/variant.model';
import {VariantService} from '../../api/services/variant.service';
import {ConditionForm} from '../rule-form/condition-group-form/condition-group-form.component';

export interface IPackageRuleFormData {
    packageSetup: PackageSetupModel;
    packageRule: PackageRuleModel;
}

export enum ETabFormGroup {
    PACKAGE_RULE_INFORMATION = 'packageRule',
    PACKAGE_RULE_CONDITIONS = 'conditions',
    PACKAGE_RULE_MATERIAL_DATA = 'materialData'
}

class PackageRuleForm {
    [ETabFormGroup.PACKAGE_RULE_INFORMATION]?: FormGroup;
    [ETabFormGroup.PACKAGE_RULE_CONDITIONS]?: FormArray<FormGroup<ConditionForm>>;
    [ETabFormGroup.PACKAGE_RULE_MATERIAL_DATA]?: FormGroup;
}

@Component({
    selector: 'package-rule-form-component',
    templateUrl: 'package-rule-form.component.html',
    styleUrls: ['package-rule-form.component.scss']
})

export class PackageRuleFormComponent implements OnInit, OnDestroy {
    public TAB_INFORMATION = 0;
    public TAB_CONDITIONS = 1;
    public TAB_MATERIAL_DATA = 2;

    public informationTab = new TabBarItemModel('Rule description', this.TAB_INFORMATION);
    public conditionsTab = new TabBarItemModel('Conditions', this.TAB_CONDITIONS);
    public materialDataTab = new TabBarItemModel('Material data', this.TAB_MATERIAL_DATA);
    public tabbarItems: TabBarItemModel[] = [
        this.informationTab,
        this.conditionsTab,
        this.materialDataTab];
    private _selectedTab = this.tabbarItems[this.TAB_INFORMATION];
    private nextButton: ButtonConfig;

    public get selectedTab(): TabBarItemModel {
        return this._selectedTab;
    }

    public set selectedTab(tab: TabBarItemModel) {
        if (tab !== this._selectedTab) {
            const index = this.tabbarItems.find((t) => t.title === tab.title).index;
            this._selectedTab = tab;
            this._selectedTab.index = index;
            this.setConfirmButton();
        }
    }

    private nextTabAction(): void {
        this.selectedTab = this.tabbarItems.find((tab) => tab.index === this.selectedTab.index + 1);
    }

    public form: FormGroup<PackageRuleForm> = new FormGroup<PackageRuleForm>({});

    private confirmAction: FullModalActionModel;
    private actions: FullModalActionModel[];

    /**
     * Responder to modal confirm button click. Initialized with 'go to next tab'
     */
    private onConfirmClicked = this.nextTabAction;

    public packageRule: PackageRuleModel;

    public subscription: Subscription;
    public ruleProperties: RulePropertyModel[] = [];
    public variants: VariantModel[] = [];
    public onDestroySubject = new Subject<void>();

    constructor(private fullModalService: FullModalService,
                private packageSetupService: PackageSetupService,
                private propertyService: PropertyService,
                private variantService: VariantService,
                @Optional() @Inject(NUC_FULL_MODAL_DATA) public modalData: IPackageRuleFormData) {
    }

    public ngOnInit(): void {
        this.initModalButtons();
        this.initFormData();
        this.getData();
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private initModalButtons(): void {
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        this.nextButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Next');

        const cancelAction = new FullModalActionModel(cancelButton);
        this.confirmAction = new FullModalActionModel(this.nextButton);

        cancelAction.observable.subscribe(() => this.fullModalService.close(false, true));
        this.confirmAction.observable.subscribe(() => this.onConfirmClicked());

        this.actions = [cancelAction, this.confirmAction];
        this.fullModalService.setModalActions(this.actions);
    }

    private initFormData(): void {
        this.packageRule = this.modalData.packageRule || new PackageRuleModel();
    }

    /**
     * If current tab is information text is Next and action is 'go to next tab'
     * Otherwise text is Save and action is 'Save'
     */
    private setConfirmButton(): void {
        switch (this._selectedTab.index) {
            case this.TAB_INFORMATION:
            case this.TAB_CONDITIONS:
                this.confirmAction.button.text = 'Next';
                this.onConfirmClicked = this.nextTabAction;
                return;
            case this.TAB_MATERIAL_DATA:
                this.confirmAction.button.text = 'Save';
                this.onConfirmClicked = this.onSaveButtonClicked;
                break;
        }
    }

    private onSaveButtonClicked(): void {
        if (this.form?.valid) {
            this.savePackageSetup();
        } else {
            Toaster.warn('Form contains errors. Please update incorrect and/or missing values');
            Object.keys(this.form.controls).forEach((key) => this.form.get(key).markAsDirty());
        }
    }

    private savePackageSetup(): void {
        const packageRule = new PackageRuleModel();
        packageRule._id = this.modalData.packageRule?._id;
        packageRule.name = this.form.get(ETabFormGroup.PACKAGE_RULE_INFORMATION)?.value.name || this.packageRule.name;
        packageRule.conditions = this.form.get(ETabFormGroup.PACKAGE_RULE_CONDITIONS)?.value.map((condition) => {
            const property = condition.property?.path;
            const operator = condition.operator?.getValue();
            const type = condition.type?.getValue();
            const value = condition.value instanceof DropdownItem ? condition.value.getValue() : condition.value;
            const dataType = condition.property.property?.dataType?.type === EDataFieldTypes.DATE ? EDataFieldTypes.DATE : null;

            return new RuleConditionModel(property,
                type,
                null,
                value,
                null,
                operator,
                dataType);
        }) || this.packageRule.conditions;
        packageRule.dataFields = this.form.get(ETabFormGroup.PACKAGE_RULE_MATERIAL_DATA)?.value || this.packageRule.dataFields;

        this.nextButton.loading = true;
        this.nextButton.disabled = true;
        if (this.modalData.packageRule) {
            this.packageSetupService.putPackageSetupRule(this.modalData.packageSetup._id, packageRule._id, packageRule)
                .pipe(finalize(() => {
                    this.nextButton.loading = false;
                    this.nextButton.disabled = false;
                }))
                .subscribe(
                    (result) => {
                        Toaster.success('Package setup rule updated successfully');
                        this.fullModalService.close(result);
                    },
                    (error) => Toaster.handleApiError(error)
                );
        } else {
            this.packageSetupService.postPackageSetupRule(this.modalData.packageSetup._id, packageRule)
                .pipe(finalize(() => {
                    this.nextButton.loading = false;
                    this.nextButton.disabled = false;
                }))
                .subscribe(
                    (result) => {
                        Toaster.success('Package setup rule added successfully');
                        this.fullModalService.close(result);
                    },
                    (error) => Toaster.handleApiError(error)
                );
        }
    }

    private getData(): void {
        this.subscription = forkJoin([
            this.propertyService.getProperties(EPropertyContext.PACKAGE_SETUP_RULE),
            this.variantService.getVariants()
        ]).pipe(takeUntil(this.onDestroySubject))
            .subscribe(([propertiesResult, variantsResult]) => {
                this.ruleProperties = propertiesResult.items;
                this.variants = variantsResult.items;
            }, (error) => {
                this.fullModalService.close();
                Toaster.handleApiError(error);
            });
    }

}
