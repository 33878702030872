import {Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ARApiUrlBuilderService, ARPagedResponseDataModel, ARRequestOptions} from '@relayter/core';
import {BaseApiRequestService} from './base-api-request.service';
import {QueryParams} from '../../classes/query-params';
import {RulePropertyModel} from '../../models/api/rule-property.model';

export enum EPropertyContext {
    INDESIGN_RULESET = 'INDESIGN_RULESET',
    WORKFLOW_AUTOMATION = 'WORKFLOW_AUTOMATION',
    PACKAGE_SETUP_RULE = 'PACKAGE_SETUP_RULE',
    PACKAGE_SETUP_DATA = 'PACKAGE_SETUP_DATA',
    WORKFLOW_IDENTIFIER_SETTING = 'WORKFLOW_IDENTIFIER_SETTING',
    WORKFLOW_IDENTIFIER_SETTING_UPLOAD_ASSET = 'WORKFLOW_IDENTIFIER_SETTING_UPLOAD_ASSET',
    WORKFLOW_PUBLICATION_ITEM = 'WORKFLOW_PUBLICATION_ITEM',
    WORKFLOW_CONFIGURATION_FILTER = 'WORKFLOW_CONFIGURATION_FILTER',
    WEBHOOK_PRODUCER_PRODUCT = 'WEBHOOK_PRODUCER_PRODUCT',
    WEBHOOK_PRODUCER_CAMPAIGN_ITEM = 'WEBHOOK_PRODUCER_CAMPAIGN_ITEM',
    ANIMATED_CONTENT_RULESET = 'ANIMATED_CONTENT_RULESET',
    ANIMATED_CONTENT_RULESET_ASSETS = 'ANIMATED_CONTENT_RULESET_ASSETS'
}

@Injectable({
    providedIn: 'root'
})
export class PropertyService extends BaseApiRequestService {
    public getProperties(context: EPropertyContext): Observable<ARPagedResponseDataModel<RulePropertyModel>> {
        const queryParams = new QueryParams()
            .addParam('context', context);

        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_PROPERTIES], queryParams.getParams());

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handleArrayResponse(options, obs, RulePropertyModel);
        });
    }
}
