/**
 * XLIFF Reader that can parse an XLIFF file to an array of objects
 */
export class XliffReader {

    private readonly data: string;
    private json: any;

    /**
     * Parses a XLIFF unit returns an object with {'segment attribute id' : 'segment target'}
     * @param {object} unit
     */
    private parseUnit(unit: any): Record<string, any> {
        const item = {_id: unit['@_id']};

        if (unit.segment) {
            for (const segment of unit.segment) {
                item[segment['@_id']] = segment.target;
            }
        }
        return item;
    }

    /**
     * Init the Reader with xml data
     * @param data {string} data
     */
    public constructor(data: string) {
        this.data = data;
    }

    /**
     * Validate data to be in a valid XLIFF format. This reader only supports XLIFF specification 2.0.
     * http://docs.oasis-open.org/xliff/xliff-core/v2.0/xliff-core-v2.0.html
     * @throws Will throw an error if the XLIFF file is not valid.
     * @returns {void}
     */
    public validate(): void {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const {XMLParser} = require('fast-xml-parser');

        const alwaysArray = ['xliff.file.unit.segment'];
        const options = {
            parseAttributeValue: true,
            ignoreAttributes: false,
            isArray: (name, jsonPath) => alwaysArray.includes(jsonPath)
        };

        try {
            this.json = new XMLParser(options).parse(this.data);
        } catch (error) {
            throw(Error('Not able to parse XML: ' + error.message));
        }

        if (!this.json.xliff) {
            throw Error('No <xliff> element found');
        }

        if (!this.json.xliff.file) {
            throw Error('No <file> element found');
        }

        if (!this.json.xliff.file.unit) {
            throw Error('No <unit> element found');
        }

        const version: number = this.json.xliff['@_version'];
        if (!version || version !== 2) {
            throw Error('Not a valid XLIFF version found, we only support version 2.0');
        }
    }

    /**
     * Parses the XLIFF file into json objects. Uses target as the field value. id as the field name.
     * @throws Will throw an error if the XLIFF file is not valid.
     * @returns {Object[]} | string - Returns array of objects.
     */
    public parse(): any[] {

        if (!this.json) {
            this.validate();
        }

        const dataRows = [];

        if (this.json.xliff.file.unit instanceof Array) {
            for (const unit of this.json.xliff.file.unit) {
                dataRows.push(this.parseUnit(unit));
            }
        } else {
            dataRows.push(this.parseUnit(this.json.xliff.file.unit));
        }

        return dataRows;
    }
}
