import {Component, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {CustomWorkflowService} from '../custom-workflow.service';
import {PublicationModel} from '../../../../../../models/api/publication.model';
import {BUTTON_TYPE, EColumnType, ESelectionMode, ITableColumn, NucDialogConfigModel, NucDialogService} from '@relayter/rubber-duck';
import {WorkflowAutomationModel} from '../../../../../../models/api/workflow-automation.model';
import {ISortOptionEvent} from '@relayter/rubber-duck/lib/modules/table/components/table/table-config';
import {WorkflowAutomationsService} from '../../../../../../api/services/workflow-automations.service';
import {Toaster} from '../../../../../../classes/toaster.class';
import {PublicationsService} from '../../../../../../api/services/publications.service';
import {CustomWorkflowActionModel, EWorkflowConfigurationActionType} from '../../../../../../models/api/custom-workflow-action.model';
import {UserIsAllowedToPipe} from '../../../../../../pipes/user-is-allowed-to.pipe';
import {CustomWorkflowComponentModel} from '../../../../../../models/api/custom-workflow-component.model';
import {RLTableComponent} from '../../../../../../components/rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../../../../../api/services/user-settings-storage.service';
import {SelectionModel} from '@angular/cdk/collections';
import {PaginatorService} from '../../../../../../components/paginator/paginator.service';

@Component({
    selector: 'rl-custom-workflow-automation-component',
    templateUrl: './custom-workflow-automation.component.html',
    styleUrls: ['./custom-workflow-automation.component.scss'],
    providers: [PaginatorService]
})
export class CustomWorkflowAutomationComponent extends RLTableComponent implements OnInit, OnDestroy {
    public readonly tableId = 'custom-workflow-automation-table';
    public component: CustomWorkflowComponentModel;
    private publication: PublicationModel;
    public transitionAction: CustomWorkflowActionModel;
    private onDestroySubject = new Subject<void>();

    // workflow automation table
    public columns: ITableColumn[] = [{
        title: 'Workflow automation',
        key: 'name',

        type: EColumnType.DEFAULT,
        sortProperty: 'name'
    }, {
        title: 'Description',
        key: 'description',

        type: EColumnType.DEFAULT
    }];

    public subscription: Subscription;
    public total: number;
    public pageIndex: number;
    public pageSize: number;
    public automations: WorkflowAutomationModel[];
    public selectionMode = ESelectionMode.EXPAND;
    public disableNextPage = true;

    public selection = new SelectionModel<WorkflowAutomationModel>(true);

    constructor(private customWorkflowService: CustomWorkflowService,
                private publicationService: PublicationsService,
                private workflowAutomationsService: WorkflowAutomationsService,
                private dialogService: NucDialogService,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private paginatorService: PaginatorService,
                userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);
    }

    public ngOnInit(): void {
        combineLatest([
            this.customWorkflowService.publication$,
            this.customWorkflowService.activeComponent$,
        ]).pipe(
            takeUntil(this.onDestroySubject),
        ).subscribe(([publication, component]) => {
            this.publication = publication;
            this.component = component;

            if (this.component) {
                // reset action
                const allowedActions = this.component.actions.filter((action) => this.userIsAllowedToPipe.transform(action.permissions));
                // also only find the transition trigger action
                this.transitionAction = allowedActions.find(
                    (action) => action.type === EWorkflowConfigurationActionType.TRANSITION_TRIGGER);
                if (this.transitionAction) this.selectionMode = ESelectionMode.MULTI; // only enable the selection mode when there is an action set
                this.paginatorService.setPageIndex(this.tableId, 1);
                this.selection.clear();
            }
        });

        this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((pagination) => {
                if (pagination.pageIndex === 1 || pagination.pageSize !== this.pageSize) {
                    this.newApiCursor.reset(this.pageIndex, this.tableSortOptions);
                }

                this.pageIndex = pagination.pageIndex;
                this.pageSize = pagination.pageSize;

                this.getAutomations();
        });
    }

    private getAutomations(): void {
        if (this.subscription) this.subscription.unsubscribe();

        const cursor = this.newApiCursor.getCursor(this.pageIndex);
        const offset = cursor._id ? 0 : (this.pageIndex - 1) * this.pageSize;

        this.subscription = this.workflowAutomationsService.getWorkflowAutomations(
            this.pageSize,
            offset,
            cursor,
            this.tableSortOptions
        ).subscribe({
            next: (result) => {
                this.automations = result.items;
                this.disableNextPage = !result.hasNext;

                if (this.automations?.length > 0) {
                    const item = this.automations[this.automations.length - 1];
                    this.newApiCursor.setCursor(this.pageIndex, item);
                }
            },
            error: Toaster.handleApiError
        });
    }

    public onSortOptionChanged(event: ISortOptionEvent): void {
        this.tableSortOptions.updateWithSortOptionEvent(event);
        this.newApiCursor.reset(1, this.tableSortOptions);
        this.paginatorService.setPageIndex(this.tableId, 1);
    }

    public openDialog(): void {
        const dialogConfig = new NucDialogConfigModel('Important!',
            'Are you sure you want to run the automations? ' +
            'This will generate all your material based on the automation rules. ' +
            'If you have already run this automation this will result in duplicate materials.');
        const dialog = this.dialogService.openDialog(dialogConfig);
        dialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => dialog.close());
        dialogConfig.addAction('I understand', BUTTON_TYPE.PRIMARY).subscribe(() => {
            dialog.close();
            this.postTransitionItem();
        });
    }

    private postTransitionItem(): void {
        const data = {automationIds: this.selection.selected.map(item => item._id)};
        this.publicationService.postTransitionItem(this.publication._id, this.transitionAction.transition, data)
            .subscribe({
                next: () => {
                    this.selection.clear();
                },
                error: Toaster.handleApiError
            });
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }
}
